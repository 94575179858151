import { calculatePosition } from "../helpers/functions"

const currentTimeStyles = {
    color: 'black',
    position: 'absolute',
    top: '-8px',
    fontSize: '12px',
    padding: '4px',
    backgroundColor: 'white',
    borderRadius: '20px',
    transform: 'translateX(-50%)',
    cursor: 'pointer',
    display: 'block',
};

const LeftTimeStyles = {
    color: 'white',
    position: 'absolute',
    top: '-4px',
    fontSize: '12px',
    transform: 'translateX(-50%)',
};

const RightTimeStyles = {...LeftTimeStyles,right:0,transform: 'translateX(50%)'}

const formatDuration = (duration) => {
    let seconds = Math.ceil(duration);
    const hrs = Math.floor(seconds / 3600);
    const mins = Math.floor((seconds % 3600) / 60);
    seconds = seconds % 60;

    let durationStr =
        (hrs > 0 ? `${hrs.toString().padStart(2, "0")}:` : "") +
        `${mins.toString().padStart(1, "0")}:` +
        `${seconds.toString().padStart(2, "0")}`;

    return durationStr;
};

const resolveTimeStyles = (isCurrentTime, isStartTime, isEndTime) => {
    if (isCurrentTime) return currentTimeStyles;
    if (isStartTime) return LeftTimeStyles;
    if (isEndTime) return RightTimeStyles;
}



function TimeLabel({ time, startTime, endTime, isCurrentTime, isStartTime, isEndTime }) {
    
    const isVisible = isCurrentTime ? (time >= startTime && time <= endTime) : true
    const position = isCurrentTime ? calculatePosition(time, startTime, endTime) : null;
    const timeStyles = resolveTimeStyles(isCurrentTime, isStartTime, isEndTime);

    if (!isVisible) return null;

    return (
        <time style={isCurrentTime ? { ...timeStyles, left: position }: timeStyles}>
            {formatDuration(time)}
        </time>
    );
}

export default TimeLabel;