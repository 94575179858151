import "../styles/CloneModal.css";

import { Button, IconButton, LinearProgress, Typography } from "@mui/material";
import React, { useEffect } from "react";

import AudPlayer from "./AudPlayer";
import CloseIcon from "@mui/icons-material/Close";
import FileUpload from "./FileUpload";
import axios from "axios";

const CloneModal = ({ closeCloneModal }) => {
  const [files, setFiles] = React.useState([]);
  const [uploadLoader, setUploadLoader] = React.useState(false);
  const [uploadError, setUploadError] = React.useState("");
  const [cloneSrc, setCloneSrc] = React.useState(null);
  const [getCloneLoading, setGetCloneLoading] = React.useState(false);



  const uploadFiles = async () => {
    setUploadLoader(true);
    setUploadError("");
    const formData = new FormData();
    console.log("files ", files);
    files.forEach((file) => {
      console.log(file);
      console.log(file.file);
      formData.append("files", file.file);
    });
    try {
      const response = await axios.post(
        'https://apx.translatetracks.com/clone_voice',
        formData,
        {
            headers: {
                token: localStorage.getItem("token"),
              },
        }
      );
      if (response.data.status === 0) {
        setUploadLoader(false);
        setCloneSrc(response.data.clone_url)
        setFiles([]);
        console.log(response.data);
      }else if(response.data.status === -3){
        setUploadLoader(false);
        setUploadError(response.data.message)
      } else {
        setUploadLoader(false);
        setUploadError("Some error occured! Please try again!")
        console.log(response.data);
      }
    } catch (error) {
      setUploadLoader(false);
      setUploadError("Some error occured! Please try again!")
      console.log(error);
    }
  };

  const getClonedVoice = async () => {
    setGetCloneLoading(true);
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/get_cloned_voice`,
        {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        }
      );
      if (response.data.status === 0) {
        setGetCloneLoading(false);
        setCloneSrc(response.data.clone_url)
      } else {
        setGetCloneLoading(false);
        console.log(response.data);
      }
    } catch (error) {
      setGetCloneLoading(false);
      console.log(error);
    }
  };

  useEffect(() => {
    setUploadError("");
    getClonedVoice();
  }, []);

  return (
    <React.Fragment>
      {getCloneLoading ? (
        <div style={{ margin: "auto", width: "20%" }}>
          <LinearProgress style={{ marginTop: 20 }} />
        </div>
      ) : (
        <div style={{ display: "flex" }} className="cloneModal">
          <div style={{ flex: 1, display: "flex", flexDirection: "column" }}>
            <Typography
              style={{
                color: "white",
                fontSize: 25,
                textAlign: "center",
                marginTop: 10,
                marginBottom: 10,
              }}
            >
              Clone Your Voice!
            </Typography>
            <FileUpload
              files={files}
              setFiles={setFiles}
              maxFiles={5}
            />
            {uploadLoader ? (
              <div style={{ margin: "auto", width: "20%" }}>
                <LinearProgress style={{ marginTop: 0 }} />
              </div>
            ) : (
              <Button
                variant="contained"
                color="primary"
                style={{ alignSelf: "center" }}
                onClick={uploadFiles}
                disabled={files.length === 0}
              >
                Clone!
              </Button>
            )}
            {uploadError && (
                <Typography
                    style={{
                        color: "red",
                        fontSize: 14,
                        textAlign: "center",
                        marginTop: 10,
                    }}
                >
                    {uploadError}
                </Typography>
            )}

            {cloneSrc && (
              <React.Fragment>
                <Typography
                  style={{
                    color: "white",
                    fontSize: 16,
                    textAlign: "center",
                    marginTop: 20,
                  }}
                >
                  Your current cloned voice!
                </Typography>
                <AudPlayer src={cloneSrc} />
              </React.Fragment>
            )}
          </div>
          <IconButton
            onClick={closeCloneModal}
            style={{ padding: 0, alignSelf: "flex-start" }}
          >
            <CloseIcon style={{ color: "white" }} fontSize="small" />
          </IconButton>
        </div>
      )}
    </React.Fragment>
  );
};

export default CloneModal;
