import { Typography } from "@mui/material"
import AudioPlayButton from "./AudioPlayButton"
import LoaderIconButton from "./LoaderIconButton"
import AddBoxIcon from "@mui/icons-material/AddBox";


const SpeakerList = ({speakerLegend,handleSpeakerAdd,status}) => {
    return(
        <div style={{display:"flex",flexDirection:"column"}}>
        <div style={{display:"flex",flexWrap:"wrap"}}>
            {speakerLegend.map((entry) => {
                return (
                    <div style={{display:"flex",justifyContent:"center",margin:5}} key={entry['speaker']}>
                        <AudioPlayButton color={entry['color']} url={entry['audio']}/>
                        <Typography style={{color: "#afb8c1",alignSelf:"center"}}>{entry['speaker']}</Typography>
                    </div>
                )
            })}
        </div>
        {status !== 'ag_waiting' &&<LoaderIconButton loading={false} ButtonType={AddBoxIcon} styles={{alignSelf:"center"}} 
            onClick={handleSpeakerAdd}/>}
        </div>
    )
}

export default SpeakerList