import blueDotImage from '../assets/images/blue_dot.png'
import redDotImage from '../assets/images/red_dot.png'
import { calculatePosition } from '../helpers/functions';

function BreakpointImage({ breakpoint, selectedBreakpoint, startTime, endTime,onBreakpointClick }) {
    const isVisible = breakpoint >= startTime && breakpoint <= endTime;
    const position = calculatePosition(breakpoint, startTime, endTime);
    const breakpointImageStyles = {
        width:10,
        position:"absolute",
        top:12,
        cursor:"pointer",
        //add translation X 50% to center the image
        transform: "translateX(-50%)"
    }

    if (!isVisible) return null;

    return (
        <img
            src={selectedBreakpoint ? (selectedBreakpoint.breakpoint === breakpoint ? blueDotImage : redDotImage) : redDotImage}
            style={{
                ...breakpointImageStyles,
                left: position
            }}            
            onClick={onBreakpointClick}
        />
    );
}

export default BreakpointImage;
