
export const calculatePosition = (point, start, end) => `${((point - start) / (end - start)) * 100}%`;
export const findSegmentDetailsFromTime = (segments, time) => {
    const segmentIndex = findSegmentIndexFromCurrentTime(segments, time);
    const segment = segments[segmentIndex];
    if(!segment){
      return null
    }
    return {
      breakpoint: time,
      text: segment.text,
      speaker: segment.speaker,
      segment_index: segmentIndex,
      index: segment.index,
      segment_id: segment.id
    };
  };

export const findSegmentIndexFromCurrentTime = (segments, playedSeconds) => {
    console.log(segments,playedSeconds)
    for (let i = 0; i < segments.length; i++) {
      if (
        playedSeconds >= segments[i].start &&
        playedSeconds <= segments[i].end
      ) {
        return i;
      }
    }
    return -1;
  };

export const findSegmentIdFromCurrentTime = (segments, playedSeconds) => {
    for (let i = 0; i < segments.length; i++) {
      if (
        playedSeconds >= segments[i].start &&
        playedSeconds <= segments[i].end
      ) {
        return segments[i].id;
      }
    }
    return -1;
  }


export const findSegmentFromId = (segments, id) => {
    for (let i = 0; i < segments.length; i++) {
      if (segments[i].id === id) {
        return segments[i];
      }
    }
    return -1;
  }

  export const formatSpeakerLegend = (speaker_legend,speaker_colors) => {
    //ensure that first speaker in the legend is "emotion"
    //assign color to each speaker
    //return the legend
    //export const speaker_legend = [{"speaker":"A","audio":"https://speakmultiassets.s3.amazonaws.com/segment_0.mp3"},{"speaker":"B","audio":"https://speakmultiassets.s3.amazonaws.com/segment_0.mp3"},{"speaker":"C","audio":"https://speakmultiassets.s3.amazonaws.com/segment_0.mp3"},{"speaker":"D","audio":"https://speakmultiassets.s3.amazonaws.com/segment_0.mp3"},{"speaker":"emotion","audio":"https://speakmultiassets.s3.amazonaws.com/segment_0.mp3"}]
    for (let i = 0; i < speaker_legend.length; i++) {
      if (speaker_legend[i]['speaker'] === "emotion") {
        const emotion = speaker_legend[i];
        speaker_legend.splice(i,1);
        speaker_legend.unshift(emotion);
      }
    }

    for (let i = 0; i < speaker_legend.length; i++) {
      speaker_legend[i]['color'] = speaker_colors[i];
    }
    return speaker_legend;
  
  }

  export const unformatSpeakerLegend = (speaker_legend) => {
    //remove color from each speaker
    //return the legend
    //export const speaker_legend = [{"speaker":"A","audio":"https://speakmultiassets.s3.amazonaws.com/segment_0.mp3"},{"speaker":"B","audio":"https://speakmultiassets.s3.amazonaws.com/segment_0.mp3"},{"speaker":"C","audio":"https://speakmultiassets.s3.amazonaws.com/segment_0.mp3"},{"speaker":"D","audio":"https://speakmultiassets.s3.amazonaws.com/segment_0.mp3"},{"speaker":"emotion","audio":"https://speakmultiassets.s3.amazonaws.com/segment_0.mp3"}]
    for (let i = 0; i < speaker_legend.length; i++) {
      delete speaker_legend[i]['color'];
    }
    return speaker_legend;
  }


  export const findColorFromSpeaker = (speaker,speaker_legend) => {
    for (let i = 0; i < speaker_legend.length; i++) {
      if (speaker_legend[i]['speaker'] === speaker) {
        return speaker_legend[i]['color'];
      }
    }
    return -1;
  }

  export const getNextAlphabet = (alphabet) => {
    if (alphabet === "Z") {
      return "A";
    }
    return String.fromCharCode(alphabet.charCodeAt(0) + 1);
  }


  export const isIncreamentPossible = (segments, new_range, index) => {
    //first range of multiple ranges
    if (index === 0 && segments.length > 1) {
      if (new_range[1] < segments[index + 1].start) {
        return true;
      } else {
        return false;
      }
      //first range of single range
    } else if (segments.length === 1) {
      return true;
      //last range of multiple ranges
    } else if (index === segments.length - 1) {
      if (new_range[0] > segments[index - 1].end) {
        return true;
      } else {
        return false;
      }
    } else {
      if (
        new_range[0] > segments[index - 1].end &&
        new_range[1] < segments[index + 1].start
      ) {
        return true;
      } else {
        return false;
      }
    }
  };
  

  export const findChangingTime = (old_range, new_range) => {
    if (old_range[0] !== new_range[0]) {
      console.log("zero ", new_range[0]);
      return new_range[0];
    } else {
      console.log("one ", new_range[1]);
      return new_range[1];
    }
  };

  export const getSegmentIndexFromId = (segments,id) => {
    for(let i=0 ; i<segments.length; i++){
      if(segments[i].id === id){
        return i
      }
    }
    return -1
  }


  export const get_button_text = (status) => {
    if(status === 'tp_waiting'){
      return "Translate"
    }else if(status == 'tr_waiting'){
      return "Generate Audio"
    }else if(status === 'ag_waiting'){
      return "Generate Video"
    }
  }

  export const  get_segments_with_empty_translations = (segments) => {
    const empty = []
    for(let i=0; i<segments.length; i++){
      if(segments[i].translation === "" && segments[i].speaker !== "emotion"){
        empty.push({'id':segments[i].id,'text':"Segment " + segments[i].id.slice(0,8) + " has empty translation"})
      }
    }
    return empty
  }

  export const is_button_disabled = (status,segments) => {
    if(status === 'transcribed_waiting'){
      return false
    }else if(status == 'translated_waiting'){
      return false
    }
  }
  

  export const get_segments_with_empty_transcriptions = (segments) => {
    const empty = []
    for(let i=0; i<segments.length; i++){
      if(segments[i].text === "" && segments[i].speaker !== "emotion"){
        empty.push({'id':segments[i].id,'text':"Segment " + segments[i].id.slice(0,8) + " has empty transcription"})
      }
    }
    return empty
  }

  export const get_overlapping_segments = (segments) => {
    const overlapping = []
    for(let i=0; i<segments.length; i++){
      for(let j=i+1; j<segments.length; j++){
        if(segments[i].start < segments[j].start && segments[i].end > segments[j].start){
          overlapping.push({'id':segments[i].id,'text':"Segment " + segments[i].id.slice(0,8) + " overlaps with segment " + segments[j].id.slice(0,8)})
        }
      }
    }
    return overlapping
  }

  export const get_audio_segment_mismatch = (segments) => {
    const mismatch = []
    for(let i=0; i<segments.length; i++){
      if(Math.abs(segments[i].end - segments[i].start - segments[i].audio_final_durations[0]) > 0.05){
        console.log(Math.abs(segments[i].end - segments[i].start - segments[i].audio_final_durations[0]))
        mismatch.push({'id':segments[i].id,'text':"Segment " + segments[i].id.slice(0,8) + " has audio-segment mismatch"})
      }
    }
    return mismatch
  }


  export const get_null_audio_segments = (segments) => {
    const null_audio = []
    for(let i=0; i<segments.length; i++){
      if(segments[i].audio_final_index === null){
        null_audio.push({'id':segments[i].id,'text':"Segment " + segments[i].id.slice(0,8) + " has no audio"})
      }
    }
    return null_audio
  }



  export const get_segments_with_0_negative_duration = (segments) => {
    const zero_duration = []
    for(let i=0; i<segments.length; i++){
      if(segments[i].end - segments[i].start <= 0){
        zero_duration.push({'id':segments[i].id,'text':"Segment " + segments[i].id.slice(0,8) + " has zero duratione"})
      }
    }
    return zero_duration
  }


  //from the given segment id and a variable context length context_length which is odd, 
  //return translation field of context_length semgnets
  //such that the given segment is in the middle if there are enough segments
  //else it should be at the start or end
  export const generate_context = (segment_id,context_length,segments) => {
    const segmentIndex = getSegmentIndexFromId(segments,segment_id)
    const context = []
    const start = Math.floor(context_length/2)
    const end = Math.floor(context_length/2)
    let position = null
    if(segmentIndex - start < 0){
      for(let i=0; i<context_length; i++){
        context.push(segments[i].translation)
      }
      position = "start"
  }
  else if(segmentIndex + end > segments.length - 1){
    for(let i=segments.length - context_length; i<segments.length; i++){
      context.push(segments[i].translation)
    }
    position = "end"
  }
  else{
    for(let i=segmentIndex - start; i<segmentIndex + end + 1; i++){
      context.push(segments[i].translation)
    }
    position = "mid"
  }
  return [context,position]
  }


  //find if segment's duration can be increased to fit the fit_duration without overlapping with other segments
  export const can_segment_fit = (fit_duration,segment_id,segments,video_duration) => {
    const segmentIndex = getSegmentIndexFromId(segments,segment_id)
    if(segmentIndex === 0){
      if(segments[segmentIndex + 1].start - fit_duration > 0){
        return true
      }else{
        return false
      }
    }else if(segmentIndex === segments.length - 1){
      if(segments[segmentIndex - 1].end + fit_duration < video_duration){
        return true
      }else{
        return false
      }
  }else{
    if(segments[segmentIndex - 1].end + fit_duration < segments[segmentIndex + 1].start){
      return true
    }else{
      return false
    }
  }
}


//find the start and end of the new fit segment that fits in fit_duration and has the same
//midpoint as the original segment
export const get_new_fit_start_end = (fit_duration,segment_id,segments) => {
  const segmentIndex = getSegmentIndexFromId(segments,segment_id)
  const segment = segments[segmentIndex]
  const mid = (segment.start + segment.end)/2
  const start = mid - fit_duration/2
  const end = mid + fit_duration/2
  return [start,end]
 
}


export const get_mapped_name_from_mapping = (mapping,speaker) => {
  for(let i=0; i<mapping.length; i++){
    if(mapping[i].speaker === speaker){
      return mapping[i].voice_name
    }
  }
}


export const get_mapping_index_from_mapping = (mapping,speaker) => {
  for(let i=0; i<mapping.length; i++){
    if(mapping[i].speaker === speaker){
      return i
    }
  }
}

export const initialize_speaker_mapping = (speaker_legend) => {
  const mapping = []
  for(let i=0; i<speaker_legend.length; i++){
    mapping.push({'speaker':speaker_legend[i].speaker,'voice_name':'default'})
  }
  return mapping
}


export const get_segment_duration = (segments,currentSegmentId) => {
  const currentSegment = findSegmentFromId(segments,currentSegmentId)
  let duration = currentSegment.end - currentSegment.start
  duration = duration.toFixed(2) + "s"
  return duration
}

export const get_audio_final_duration = (segments,currentSegmentId) => {
  const currentSegment = findSegmentFromId(segments,currentSegmentId)
  let duration = currentSegment.audio_final_durations[currentSegment.audio_final_index]
  if(duration === undefined){
    duration = "N/A"
  }else{
    duration = duration.toFixed(2) + "s"
  }
  return duration
}

export const  get_audio_count = (originalSegments) => {
  let audio_count = 0
  for(let i = 0 ; i<originalSegments.length; i++){
    if(originalSegments[i]['audio_final_index'] !== null){
      audio_count += 1
    }
  }
  return audio_count

} 


export const get_next_segmentId_from_current_time = (segments,currentTime) => {
  console.log(segments,currentTime)
  for(let i = 0 ; i<segments.length; i++){
    if( i === segments.length - 1  ){
      return -1
    }else if(currentTime < segments[0].start){
      return segments[0].id

    }else if((segments[i].start <= currentTime && segments[i].end >= currentTime) || (segments[i+1].start > currentTime && segments[i].end < currentTime) ){
      console.log(i,segments[i].start,currentTime,segments[i].end,segments[i+1].start)
      return segments[i+1].id
    }
  }
}


export const round_off_start_end = (segments) => {
  for(let i =0 ; i < segments.length; i++){
    console.log(segments[i].start)
    segments[i].start = Math.round(segments[i].start*100)/100
    segments[i].end = Math.round(segments[i].end*100)/100
  }

  return segments
}

export const get_previous_segmentId_from_current_time = (segments,currentTime) => {
  for(let i = 0 ; i<segments.length; i++){
    if( i === segments.length - 1  ){
      return segments[i-1].id
    }else if(currentTime < segments[0].end){
      return -1

    }else if((segments[i].start <= currentTime && segments[i].end >= currentTime) || (segments[i-1] && segments[i-1].start  < currentTime && segments[i].start > currentTime) ){
      return segments[i-1].id
    }
  }

}

//for keys not existing in the new mapping, add them with default value
//speaker_mapping = [{'speaker':,'voice_name':}]
//speaker_legend = [{'speaker':,'audio':}]
export const resolve_speaker_mapping = (speaker_mapping,speaker_legend) => {
  const new_mapping = []
  for(let i=0; i<speaker_legend.length; i++){
    let found = false
    for(let j=0; j<speaker_mapping.length; j++){
      if(speaker_mapping[j].speaker === speaker_legend[i].speaker){
        new_mapping.push(speaker_mapping[j])
        found = true
        break
      }
    }
    if(!found){
      new_mapping.push({'speaker':speaker_legend[i].speaker,'voice_name':'default'})
    }
  }
  console.log(new_mapping)
  return new_mapping

}