import { useEffect, useRef, useState } from "react";
import useApi from "../hooks/useApi";
import { useLocation } from "react-router-dom";
import Player from "../components/Player";
import PlayerControls from "../components/PlayerControls";
import ReactPlayer from "react-player";
import Slider from "@mui/material/Slider"; // Import Slider from Material-UI
import { Button, LinearProgress } from "@mui/material";
import { useNavigate } from "react-router-dom";





const formatTime = (value) => {
    const minutes = Math.floor(value / 60);
    const seconds = Math.floor(value % 60);
    return `${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
};


const BackgroundEditor = () => {

    const location = useLocation();
    const query = new URLSearchParams(location.search);
    const navigate = useNavigate()


    const backgroundDetails = useApi(`${process.env.REACT_APP_API_URL}/get_background_details`)
    const submit = useApi('https://apy.translatetracks.com/submit')

    const video_id = query.get('video_id');

    const [video_url, setVideoUrl] = useState(null);
    const [bg_url, setBgUrl] = useState(null);


    const [isMute, setIsMute] = useState(false);
    const [isBuffering, setIsBuffering] = useState(false);
    const [playing, setPlaying] = useState(false);
    const [bgVolume, setBgVolume] = useState(0.5);
    const [isBgPlaying, setIsBgPlaying] = useState(false);
    const [isBgBuffering, setIsBgBuffering] = useState(false);
    const [duration, setDuration] = useState(null);
    const [progress, setProgress] = useState(0); // State for progress
    const [isReady, setIsReady] = useState(false); 
    const [isBgReady, setIsBgReady] = useState(false); 

    const playerRef = useRef(null);
    const bgRef = useRef(null)


    const onReady = () => {
        setIsReady(true);
    }
    const onBgReady = () => {
        setIsBgReady(true);
    }

    const onError = (e) => {
        console.log('onError', e)
    }

    const onDuration = (duration) => {
        console.log('onDuration', duration)
        setDuration(duration);
    };

    const onBuffer = () => {
        console.log('onBuffer')
        setIsBuffering(true);
    }
    const onBgBuffer = () => {
        console.log('onBgBuffer')
        setIsBgBuffering(true);
    }



    const onBufferEnd = () => {
        console.log('onBufferEnd')
        setIsBuffering(false);
    }
    const onBgBufferEnd = () => {
        console.log('onBgBufferEnd')
        setIsBgBuffering(false);
    }

    const togglePlaying = () => {
        setPlaying(!playing)
        setIsBgPlaying(!isBgPlaying)
    }

    const toggleMute = () => {
        setIsMute(!isMute)
    }

    const handleBgVolumeChange = (e, value) => {
        setBgVolume(value)
    }

    const handleSeek = (e, newValue) => {
        setProgress(newValue);
        playerRef.current.seekTo(newValue, 'seconds');
        bgRef.current.seekTo(newValue, 'seconds');
    }

    const handleProgress = (state) => {
        setProgress(state.playedSeconds);
    }


    useEffect(() => {
        if (progress > 0) {
            console.log("isReady", isReady, "isBuffering", isBuffering, "isBgReady", isBgReady, "isBgBuffering", isBgBuffering)
            if ((isBuffering || !isReady) && (!isBgBuffering && isBgReady)) {
                setIsBgPlaying(false)   
            } else {
                setIsBgPlaying(true)
            }
        }

    }, [isBgReady,isBgBuffering,isBgBuffering,isReady])


    useEffect(() => {
        if(progress > 0){
            if ((isBgBuffering || !isBgReady) && (!isBuffering && isReady)) {
                setPlaying(false)
            } else {
                setPlaying(true)
            }
        }

    },[isBgReady,isBgBuffering,isBgBuffering,isReady])



    useEffect(() => {
        backgroundDetails.fetchData('GET', null, { request_id: video_id }, localStorage.getItem('token'))
    }, [])

    useEffect(() => {
        if (backgroundDetails.data) {
            setVideoUrl(backgroundDetails.data.data.video_link)
            setBgUrl(backgroundDetails.data.data.bg_link)
        }

    }, [backgroundDetails.data])


    const handleSubmitClick = () => {
        // let speaker_legend = [...speakerLegend]
        submit.fetchData('POST', {task:"add_bg",volume_level:bgVolume,video_id:video_id}, null,localStorage.getItem('token'))
    }
    
      useEffect(() => {
        if(submit.data){
            navigate("/")
        }
      }, [submit.data])

    return (
        <div style={{ minHeight: "100vh", backgroundColor: "#272727" }}>
            <div style={{ display: "flex", flexDirection: "column", justifyContent: "center" }}>
                <Player url={video_url}
                    videoLoaded={true}
                    style={{ alignSelf: "center" }}
                    playing={playing}
                    isMute={isMute}
                    onDuration={onDuration}
                    handleVideoProgress={handleProgress}
                    onBuffer={onBuffer}
                    onBufferEnd={onBufferEnd}
                    playerRef={playerRef}
                    onReady={onReady}
                    onError={onError}

                />
                <PlayerControls isMute={isMute} isBuffering={isBuffering} playing={playing}
                    togglePlaying={togglePlaying} toggleMute={toggleMute} hide={false}
                    handleBgVolumeChange={handleBgVolumeChange} bgVolume={bgVolume} />
                <Slider
                    value={progress}
                    onChange={handleSeek}
                    min={0}
                    max={duration} // Set max value based on video duration
                    step={1}
                    style={{ width: 600, alignSelf: "center" }}
                    valueLabelFormat={formatTime} // Use the custom thumb component
                    valueLabelDisplay="auto" // Display the label automatically
                    key={`slider-${progress}`} // Force reset component
                />

                <ReactPlayer
                    url={bg_url}
                    height={0}
                    width={0}
                    //    ref={bgAudioRef}
                    playing={isBgPlaying}
                    volume={bgVolume}
                    ref={bgRef}
                    onReady={onBgReady}
                    onBuffer={onBgBuffer}
                    // onBufferEnd={onBgBufferEnd}
                />

                {submit.loading ? 
                <div style={{ width: "20%",alignSelf:"center",marginTop:100 }}>
                <LinearProgress/>
                </div>:
                <Button
                    variant="contained"
                    color="primary"
                    style={{ alignSelf: "center", marginTop: 100 }}
                    onClick={handleSubmitClick}
                >
                    Generate Video
                </Button>}
            </div>
        </div>
    )
}

export default BackgroundEditor;