import { Route, Routes } from "react-router-dom";

import Authentication from "../screens/Authentication";
import Landing from "../screens/Landing";
import Showcase from "../screens/Showcase";
import VideoTest from "../screens/VideoTest";
import Editor from "../screens/Editor";
import BackgroundEditor from "../screens/BackgroundEditor";

const AppRouter = () => {
    return (
        <Routes>
            <Route path="/auth" element={<Authentication/>} />
            <Route path="/showcase" element={<Showcase/>} />
            <Route path="/" element={<Landing/>} />
            <Route path="/video" element={<VideoTest/>} />
            <Route path="/editor" element={<Editor/>} />
            <Route path="/addBackground" element={<BackgroundEditor/>} />
        </Routes>
    )
}

export default AppRouter;