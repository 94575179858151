import React from "react";
import ReactPlayer from "react-player";

const Player = ({url,playing,handleVideoProgress,isMute,onBuffer,onBufferEnd,onDuration,onReady,playerRef,videoLoaded,style,onError}) => {
    return(
        <ReactPlayer
            className="react-player"
            url={url}
            // url={videoUrl}
            playing={playing}
            // onDuration={(duration) => {
            //   setDuration(duration);
            //   if (duration < leastCount) {
            //     setEndTime(duration);
            //   }
            // }}
            onDuration={onDuration}
            onProgress={handleVideoProgress}
            ref={playerRef}
            progressInterval={10}
            width={600}
            height={350}
            onBuffer={onBuffer}
            onBufferEnd={onBufferEnd}
            muted={isMute}
            onReady={onReady}
            style={!videoLoaded ? {display:"none"} : style}
            onError={onError}
          />
    )
}

export default Player;