import {
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  InputLabel,
  LinearProgress,
  MenuItem,
  Select,
  Typography,
  IconButton,
  CircularProgress
} from "@mui/material";
import React, { useEffect, useState } from "react";

import EmptyTextarea from "./EmptyTextArea";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import TranslateIcon from '@mui/icons-material/Translate';

const TranslationModal = ({ selectedVideoId, setModalIsOpen }) => {
  const [translation, setTranslation] = useState({
    response: null,
    loading: false,
    error: "",
  });
  const [userVoices, setUserVoices] = useState(null);
  const [translationVerification, setTranslationVerification] = useState({
    loading: false,
    error: "",
  });
  const [voiceStyle, setVoiceStyle] = useState("0");
  const [useClonedVoice, setUseClonedVoice] = useState(false);
  const navigate = useNavigate();
  const [englishTranslationLoading,setEnglishTranslationLoading] = useState(false)
  const [translationLoading,setTranslationLoading] = useState(false)

  const handleUseClonedVoiceChange = (event) => {
    console.log(event.target.checked);
    setUseClonedVoice(event.target.checked);
  };

  const handleTranslationChange = (event, index) => {
    updateTranslationResponse(index, { translation: event.target.value });
  };

  const updateTranslationResponse = (update_index, update_object) => {
    let new_response = [...translation.response];
    new_response[update_index] = {
      ...translation.response[update_index],
      ...update_object,
    };
    setTranslation({ ...translation, response: new_response });
  };

  const updateDict = (state, setState, update_object) => {
    setState({ ...state, ...update_object });
  };

  const getTranslation = async (video_id) => {
    updateDict(translation, setTranslation, { error: "", loading: true });
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/get_translation`,
        {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
          params: {
            request_id: video_id,
          },
        }
      );
      console.log(response.data);
      if (response.data.status === 0) {
        console.log("updating dict...", response.data.translation_response);
        updateDict(translation, setTranslation, {
          response: response.data.translation_response,
          loading: false,
        });
        setUserVoices(response.data.user_voices);
      } else if (response.data.status === -1) {
        localStorage.removeItem("token");
        localStorage.removeItem("email");
        navigate("/auth");
      } else {
        updateDict(translation, setTranslation, {
          loading: false,
          error: "Something went wrong.Please try again later!",
        });
      }
    } catch (err) {
      console.log(err);
      updateDict(translation, setTranslation, {
        loading: false,
        error: "Something went wrong.Please try again later!",
      });
    }
  };

  // //save every 10 seconds
  // useEffect(() => {
  //   const interval = setInterval(() => {
  //     if (translation.response) {
  //       verifyTranslation(true,true);
  //     }
  //   }, 60000);
  //   return () => clearInterval(interval);
  // }, [translation.response]);

  const verifyTranslation = async (only_save,auto_save) => {
    !auto_save && updateDict(translationVerification, setTranslationVerification, {
      error: "",
      loading: true,
    });
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/update_translation`,
        {
          video_id: selectedVideoId,
          translation: translation.response,
          voice_style: voiceStyle,
          use_cloned_voice: useClonedVoice,
          only_save: only_save
        },
        {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        }
      );
      console.log(response.data);
      if (response.data.status === 0) {
        !auto_save && updateDict(translationVerification, setTranslationVerification, {
          loading: false,
        });
        if(!only_save){
        setModalIsOpen(false);
        }
      } else if (response.data.status === -1) {
        localStorage.removeItem("token");
        localStorage.removeItem("email");
        navigate("/auth");
      } else {
        updateDict(translationVerification, setTranslationVerification, {
          loading: false,
          error: "Something went wrong.Please try again later!",
        });
      }
    } catch (err) {
      console.log(err);
      updateDict(translationVerification, setTranslationVerification, {
        loading: false,
        error: "Something went wrong.Please try again later!",
      });
    }
  };

  const getAccurateEnglishTranslation = async (index) => {
    setEnglishTranslationLoading(true)
    try {
      const response = await axios.get(
        "https://apx.translatetracks.com/get_accurate_translation",
        {
          headers: {
            token: localStorage.getItem("token"),
          },
          params: {
            text: translation.response[index].translation,
          },
        }
      );
      console.log(response.data);
      if (response.data.status === 0) {
        setEnglishTranslationLoading(false)
        //set translation of index
        updateTranslationResponse(index, {english_translation:response.data.data})
      } else if (response.data.status === -1) {
        setEnglishTranslationLoading(false)
        localStorage.removeItem("token");
        localStorage.removeItem("email");
        navigate("/auth");
      } else {
        setEnglishTranslationLoading(false)
      }
    } catch (err) {
      setEnglishTranslationLoading(false)
      console.log(err);
    }
    
  }

  const getTranslationFromId = async (index) => {
    setTranslationLoading(true)
    try {
      const response = await axios.get(
        "https://apx.translatetracks.com/get_translation_from_session",
        {
          headers: {
            token: localStorage.getItem("token"),
          },
          params: {
            text: translation.response[index].transcription_para,
            session_id: selectedVideoId 
          },
        }
      );
      console.log(response.data);
      if (response.data.status === 0) {
        setTranslationLoading(false)
        //set translation of index
        updateTranslationResponse(index, {translation:translation.response[index].translation + "\n" +  response.data.data})
      } else if (response.data.status === -1) {
        setTranslationLoading(false)
        localStorage.removeItem("token");
        localStorage.removeItem("email");
        navigate("/auth");
      } else {
        setTranslationLoading(false)
      }
    } catch (err) {
      setTranslationLoading(false)
      console.log(err);
    }
    
  }

  useEffect(() => {
    getTranslation(selectedVideoId);
  }, []);

  if (translation.error) {
    return (
      <Typography
        style={{
          color: "red",
          fontSize: 14,
          textAlign: "center",
          marginTop: 5,
        }}
      >
        {translation.error}
      </Typography>
    );
  } else if (translation.loading || !translation.response) {
    return (
      <div style={{ margin: "auto", width: "20%" }}>
        <LinearProgress style={{ marginTop: 20 }} />
      </div>
    );
  } else {
    return (
      <React.Fragment>
        <Typography variant="h6" style={{ color: "#AFB8C1" }}>
          Verify Translation
        </Typography>
        <Typography style={{ fontSize: 12, color: "#AFB8C1" }}>
          (You can use online multilingual keyboards to type text in languages
          other than english)
        </Typography>
        {translation.response.map((item, index) => {
          return (
            <div
              style={{
                display: item.speaker === 'emotion' ? "none" : "flex",
                flexDirection: "column",
                margin: 10,
              }}
            >
              <Typography
                style={{ alignSelf: "center", marginTop: 20, color: "#AFB8C1" }}
                variant="h6"
              >
                {"Paragraph #" + (index + 1)}
              </Typography>
              <div style={{ display: "flex" }}>
                <Typography style={{ color: "#AFB8C1", alignSelf: "center" }}>
                  {item.transcription_para}
                </Typography>
                {
                  translationLoading ? <CircularProgress style={{alignSelf:'center',marginLeft:10}} size={20} /> :
                <IconButton
                  onClick={() => getTranslationFromId(index)}
                >
                  <TranslateIcon
                    style={{ color: "white" }}
                    fontSize="medium"
                  />
                </IconButton>
                }
              </div>
              <Typography style={{ marginBottom: 10, color: "#AFB8C1", fontSize: 12 }}>
                {"(" + item.transcription_translation + ")"}
              </Typography>
              <div style={{ display: "flex" }}>
                <EmptyTextarea
                  key={index}
                  value={item.translation}
                  onChange={(event) => {
                    handleTranslationChange(event, index);
                  }}
                // maxLength={textLimit[index]}
                />
                {
                  englishTranslationLoading ? <CircularProgress style={{alignSelf:'center',marginLeft:10}} size={20} /> :
                <IconButton
                  onClick={() => getAccurateEnglishTranslation(index)}

                >
                  <TranslateIcon
                    style={{ color: "white" }}
                    fontSize="medium"
                  />
                </IconButton>
                }
              </div>
              <Typography style={{ color: "#afb8c1" }}>{item.english_translation}</Typography>
              { item.mapped &&
                <React.Fragment>
              <Typography  style={{ alignSelf: "center", marginTop: 20,marginBottom:20, color: "#AFB8C1" }}
                variant="h7">Original</Typography>
              <Typography style={{ color: "#afb8c1",textAlign:"center" }}>{item.original_text}</Typography>
              <Typography style={{ marginBottom: 10, color: "#AFB8C1", fontSize: 12,textAlign:"center" }}>
                {"("+ item.original_text_english_translation + ")"}
              </Typography>
              </React.Fragment>
            }

            </div>
          );
        })}
        <Typography
          style={{ textAlign: "center", marginTop: 30, color: "#AFB8C1" }}
          variant="h6"
        >
          Audio Configuration
        </Typography>
        <div style={{ display: "flex", flexDirection: "column" }}>
          <FormControl
            style={{
              display: "flex",
              width: 200,
              margin: "auto",
              marginTop: 10,
              marginBottom: 10,
            }}
          >
            <InputLabel id="style">Voice Style</InputLabel>
            <Select
              labelId="style"
              id="demo-simple-select"
              value={voiceStyle}
              label="Voice Style"
              onChange={(event) => setVoiceStyle(event.target.value)}
            >
              <MenuItem value={"0"}>Expressive</MenuItem>
              <MenuItem value={"1"}>Balanced</MenuItem>
              <MenuItem value={"2"}>Monologue</MenuItem>
            </Select>
          </FormControl>
          {userVoices && (
            <FormControlLabel
              control={
                <Checkbox
                  checked={useClonedVoice}
                  onChange={handleUseClonedVoiceChange}
                />
              }
              label="Use cloned voice"
              style={{ margin: "auto", color: "white" }}
            />
          )}
        </div>
        {translationVerification.loading ? (
          <div style={{ width: "20%", margin: "auto" }}>
            <LinearProgress style={{ marginTop: 10 }} />
          </div>
        ) : (
          <React.Fragment>
            <div style={{ display: "flex", justifyContent: "center" }}>
              <Button
                variant="contained"
                color="primary"
                style={{ alignSelf: "center", marginTop: 10 }}
                onClick={() => verifyTranslation(false)}
              >
                Generate audio
              </Button>
              <Button
                variant="contained"
                color="primary"
                style={{ alignSelf: "center", marginTop: 10, marginLeft: 10 }}
                onClick={() => verifyTranslation(true)}
              >
                Save
              </Button>
            </div>
            {translationVerification.error && (
              <Typography
                style={{
                  color: "red",
                  fontSize: 14,
                  textAlign: "center",
                  marginTop: 5,
                }}
              >
                {translationVerification.error}
              </Typography>
            )}
          </React.Fragment>
        )}
      </React.Fragment>
    );
  }
};

export default TranslationModal;
