import "../styles/CloneModal.css";

import { Button, IconButton, LinearProgress, Pagination, Stack, TextField, Tooltip, Typography,CircularProgress } from "@mui/material";
import React, { useEffect, useState } from "react";

import AudioGroup from "./AudioGroup";
import ContentCutIcon from '@mui/icons-material/ContentCut';
import EmptyTextarea from "./EmptyTextArea";
import ExpandIcon from '@mui/icons-material/Expand';
import ReactModal from "react-modal";
import RecommendationModal from "./RecommendationModal";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import TranslateIcon from '@mui/icons-material/Translate';
import AccessTimeIcon from '@mui/icons-material/AccessTime';



const getTranslationListFromAudioObject = (audioObject) => {
  let translationList = []
  audioObject.forEach((audioObject) => {
    translationList.push(audioObject['translation']['text'])
  })
  return translationList

}


const AudioModal = ({ selectedVideoId, setModalIsOpen }) => {
  const navigate = useNavigate();
  const [audio, setAudio] = useState({
    response: null,
    loading: false,
    error: "",
  });
  const [regenerate, setRegenerate] = useState({ loading: null, error: "" });
  const [audioVerification, setAudioVerification] = useState({
    loading: false,
    error: "",
  });

  const [isRecommendationModalOpen, setIsRecommendationModalOpen] = useState(false);

  const [cutIndex, setCutIndex] = useState(null)
  const [videoLinks, setVideoLinks] = useState(null);
  const [alterContext, setAlterContext] = useState(null);
  const [charactersLeft, setCharactersLeft] = useState(0)
  const [pageNumber, setPageNumber] = useState(1)
  const [totalPages, setTotalPages] = useState(1)
  const [jumpSegmentNumber, setJumpSegmentNumber] = useState('')
  const [originalTranslation, setOriginalTranslation] = useState('')
  const [translationLoading, setTranslationLoading] = useState(false)
  const [predictionLoading, setPredictionLoading] = useState(false)
  const [englishTranslationLoading, setEnglishTranslationLoading] = useState(false)

  const handlePageChange = (event, page) => {
    setPageNumber(page)
  }

  const updateTranslationFromRecommendation = (index, recommendation) => {
    updateAudioResponse(index, {
      translation: { ...audio.response[index]["translation"], text: recommendation },
    });
  }

  useEffect(() => {
    if (cutIndex !== null && alterContext) {
      setIsRecommendationModalOpen(true)
    }
  }, [cutIndex])

  const updateVideoLink = (index, link) => {
    console.log(index, link)
    let new_video_links = [...videoLinks];
    new_video_links[index] = link;
    console.log(new_video_links)
    setVideoLinks(new_video_links);
  };

  const updateAudioResponse = (update_index, update_object) => {
    let new_response = [...audio.response];
    new_response[update_index] = {
      ...audio.response[update_index],
      ...update_object,
    };
    setAudio({ ...audio, response: new_response });
  };

  const updateLoadersAndErrors = (state, setState, update_object) => {
    setState({ ...state, ...update_object });
  };

  const updateRegenerateLoader = (update_index, update_value) => {
    let new_loader = [...regenerate.loading];
    new_loader[update_index] = update_value;
    setRegenerate({ ...regenerate, loading: new_loader });
  };

  useEffect(() => {
    console.log("calling...", selectedVideoId);
    getAudio(selectedVideoId, pageNumber);
  }, [pageNumber]);

  const closeRecommendationModal = () => {
    setIsRecommendationModalOpen(false)
    setCutIndex(null)
    setAlterContext(null)
  }

  const handleTranslationChange = (event, index) => {
    // updateAudioResponse(index, { translation: event.target.value });
    updateAudioResponse(index, {
      translation: { ...audio.response[index]["translation"], text: event.target.value },
    });
  };
  const handleEnglishTranslationChange = (event, index) => {
    updateAudioResponse(index, {
      english_translation: event.target.value,
    });
  }

  const handleSegmentValueChange = (event) => {
    const value = event.target.value;
    // Only set the state if the value is a number or empty
    if (!isNaN(value)) {
      setJumpSegmentNumber(value);
    }
  };

  const handleKeyDown = async (event) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      const pageNumber = Math.ceil(jumpSegmentNumber / 10)
      console.log(pageNumber)
      setPageNumber(pageNumber)
    }
  };

  const getAudio = async (video_id, page_number) => {
    updateLoadersAndErrors(audio, setAudio, { error: "", loading: true });
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/get_audio`,
        {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
          params: {
            request_id: video_id,
            page_number: page_number
          },
        }
      );
      console.log("audio ", response.data);
      if (response.data.status === 0) {
        updateLoadersAndErrors(audio, setAudio, {
          response: response.data.audio_object,
        });
        updateLoadersAndErrors(regenerate, setRegenerate, {
          loading: new Array(response.data.audio_object.length).fill(false),
        });
        setVideoLinks(response.data.video_links);
        setCharactersLeft(response.data.characters_left)
        setTotalPages(response.data.total_pages)
        setOriginalTranslation(getTranslationListFromAudioObject(response.data.audio_object))
      } else if (response.data.status === -1) {
        localStorage.removeItem("token");
        localStorage.removeItem("email");
        navigate("/auth");
      } else {
        updateLoadersAndErrors(audio, setAudio, {
          error: "Something went wrong.Please try again later!",
          loading: false,
        });
      }
    } catch (err) {
      console.log(err);
      updateLoadersAndErrors(audio, setAudio, {
        error: "Something went wrong.Please try again later!",
        loading: false,
      });
    }
  };

  const getAccurateEnglishTranslation = async (index) => {
    setEnglishTranslationLoading(true)
    try {
      const response = await axios.get(
        "https://apx.translatetracks.com/get_accurate_translation",
        {
          headers: {
            token: localStorage.getItem("token"),
          },
          params: {
            text: audio.response[index].translation.text,
          },
        }
      );
      console.log(response.data);
      if (response.data.status === 0) {
        setEnglishTranslationLoading(false)
        updateAudioResponse(index, {
          english_translation: response.data.data,
        });
      } else if (response.data.status === -1) {
        setEnglishTranslationLoading(false)
        localStorage.removeItem("token");
        localStorage.removeItem("email");
        navigate("/auth");
      } else {
        setEnglishTranslationLoading(false)
      }
    } catch (err) {
      setEnglishTranslationLoading(false)
      console.log(err);
    }
    
  }
  

  const onRegenerateClick = async (segment_id, video_id) => {
    updateRegenerateLoader(segment_id, true);
    try {
      const response = await axios.get(
        "https://apx.translatetracks.com/regenerate_audio",
        {
          headers: {
            token: localStorage.getItem("token"),
          },
          params: {
            segment_id: (pageNumber - 1) * 10 + segment_id,
            session_id: video_id,
            text: audio.response[segment_id]["translation"].text,
          },
        }
      );
      console.log(response.data);
      if (response.data.status === 0) {
        updateRegenerateLoader(segment_id, false);
        updateAudioResponse(segment_id, { audio_info: response.data.data });
        updateVideoLink(segment_id, { 'video_link': response.data.video_link });
        setCharactersLeft(response.data.characters_left)
      } else if (response.data.status === -1) {
        localStorage.removeItem("token");
        localStorage.removeItem("email");
        navigate("/auth");
      } else {
        updateRegenerateLoader(segment_id, false);
        updateLoadersAndErrors(regenerate, setRegenerate, {
          error: "Something went wrong.Please try again later!",
        });
      }
    } catch (err) {
      console.log(err);
      updateRegenerateLoader(segment_id, false);
      updateLoadersAndErrors(regenerate, setRegenerate, {
        error: "Something went wrong.Please try again later!",
      });
    }
  };

  const getTranslationFromId = async (index) => {
    setTranslationLoading(true)
    try {
      const response = await axios.get(
        "https://apx.translatetracks.com/get_translation_from_session",
        {
          headers: {
            token: localStorage.getItem("token"),
          },
          params: {
            text: audio.response[index].english_translation,
            session_id: selectedVideoId 
          },
        }
      );
      console.log(response.data);
      if (response.data.status === 0) {
        setTranslationLoading(false)
        //set translation of index
        updateTranslationFromRecommendation(index, response.data.data)
      } else if (response.data.status === -1) {
        setTranslationLoading(false)
        localStorage.removeItem("token");
        localStorage.removeItem("email");
        navigate("/auth");
      } else {
        setTranslationLoading(false)
      }
    } catch (err) {
      setTranslationLoading(false)
      console.log(err);
    }
    
  }

  const predictDuration = async (index) => {
    setPredictionLoading(true)
    try {
      const response = await axios.get(
        "https://apx.translatetracks.com/predict_duration",
        {
          headers: {
            token: localStorage.getItem("token"),
          },
          params: {
            text: audio.response[index].translation.text,
            session_id: selectedVideoId,
            speaker: audio.response[index].translation.speaker
          },
        }
      );
      console.log(response.data);
      if (response.data.status === 0) {
        setPredictionLoading(false)
        //set translation of index
        // updateTranslationFromRecommendation(index, response.data.data)
        updateAudioResponse(index, {
          translation: { ...audio.response[index]["translation"], target_duration: Math.round(response.data.data/10)/100 },
        });
      } else if (response.data.status === -1) { 
        setPredictionLoading(false)
        localStorage.removeItem("token");
        localStorage.removeItem("email");
        navigate("/auth");
      } else {
        setPredictionLoading(false)
      }
    } catch (err) {
      setPredictionLoading(false)
      console.log(err);
    }
    
  }



  const verifyAudio = async () => {
    updateLoadersAndErrors(audioVerification, setAudioVerification, {
      loading: true,
      error: "",
    });
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/update_audio`,
        {
          video_id: selectedVideoId,
        },
        {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        }
      );
      console.log(response.data);
      if (response.data.status === 0) {
        updateLoadersAndErrors(audioVerification, setAudioVerification, {
          loading: false,
        });
        setModalIsOpen(false);
      } else if (response.data.status === -1) {
        localStorage.removeItem("token");
        localStorage.removeItem("email");
        navigate("/auth");
      } else {
        updateLoadersAndErrors(audioVerification, setAudioVerification, {
          loading: false,
          error: "Something went wrong.Please try again later!",
        });
      }
    } catch (err) {
      console.log(err);
      updateLoadersAndErrors(audioVerification, setAudioVerification, {
        loading: false,
        error: "Something went wrong.Please try again later!",
      });
    }
  };

  if (audio.error) {
    return (
      <Typography
        style={{
          color: "red",
          fontSize: 14,
          textAlign: "center",
          marginTop: 5,
        }}
      >
        {audio.error}
      </Typography>
    );
  } else if (audio.loading || !audio.response || !videoLinks) {
    return (
      <div style={{ margin: "auto", width: "20%" }}>
        <LinearProgress style={{ marginTop: 20 }} />
      </div>
    );
  } else {
    return (
      <React.Fragment>
        <div>
          <Typography style={{ color: "white", margin: 10 }}>{"Characters left: " + charactersLeft}</Typography>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            marginTop: 20,
          }}
        >
          {audio.response.map((audio_object, index) => {
            return (
              <div style={{ marginTop: 20, display: audio_object["translation"].speaker === 'emotion' ? "none" : "block" }}>
                <Typography style={{ color: "white", textAlign: "center" }}>
                  {"Segment # " + (10 * (pageNumber - 1) + index + 1) + "(" + audio_object["translation"].time + " seconds)"}
                </Typography>
                <div style={{ display: "flex" }}>
                  <video
                    controls
                    style={{ margin: "auto", width: "50%", marginTop: 10, maxHeight: 400 }}
                    key={videoLinks[index]['video_link']}
                  >
                    <source src={videoLinks[index]['video_link']}
                      type="video/mp4" />
                  </video>
                </div>
                <AudioGroup
                  src={audio_object["audio_info"]["audio_link"]}
                  left={audio_object["audio_info"]["left"]}
                  onRegenerateClick={onRegenerateClick}
                  regenerateLoading={regenerate.loading}
                  regenerationError={regenerate.error}
                  index={index}
                  video_id={selectedVideoId}
                  regenerateDisabled={charactersLeft < audio_object['translation'].text.length}
                />
                <Typography style={{ color: "#afb8c1", textAlign: "center", marginBottom: 10 }}>
                  {originalTranslation[index]}
                </Typography>
                <div style={{ display: "grid", gridTemplateColumns: "80% 1fr 1fr 1fr 1fr 1fr" }}>
                  <EmptyTextarea
                    key={audio_object["id"]}
                    value={audio_object["translation"].text}
                    onChange={(event) => {
                      handleTranslationChange(event, index);
                    }}
                    // maxLength={textLimit[index]}
                    style={{ width: "98%" }}
                  />
                  <Typography style={{ color: "#afb8c1", marginLeft: 10, fontSize: 12,alignSelf:"center"}}> {"(" + Math.round(audio_object['translation'].target_duration * 100) / 100 + ")"}</Typography>
                  <Tooltip title="Get a shorter translation">
                    <IconButton onClick={() => {
                      setCutIndex(index)
                      setAlterContext('shorten')
                    }} disableRipple>
                      <ContentCutIcon
                        style={{ color: "white" }}
                        fontSize="medium" />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="Get a longer translation">
                    <IconButton onClick={() => {
                      setCutIndex(index)
                      setAlterContext('expand')
                    }} disableRipple>
                      <ExpandIcon
                        style={{ color: "white" }}
                        fontSize="medium" />
                    </IconButton>
                  </Tooltip>
                  {predictionLoading ? <CircularProgress size={20} style={{ marginLeft: 10,alignSelf:"center" }} /> :
                  (
                  <Tooltip title="Predict Time">
                    <IconButton  
                      disableRipple
                      onClick={() => predictDuration(index)}>
                      <AccessTimeIcon
                        style={{ color: "white" }}
                        fontSize="medium" />
                    </IconButton>
                  </Tooltip>
                  )}
                  <IconButton
                  onClick={() => getAccurateEnglishTranslation(index)}
                  >
                    <TranslateIcon
                      style={{ color: "white" }}
                      fontSize="medium"
                    />
                  </IconButton>
                  <Typography style={{ color: charactersLeft < audio_object['translation'].text.length ? "red" : "white", marginLeft: 10, fontSize: 12, textAlign: "right" }}>
                    {charactersLeft < audio_object['translation'].text.length ? "Not enough characters left!" : audio_object['translation'].text.length + "/" + charactersLeft}
                  </Typography>
                  <EmptyTextarea
                    // key={audio_object["id"]}
                    value={audio_object["english_translation"]}
                    onChange={(event) => {
                      handleEnglishTranslationChange(event, index);
                    }}
                    // maxLength={textLimit[index]}
                    style={{ width: "98%", gridRowStart: 3 }}
                  />
                  {translationLoading ? <CircularProgress size={20} style={{ marginLeft: 10, gridRowStart: 3,alignSelf:"center" }} /> :
                  (
                  <IconButton
                    style={{ margin: "auto", gridRowStart: 3 }}
                  onClick={() => getTranslationFromId(index)}
                  >
                    <TranslateIcon
                      style={{ color: "white" }}
                      fontSize="medium"
                    />
                  </IconButton>
                  )}
                </div>

              </div>
            );
          })}
          {audioVerification.loading ? (
            <div style={{ width: "20%", margin: "auto" }}>
              <LinearProgress style={{ marginTop: 10 }} />
            </div>
          ) : (
            <React.Fragment>
              <div style={{ display: "flex", flexDirection: "row" }}>
                <Pagination style={{ alignSelf: "end" }} count={totalPages} page={pageNumber} onChange={handlePageChange} siblingCount={5} boundaryCount={5} />
                <TextField
                  id="standard-basic"
                  label="Jump to segment"
                  variant="standard"
                  onChange={handleSegmentValueChange}
                  value={jumpSegmentNumber}
                  onKeyDown={handleKeyDown} />
              </div>
              <div style={{ display: "flex", justifyContent: "center" }}>
                <Button
                  variant="contained"
                  color="primary"
                  style={{ alignSelf: "center", marginTop: 10 }}
                  onClick={verifyAudio}
                >
                  Verify
                </Button>
              </div>
              {audioVerification.error && (
                <Typography
                  style={{
                    color: "red",
                    fontSize: 14,
                    textAlign: "center",
                    marginTop: 5,
                  }}
                >
                  {audioVerification.error}
                </Typography>
              )}
            </React.Fragment>
          )}
        </div>
        <ReactModal
          isOpen={isRecommendationModalOpen}
          onRequestClose={closeRecommendationModal}
          contentLabel="Example Modal"
          className="CloneModal"
          overlayClassName="Overlay"
        >
          <RecommendationModal segmentIndex={cutIndex}
            updateTranslationFromRecommendation={updateTranslationFromRecommendation}
            closeRecommendationModal={closeRecommendationModal}
            originalText={audio.response[cutIndex] ? audio.response[cutIndex].translation['text'] : null}
            selectedVideoId={selectedVideoId}
            alterContext={alterContext}
            pageNumber={pageNumber}
          />
        </ReactModal>
      </React.Fragment>
    );
  }
};

export default AudioModal;
