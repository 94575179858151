import React from "react"
import { findSegmentDetailsFromTime, findSegmentFromId } from "../helpers/functions"

export const useTimlineLogic = (leastCount, duration, timelineWidth, totalWidth, sliderRef,playerRef,rangeSliderRef,segments,status) => {
    const [startTime, setStartTime] = React.useState(0)
    const [endTime, setEndTime] = React.useState(leastCount)
    const [currentTime, setCurrentTime] = React.useState(0)
    const timelineRef = React.useRef(null)
    const [selectedBreakpoint,setSelectedBreakpoint] = React.useState(null)
    const [timelineClicked,setTimelineClicked] = React.useState(false)
    const [breakpoints,setBreakpoints] = React.useState(null)

    React.useEffect(() => {
        if (
            Math.floor(currentTime) === Math.floor(endTime) &&
            Math.floor(currentTime) !== Math.floor(duration)
        ) {
            console.log(
                "ct ",
                Math.floor(currentTime),
                Math.floor(endTime),
                duration
            );
            const secondsPerPixel = duration / totalWidth;
            const newScrollLeft = currentTime / secondsPerPixel;
            if (sliderRef.current) {
                sliderRef.current.scrollLeft = newScrollLeft;
                const newStartTime = newScrollLeft * secondsPerPixel;
                const newEndTime =
                    newStartTime + timelineWidth * secondsPerPixel < duration
                        ? newStartTime + timelineWidth * secondsPerPixel
                        : duration;
                setStartTime(newStartTime);
                setEndTime(newEndTime);
            }
        }
    }, [currentTime]);


    const handleTimelineScroll = (e) => {
        const secondsPerPixel = duration / totalWidth;
        const newStartTime = e.currentTarget.scrollLeft * secondsPerPixel;
        const newEndTime = newStartTime + timelineWidth * secondsPerPixel;
        setStartTime(newStartTime);
        setEndTime(newEndTime);
    };

    const handleTimelineClick = (e) => {
        const secondsPerPixel =
          (endTime - startTime) / timelineRef.current.offsetWidth;
        const clickTime =
          (e.clientX - rangeSliderRef.current.getBoundingClientRect().left) *
          secondsPerPixel;
        playerRef.current.seekTo(clickTime, "seconds");
        // bgAudioRef.current.seekTo(clickTime, "seconds");
        status === 'ag_waiting' && setTimelineClicked(true)
      };

    const handleBreakpointClick = (event,breakpoint) => {
        setSelectedBreakpoint(findSegmentDetailsFromTime(segments, breakpoint));
        playerRef.current.seekTo(breakpoint - 1, "seconds");
        // bgAudioRef.current.seekTo(breakpoint - 1, "seconds");
        event.stopPropagation();
    }


    const scrollToSegmentId = (segment_id,setCurrentSegmentId) => {
        const secondsPerPixel = duration / totalWidth;
        const newScrollLeft = findSegmentFromId(segments,segment_id).start / secondsPerPixel;
        if (sliderRef.current) {
          sliderRef.current.scrollLeft = newScrollLeft;
          const newStartTime = sliderRef.current.scrollLeft * secondsPerPixel;
          const newEndTime =
            newStartTime + timelineWidth * secondsPerPixel < duration
              ? newStartTime + timelineWidth * secondsPerPixel
              : duration;
          console.log(newStartTime,newEndTime)
          setStartTime(newStartTime);
          setEndTime(newEndTime);
          console.log(findSegmentFromId(segments,segment_id).start)
          setCurrentTime(findSegmentFromId(segments,segment_id).start);
          setCurrentSegmentId(segment_id)
        }
    }

    const scrollToNextPage = () => {
        const secondsPerPixel = duration / totalWidth;
        const newScrollLeft = currentTime / secondsPerPixel;
        if (sliderRef.current) {
            sliderRef.current.scrollLeft = newScrollLeft;
            const newStartTime = newScrollLeft * secondsPerPixel;
            const newEndTime =
                newStartTime + timelineWidth * secondsPerPixel < duration
                    ? newStartTime + timelineWidth * secondsPerPixel
                    : duration;
            setStartTime(newStartTime);
            setEndTime(newEndTime);
        }
    }

    const scrollToPreviousPage = () => {
        const secondsPerPixel = duration / totalWidth;
        const newScrollLeft = (currentTime - leastCount) / secondsPerPixel;
        if (sliderRef.current) {
            sliderRef.current.scrollLeft = newScrollLeft;
            const newStartTime = newScrollLeft * secondsPerPixel;
            const newEndTime =
                newStartTime + timelineWidth * secondsPerPixel < duration
                    ? newStartTime + timelineWidth * secondsPerPixel
                    : duration;
            setStartTime(newStartTime);
            setEndTime(newEndTime);
        }
    }

    return {startTime,endTime,currentTime,breakpoints,selectedBreakpoint,timelineClicked, setStartTime,setEndTime,setCurrentTime, setBreakpoints,handleTimelineScroll,handleTimelineClick,handleBreakpointClick,setTimelineClicked, timelineRef,scrollToSegmentId,scrollToNextPage,scrollToPreviousPage,setSelectedBreakpoint}

}