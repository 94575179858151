
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";

const SpeakerSelect = ({ speakerLegend,segment,handleSpeakerChange }) => {
    return (
        <FormControl
            style={{
                color: "white",
                width: "80%",
                alignSelf: "center",
            }}
        >
            <InputLabel id="speaker" style={{ color: "white" }}>
                Speaker
            </InputLabel>
            <Select
                labelId="speaker"
                id="demo-simple-select"
                  value={segment.speaker}
                // value={"A"}
                label="Speaker"
              onChange={(e) => handleSpeakerChange(e,segment.id )}
            >
                {speakerLegend &&
                    speakerLegend.map((speaker_dict,i) => {
                        return <MenuItem value={speaker_dict.speaker}>{speaker_dict.speaker}</MenuItem>;
                    })}
            </Select>
        </FormControl>
    )
}

export default SpeakerSelect;