import "../styles/ShowCase.css"

import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import DataGroup from "../components/DataGroup";
import Fab from '@mui/material/Fab';
import { Typography } from "@mui/material";
import elonImage from "../assets/images/elon.png";
import novakImage from "../assets/images/novak.png";
import speakmultiLogo from "../assets/images/speakmulti_logo.png";

// const translations = [
//   {
//     "source_url":"https://youtu.be/bNl72Ap73k0",
//     "source_language":"English",
//     "title":"Warographics",
//     "subtitle":"",
//     "targets":[
//       {
//         "target_url":"https://youtu.be/V7sSReMad4I",
//         "target_language":"Spanish"
//       }
//     ]
//   },
//   {
//     "source_url":"https://youtu.be/wx-6CEH-KH4",
//     "source_language":"English",
//     "title":"Futurology",
//     "subtitle":"",
//     "targets":[
//       {
//         "target_url":"https://youtu.be/cui02p0Cul0",
//         "target_language":"Spanish"
//       }
//     ]
//   },
//   {
//     "source_url":"https://youtu.be/oOAbi-w4A84",
//     "source_language":"English",
//     "title":"B1M",
//     "subtitle":"",
//     "targets":[
//       {
//         "target_url":"https://youtu.be/lDoq_fwKql0",
//         "target_language":"Spanish"
//       }
//     ]
//   },
//   {
//     "source_url":"https://youtu.be/DY5gweeuBLc",
//     "source_language":"English",
//     "title":"Practical Engineering",
//     "subtitle":"",
//     "targets":[
//       {
//         "target_url":"https://youtu.be/FPgOOQfi7VI",
//         "target_language":"Spanish"
//       }
//     ]
//   },
//   {
//     "source_url":"https://youtu.be/PiNMa5qCzIw",
//     "source_language":"English",
//     "title":"DW",
//     "subtitle":"",
//     "targets":[
//       {
//         "target_url":"https://youtu.be/3NmZOO9J3ek",
//         "target_language":"Spanish"
//       }
//     ]
//   },
//   {
//     "source_url":"https://youtu.be/1ARMM7hLV0s",
//     "source_language":"Spanish",
//     "title":"FT",
//     "subtitle":"",
//     "targets":[
//       {
//         "target_url":"https://youtu.be/C8w56ELQ87g",
//         "target_language":"English"
//       }
//     ]
//   },
//   {
//     "source_url":"https://youtu.be/dWMYtUFKC8Q",
//     "source_language":"English",
//     "title":"",
//     "subtitle":"Explained With Dom",
//     "targets":[
//       {
//         "target_url":"https://youtu.be/p0giudMZD-E",
//         "target_language":"Spanish"
//       }
//     ]
//   },
//   {
//     "source_url":"https://youtu.be/4W5l6LX8QHY",
//     "source_language":"English",
//     "title":"Kamome",
//     "subtitle":"",
//     "targets":[
//       {
//         "target_url":"https://youtu.be/eztcBWoVnYo",
//         "target_language":"Spanish"
//       }
//     ]
//   },
//   {
//     "source_url":"https://youtu.be/e1vZ_rJ9gFk",
//     "source_language":"English",
//     "title":"OBF",
//     "subtitle":"",
//     "targets":[
//       {
//         "target_url":"https://youtu.be/aVcCmuIYdF0",
//         "target_language":"Spanish"
//       }
//     ]
//   },
//   {
//     "source_url":"https://youtu.be/QY1cVDNJvJI",
//     "source_language":"English",
//     "title":"Johnny Harris",
//     "subtitle":"",
//     "targets":[
//       {
//         "target_url":"https://youtu.be/sAtYqX2ulNI",
//         "target_language":"Spanish"
//       }
//     ]
//   },
//   {
//     "source_url":"https://youtu.be/_SG7l5rNHGE",
//     "source_language":"English",
//     "title":"QuantaMag",
//     "subtitle":"",
//     "targets":[
//       {
//         "target_url":"https://youtu.be/W4NkkOQr3rc",
//         "target_language":"Spanish"
//       }
//     ]
//   },
//   {
//     "source_url":"https://youtu.be/Uz0R2CvrxNQ",
//     "source_language":"English",
//     "title":"Freethink",
//     "subtitle":"",
//     "targets":[
//       {
//         "target_url":"https://youtu.be/-rI1UlBvklM",
//         "target_language":"Spanish"
//       }
//     ]
//   },
//   {
//     "source_url":"https://youtu.be/ezuKzrT1K_A",
//     "source_language":"Hindi",
//     "title":"Gareeb Scientist",
//     "subtitle":"",
//     "targets":[
//       {
//         "target_url":"https://youtu.be/1m8cae1IGAs",
//         "target_language":"English"
//       }
//     ]
//   },
//   {
//     "source_url":"https://youtu.be/mZ1DynXNtsY",
//     "source_language":"Hindi",
//     "title":"Nitish Rajput",
//     "subtitle":"",
//     "targets":[
//       {
//         "target_url":"https://youtu.be/SYSHpjFHdQI",
//         "target_language":"English"
//       }
//     ]
//   },
//   {
//     "source_url":"https://youtu.be/26kyJn8u3Q8",
//     "source_language":"Hindi",
//     "title":"Dhruv Rathee",
//     "subtitle":"",
//     "targets":[
//       {
//         "target_url":"https://youtu.be/OPlRIAs-r7Y",
//         "target_language":"English"
//       },
//       {
//         "target_url":"https://youtu.be/sBYdpPFCyA8",
//         "target_language":"English"
//       }
//     ]
//   },
//   {
//     "source_url":"https://youtu.be/w4Uns6VFy8w",
//     "source_language":"French",
//     "title":"Le Fossoyeur de Films",
//     "subtitle":"",
//     "targets":[
//       {
//         "target_url":"https://www.youtube.com/watch?v=lluT0sa0bZY",
//         "target_language":"English"
//       }
//     ]
//   },
//   {
//     "source_url":"https://youtu.be/87c73R2PPCI",
//     "source_language":"French",
//     "title":"Fast Good Cuisine",
//     "subtitle":"",
//     "targets":[
//       {
//         "target_url":"https://youtu.be/JnpEWlbBes0",
//         "target_language":"English"
//       }
//     ]
//   },
//   {
//     "source_url":"https://youtu.be/zH-V5dIYzHs",
//     "source_language":"Italian",
//     "title":"MattleyIt",
//     "subtitle":"",
//     "targets":[
//       {
//         "target_url":"https://youtu.be/koinXbzKIH0",
//         "target_language":"English"
//       }
//     ]
//   },
//   {
//     "source_url":"https://youtu.be/5wvE7vTfSIg",
//     "source_language":"Italian",
//     "title":"AlmaPhysio",
//     "subtitle":"",
//     "targets":[
//       {
//         "target_url":"https://youtu.be/RB_NIrsUtzI",
//         "target_language":"English"
//       }
//     ]
//   },
//   {
//     "source_url":"https://youtu.be/MdvG67xv1hQ",
//     "source_language":"French",
//     "title":"Cyrus North",
//     "subtitle":"",
//     "targets":[
//       {
//         "target_url":"https://youtu.be/5vna8okDTww",
//         "target_language":"English"
//       }
//     ]
//   },
//   {
//     "source_url":"https://youtu.be/LzASheiqgII",
//     "source_language":"German",
//     "title":"xLaeta",
//     "subtitle":"",
//     "targets":[
//       {
//         "target_url":"https://youtu.be/9ddiwcFn7-0",
//         "target_language":"English"
//       }
//     ]
//   },
//   {
//     "source_url":"https://youtu.be/YGff_jcOg7w",
//     "source_language":"German",
//     "title":"Paluten",
//     "subtitle":"",
//     "targets":[
//       {
//         "target_url":"https://youtu.be/95lJv-oJGdw",
//         "target_language":"English"
//       }
//     ]
//   },
//   {
//     "source_url":"https://youtu.be/Z98I1myGFU4",
//     "source_language":"German",
//     "title":"Malwanne",
//     "subtitle":"",
//     "targets":[
//       {
//         "target_url":"https://youtu.be/4WpsTx9zoL4",
//         "target_language":"English"
//       }
//     ]
//   },
//   {
//     "source_url":"https://youtu.be/QT68mi_fxWY",
//     "source_language":"German",
//     "title":"Sallys Welt",
//     "subtitle":"",
//     "targets":[
//       {
//         "target_url":"https://youtu.be/OWgnwvnKxVs",
//         "target_language":"English"
//       }
//     ]
//   },
//   {
//     "source_url":"https://youtu.be/VhpeTdetCfY",
//     "source_language":"German",
//     "title":"TheZeroOfTime",
//     "subtitle":"",
//     "targets":[
//       {
//         "target_url":"https://youtu.be/Uj7MWWgRD54",
//         "target_language":"English"
//       }
//     ]
//   },
//   {
//     "source_url":"https://youtu.be/yi-0fUhMoX4",
//     "source_language":"Spanish",
//     "title":"TheWillyRex",
//     "subtitle":"",
//     "targets":[
//       {
//         "target_url":"https://youtu.be/xGBFK5UkaCc",
//         "target_language":"English"
//       }
//     ]
//   },
//   {
//     "source_url":"https://youtu.be/j-RW0YSrJDo",
//     "source_language":"Italian",
//     "title":"Poldo",
//     "subtitle":"",
//     "targets":[
//       {
//         "target_url":"https://youtu.be/gW_gyFa9dJQ",
//         "target_language":"English"
//       }
//     ]
//   }
// ]


// const translations1 = [{
//   "source_url":"https://youtu.be/xcGOEJVQv6w",
//   "source_language":"English",
//   "title":"Lex Friedman",
//   "subtitle":"ft. Marc Andreessen",
//   "targets":[
//     {
//       "target_url":"https://www.youtube.com/watch?v=yyoBRLCK4j8",
//       "target_language":"French"
//     },
//     {
//       "target_url":"https://www.youtube.com/watch?v=s-w1FY8ULrA",
//       "target_language":"Hinglish"
//     },
//     {
//       "target_url":"https://www.youtube.com/watch?v=ZG5uYyda6Bo",
//       "target_language":"Spanish"
//     }
//   ]
// },
// {
//   "source_url":"https://youtu.be/ckLT8iYPwDY",
//   "source_language":"English",
//   "title":"Ranveer Allahbadia",
//   "subtitle":"ft. Piyush Goyal",
//   "targets":[
//     {
//       "target_url":"https://www.youtube.com/watch?v=pJM-vGyJ6v4",
//       "target_language":"Hinglish"
//     }
//   ]
// },
// {
//   "source_url":"https://youtu.be/n9HiL-hL-Kc",
//   "source_language":"English",
//   "title":"Patrick Bet-David",
//   "subtitle":"PBD Podcast",
//   "targets":[
//     {
//       "target_url":"https://www.youtube.com/watch?v=FCAGsFLCyr8",
//       "target_language":"French"
//     },
//     {
//       "target_url":"https://www.youtube.com/watch?v=kiXYIMg60Vc",
//       "target_language":"Hinglish"
//     },
//     {
//       "target_url":"https://www.youtube.com/watch?v=MpFtJYNr7H8",
//       "target_language":"Spanish"
//     }
//   ]
// },
// {
//   "source_url":"https://www.youtube.com/watch?v=ozBsL4QB-jI",
//   "source_language":"English",
//   "title":"Karan Thapar",
//   "subtitle":"ft. Shashi Tharoor",
//   "targets":[
//     {
//       "target_url":"https://www.youtube.com/watch?v=JjCbVzzvsu8",
//       "target_language":"Hinglish"
//     }
//   ]
// },
// {
//   "source_url":"https://youtu.be/At6imnBM4UA",
//   "source_language":"English",
//   "title":"Ranveer Allahbadia",
//   "subtitle":"ft. S. Jaishankar",
//   "targets":[
//     {
//       "target_url":"https://www.youtube.com/watch?v=5sEtOylUNyQ",
//       "target_language":"Hinglish"
//     }
//   ]
// },
// {
//   "source_url":"https://www.youtube.com/watch?v=HEkg1jGm9IE",
//   "source_language":"English",
//   "title":"Lex Friedman",
//   "subtitle":"Monologue(with lipsync beta)",
//   "targets":[
//     {
//       "target_url":"https://www.youtube.com/watch?v=gZmq6ZvYZ4U",
//       "target_language":"Hinglish"
//     }
//   ]
// },
// {
//   "source_url":"https://youtu.be/_r83X0KXObY",
//   "source_language":"English",
//   "title":"Elon Musk",
//   "subtitle":"media interaction",

//   "targets":[
//     {
//       "target_url":"https://www.youtube.com/watch?v=34BWRKvS5Ag",
//       "target_language":"Hinglish"
//     }
//   ]
// },
// {
//   "source_url":"https://www.youtube.com/shorts/vCA1dZxKoVI",
//   "source_language":"English",
//   "title":"Novak Djokovic",
//   "subtitle":"after winning 23rd GS",
//   "thumbnail":novakImage,
//   "targets":[
//     {
//       "target_url":"https://youtube.com/shorts/1MpUIInCakw",
//       "target_language":"German",
//       "thumbnail":novakImage
//     }
//   ]
// },
// {
//   "source_url":"https://www.youtube.com/shorts/lMZPILy3ePQ",
//   "source_language":"English",
//   "title":"Elon Musk",
//   "subtitle":"on meaning of life",
//   "thumbnail":elonImage,
//   "targets":[
//     {
//       "target_url":"https://youtube.com/shorts/-qGZ95NMZYg",
//       "target_language":"Hinglish",
//       "thumbnail":elonImage
//     }
//   ]
// },
// {
//   "source_url":"https://www.youtube.com/watch?v=__H-DmRiA5A",
//   "source_language":"English",
//   "title":"Shekhar Gupta",
//   "subtitle":"Cut the Clutter",
//   "targets":[
//     {
//       "target_url":"https://www.youtube.com/watch?v=ziK8L0j1S24",
//       "target_language":"Hinglish"
//     }
//   ]
// },
// {
//   "source_url":"https://www.youtube.com/watch?v=tN08SuhRHas",
//   "source_language":"French",
//   "title":"Emmanuel Macron",
//   "subtitle":"Monologue",
//   "targets":[
//     {
//       "target_url":"https://www.youtube.com/watch?v=k-NXb8pKrrU",
//       "target_language":"English"
//     }
//   ]
// },
// {
//   "source_url":"https://www.youtube.com/watch?v=UTFSq3afBJI",
//   "source_language":"Italian",
//   "title":"Giorgia Meloni",
//   "subtitle":"Monologue",
//   "targets":[
//     {
//       "target_url":"https://www.youtube.com/watch?v=6_GHfhiUuHg",
//       "target_language":"English"
//     }
//   ]
// },
// {
//   "source_url":"https://www.youtube.com/watch?v=vPf474FO0kU",
//   "source_language":"English",
//   "title":"MKBHD",
//   "subtitle":"ft. Macbook Air 15\"",
//   "targets":[
//     {
//       "target_url":"https://www.youtube.com/watch?v=IYFu_32S2g4",
//       "target_language":"Hinglish"
//     }
//   ]
// },



// ]

const translations = [
  {
     "source_url":"https://youtu.be/QY1cVDNJvJI",
     "source_language":"English",
     "title":"Johnny Harris",
     "subtitle":"",
     "targets":[
        {
           "target_url":"https://youtu.be/sAtYqX2ulNI",
           "target_language":"Spanish"
        }
     ]
  },
  {
     "source_url":"https://youtu.be/VhpeTdetCfY",
     "source_language":"German",
     "title":"TheZeroOfTime",
     "subtitle":"",
     "targets":[
        {
           "target_url":"https://youtu.be/Uj7MWWgRD54",
           "target_language":"English"
        }
     ]
  },
  {
     "source_url":"https://youtu.be/PiNMa5qCzIw",
     "source_language":"English",
     "title":"DW",
     "subtitle":"",
     "targets":[
        {
           "target_url":"https://youtu.be/3NmZOO9J3ek",
           "target_language":"Spanish"
        }
     ]
  },
  {
     "source_url":"https://youtu.be/xcGOEJVQv6w",
     "source_language":"English",
     "title":"Lex Friedman",
     "subtitle":"ft. Marc Andreessen",
     "targets":[
        {
           "target_url":"https://www.youtube.com/watch?v=yyoBRLCK4j8",
           "target_language":"French"
        },
        {
           "target_url":"https://www.youtube.com/watch?v=s-w1FY8ULrA",
           "target_language":"Hinglish"
        },
        {
           "target_url":"https://www.youtube.com/watch?v=ZG5uYyda6Bo",
           "target_language":"Spanish"
        }
     ]
  },
  {
     "source_url":"https://www.youtube.com/shorts/vCA1dZxKoVI",
     "source_language":"English",
     "title":"Novak Djokovic",
     "subtitle":"after winning 23rd GS",
     "thumbnail":novakImage,
     "targets":[
        {
           "target_url":"https://youtube.com/shorts/1MpUIInCakw",
           "target_language":"German",
           "thumbnail":novakImage
        }
     ]
  },
  {
     "source_url":"https://www.youtube.com/watch?v=HEkg1jGm9IE",
     "source_language":"English",
     "title":"Lex Friedman",
     "subtitle":"Monologue(with lipsync beta)",
     "targets":[
        {
           "target_url":"https://www.youtube.com/watch?v=gZmq6ZvYZ4U",
           "target_language":"Hinglish"
        }
     ]
  },
  {
     "source_url":"https://youtu.be/At6imnBM4UA",
     "source_language":"English",
     "title":"Ranveer Allahbadia",
     "subtitle":"ft. S. Jaishankar",
     "targets":[
        {
           "target_url":"https://www.youtube.com/watch?v=5sEtOylUNyQ",
           "target_language":"Hinglish"
        }
     ]
  },
  {
     "source_url":"https://youtu.be/dWMYtUFKC8Q",
     "source_language":"English",
     "title":"",
     "subtitle":"Explained With Dom",
     "targets":[
        {
           "target_url":"https://youtu.be/p0giudMZD-E",
           "target_language":"Spanish"
        }
     ]
  },
  {
     "source_url":"https://www.youtube.com/watch?v=ozBsL4QB-jI",
     "source_language":"English",
     "title":"Karan Thapar",
     "subtitle":"ft. Shashi Tharoor",
     "targets":[
        {
           "target_url":"https://www.youtube.com/watch?v=JjCbVzzvsu8",
           "target_language":"Hinglish"
        }
     ]
  },
  {
     "source_url":"https://youtu.be/LzASheiqgII",
     "source_language":"German",
     "title":"xLaeta",
     "subtitle":"",
     "targets":[
        {
           "target_url":"https://youtu.be/9ddiwcFn7-0",
           "target_language":"English"
        }
     ]
  },
  {
     "source_url":"https://youtu.be/yi-0fUhMoX4",
     "source_language":"Spanish",
     "title":"TheWillyRex",
     "subtitle":"",
     "targets":[
        {
           "target_url":"https://youtu.be/xGBFK5UkaCc",
           "target_language":"English"
        }
     ]
  },
  {
     "source_url":"https://youtu.be/j-RW0YSrJDo",
     "source_language":"Italian",
     "title":"Poldo",
     "subtitle":"",
     "targets":[
        {
           "target_url":"https://youtu.be/gW_gyFa9dJQ",
           "target_language":"English"
        }
     ]
  },
  {
     "source_url":"https://youtu.be/ezuKzrT1K_A",
     "source_language":"Hindi",
     "title":"Gareeb Scientist",
     "subtitle":"",
     "targets":[
        {
           "target_url":"https://youtu.be/1m8cae1IGAs",
           "target_language":"English"
        }
     ]
  },
  {
     "source_url":"https://youtu.be/_r83X0KXObY",
     "source_language":"English",
     "title":"Elon Musk",
     "subtitle":"media interaction",
     "targets":[
        {
           "target_url":"https://www.youtube.com/watch?v=34BWRKvS5Ag",
           "target_language":"Hinglish"
        }
     ]
  },
  {
     "source_url":"https://www.youtube.com/watch?v=UTFSq3afBJI",
     "source_language":"Italian",
     "title":"Giorgia Meloni",
     "subtitle":"Monologue",
     "targets":[
        {
           "target_url":"https://www.youtube.com/watch?v=6_GHfhiUuHg",
           "target_language":"English"
        }
     ]
  },
  {
     "source_url":"https://www.youtube.com/watch?v=__H-DmRiA5A",
     "source_language":"English",
     "title":"Shekhar Gupta",
     "subtitle":"Cut the Clutter",
     "targets":[
        {
           "target_url":"https://www.youtube.com/watch?v=ziK8L0j1S24",
           "target_language":"Hinglish"
        }
     ]
  },
  {
     "source_url":"https://youtu.be/e1vZ_rJ9gFk",
     "source_language":"English",
     "title":"OBF",
     "subtitle":"",
     "targets":[
        {
           "target_url":"https://youtu.be/aVcCmuIYdF0",
           "target_language":"Spanish"
        }
     ]
  },
  {
     "source_url":"https://youtu.be/MdvG67xv1hQ",
     "source_language":"French",
     "title":"Cyrus North",
     "subtitle":"",
     "targets":[
        {
           "target_url":"https://youtu.be/5vna8okDTww",
           "target_language":"English"
        }
     ]
  },
  {
     "source_url":"https://youtu.be/Uz0R2CvrxNQ",
     "source_language":"English",
     "title":"Freethink",
     "subtitle":"",
     "targets":[
        {
           "target_url":"https://youtu.be/-rI1UlBvklM",
           "target_language":"Spanish"
        }
     ]
  },
  {
     "source_url":"https://www.youtube.com/watch?v=tN08SuhRHas",
     "source_language":"French",
     "title":"Emmanuel Macron",
     "subtitle":"Monologue",
     "targets":[
        {
           "target_url":"https://www.youtube.com/watch?v=k-NXb8pKrrU",
           "target_language":"English"
        }
     ]
  },
  {
     "source_url":"https://youtu.be/oOAbi-w4A84",
     "source_language":"English",
     "title":"B1M",
     "subtitle":"",
     "targets":[
        {
           "target_url":"https://youtu.be/lDoq_fwKql0",
           "target_language":"Spanish"
        }
     ]
  },
  {
     "source_url":"https://youtu.be/1ARMM7hLV0s",
     "source_language":"Spanish",
     "title":"FT",
     "subtitle":"",
     "targets":[
        {
           "target_url":"https://youtu.be/C8w56ELQ87g",
           "target_language":"English"
        }
     ]
  },
  {
     "source_url":"https://youtu.be/QT68mi_fxWY",
     "source_language":"German",
     "title":"Sallys Welt",
     "subtitle":"",
     "targets":[
        {
           "target_url":"https://youtu.be/OWgnwvnKxVs",
           "target_language":"English"
        }
     ]
  },
  {
     "source_url":"https://youtu.be/Z98I1myGFU4",
     "source_language":"German",
     "title":"Malwanne",
     "subtitle":"",
     "targets":[
        {
           "target_url":"https://youtu.be/4WpsTx9zoL4",
           "target_language":"English"
        }
     ]
  },
  {
     "source_url":"https://youtu.be/DY5gweeuBLc",
     "source_language":"English",
     "title":"Practical Engineering",
     "subtitle":"",
     "targets":[
        {
           "target_url":"https://youtu.be/FPgOOQfi7VI",
           "target_language":"Spanish"
        }
     ]
  },
  {
     "source_url":"https://youtu.be/5wvE7vTfSIg",
     "source_language":"Italian",
     "title":"AlmaPhysio",
     "subtitle":"",
     "targets":[
        {
           "target_url":"https://youtu.be/RB_NIrsUtzI",
           "target_language":"English"
        }
     ]
  },
  {
     "source_url":"https://youtu.be/zH-V5dIYzHs",
     "source_language":"Italian",
     "title":"MattleyIt",
     "subtitle":"",
     "targets":[
        {
           "target_url":"https://youtu.be/koinXbzKIH0",
           "target_language":"English"
        }
     ]
  },
  {
     "source_url":"https://youtu.be/mZ1DynXNtsY",
     "source_language":"Hindi",
     "title":"Nitish Rajput",
     "subtitle":"",
     "targets":[
        {
           "target_url":"https://youtu.be/SYSHpjFHdQI",
           "target_language":"English"
        }
     ]
  },
  {
     "source_url":"https://youtu.be/26kyJn8u3Q8",
     "source_language":"Hindi",
     "title":"Dhruv Rathee",
     "subtitle":"",
     "targets":[
        {
           "target_url":"https://youtu.be/OPlRIAs-r7Y",
           "target_language":"English"
        },
        {
           "target_url":"https://youtu.be/sBYdpPFCyA8",
           "target_language":"English"
        }
     ]
  },
  {
     "source_url":"https://youtu.be/_SG7l5rNHGE",
     "source_language":"English",
     "title":"QuantaMag",
     "subtitle":"",
     "targets":[
        {
           "target_url":"https://youtu.be/W4NkkOQr3rc",
           "target_language":"Spanish"
        }
     ]
  },
  {
     "source_url":"https://www.youtube.com/watch?v=vPf474FO0kU",
     "source_language":"English",
     "title":"MKBHD",
     "subtitle":"ft. Macbook Air 15\"",
     "targets":[
        {
           "target_url":"https://www.youtube.com/watch?v=IYFu_32S2g4",
           "target_language":"Hinglish"
        }
     ]
  },
  {
     "source_url":"https://youtu.be/4W5l6LX8QHY",
     "source_language":"English",
     "title":"Kamome",
     "subtitle":"",
     "targets":[
        {
           "target_url":"https://youtu.be/eztcBWoVnYo",
           "target_language":"Spanish"
        }
     ]
  },
  {
     "source_url":"https://youtu.be/YGff_jcOg7w",
     "source_language":"German",
     "title":"Paluten",
     "subtitle":"",
     "targets":[
        {
           "target_url":"https://youtu.be/95lJv-oJGdw",
           "target_language":"English"
        }
     ]
  },
  {
     "source_url":"https://youtu.be/bNl72Ap73k0",
     "source_language":"English",
     "title":"Warographics",
     "subtitle":"",
     "targets":[
        {
           "target_url":"https://youtu.be/V7sSReMad4I",
           "target_language":"Spanish"
        }
     ]
  },
  {
     "source_url":"https://youtu.be/ckLT8iYPwDY",
     "source_language":"English",
     "title":"Ranveer Allahbadia",
     "subtitle":"ft. Piyush Goyal",
     "targets":[
        {
           "target_url":"https://www.youtube.com/watch?v=pJM-vGyJ6v4",
           "target_language":"Hinglish"
        }
     ]
  },
  {
     "source_url":"https://youtu.be/n9HiL-hL-Kc",
     "source_language":"English",
     "title":"Patrick Bet-David",
     "subtitle":"PBD Podcast",
     "targets":[
        {
           "target_url":"https://www.youtube.com/watch?v=FCAGsFLCyr8",
           "target_language":"French"
        },
        {
           "target_url":"https://www.youtube.com/watch?v=kiXYIMg60Vc",
           "target_language":"Hinglish"
        },
        {
           "target_url":"https://www.youtube.com/watch?v=MpFtJYNr7H8",
           "target_language":"Spanish"
        }
     ]
  },
  {
     "source_url":"https://youtu.be/w4Uns6VFy8w",
     "source_language":"French",
     "title":"Le Fossoyeur de Films",
     "subtitle":"",
     "targets":[
        {
           "target_url":"https://www.youtube.com/watch?v=lluT0sa0bZY",
           "target_language":"English"
        }
     ]
  },
  {
     "source_url":"https://youtu.be/87c73R2PPCI",
     "source_language":"French",
     "title":"Fast Good Cuisine",
     "subtitle":"",
     "targets":[
        {
           "target_url":"https://youtu.be/JnpEWlbBes0",
           "target_language":"English"
        }
     ]
  },
  {
     "source_url":"https://www.youtube.com/shorts/lMZPILy3ePQ",
     "source_language":"English",
     "title":"Elon Musk",
     "subtitle":"on meaning of life",
     "thumbnail":elonImage,
     "targets":[
        {
           "target_url":"https://youtube.com/shorts/-qGZ95NMZYg",
           "target_language":"Hinglish",
           "thumbnail":elonImage
        }
     ]
  },
  {
     "source_url":"https://youtu.be/wx-6CEH-KH4",
     "source_language":"English",
     "title":"Futurology",
     "subtitle":"",
     "targets":[
        {
           "target_url":"https://youtu.be/cui02p0Cul0",
           "target_language":"Spanish"
        }
     ]
  }
]


const Showcase = () => {
  return (
    <div style={{ backgroundColor: "#080B16", padding: 10 }}>
      <div style={{display:"flex",flexDirection:"row"}}>
      <img
          src={speakmultiLogo}
          alt="flag"
          style={{ width: 50, height: 50, marginRight: 10,cursor:"pointer" }}
          onClick={() => window.open("https://speakmulti.com", "_blank")}
        />
      <Typography variant="h3" style={{ color: "white",fontFamily:"DM Sans" }}>
        Showcase
      </Typography>
      </div>
      {translations.map((data,index)=>{
        return (
        <div style={{marginBottom:50}}>
        <DataGroup data={data} key={index}/>
        </div>
        )
      })}
      <div style={{justifyContent:"center",display:"flex",flexDirection:"column"}}>
        <Typography style={{color:"white",alignSelf:"center",marginBottom:10}}>Want to expand your audience?</Typography>
      <Fab variant="extended" style={{backgroundColor:"white",width:"20%",alignSelf:"center",minWidth:200}} onClick={() =>{window.open(process.env.REACT_APP_AUTH_URL,"_blank")} }>
        <Typography style={{fontWeight:"bold",color:"#080B16"}}>Start Translating</Typography>
        <ArrowForwardIcon/>
      </Fab>
      </div>

      
    </div>
  );
};

export default Showcase;
