import "../styles/Modal.css";

import {
  Button,
  CircularProgress,
  FormHelperText,
  IconButton,
  LinearProgress,
  Menu,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";

import AudPlayer from "../components/AudPlayer";
import AudioGroup from "../components/AudioGroup";
import AudioModal from "../components/AudioModal";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Checkbox from "@mui/material/Checkbox";
import CloneModal from "../components/CloneModal";
import CloseIcon from "@mui/icons-material/Close";
import EmailIcon from "@mui/icons-material/Email";
import EmptyTextarea from "../components/EmptyTextArea";
import FileUpload from "../components/FileUpload";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import Header from "../components/Header";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import ReactModal from "react-modal";
import Select from "@mui/material/Select";
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import TelegramIcon from "@mui/icons-material/Telegram";
import TranscriptionModal from "../components/TranscriptionModal";
import TranslationModal from "../components/TranslationModal";
import TwitterIcon from "@mui/icons-material/Twitter";
import VideoPlayer from "../components/VideoPlayer";
import VideoTest from "./VideoTest";
import axios from "axios";
import { loadStripe } from "@stripe/stripe-js";
import telegramImage from "../assets/images/telegram_button.png";
import twitterImage from "../assets/images/twitter_button.png";
import RefreshIcon from '@mui/icons-material/Refresh';
import DownloadInitiator from "../components/DownloadInitiator";
import useApi from "../hooks/useApi";
import Snack from "../components/Snack";
import EditIcon from '@mui/icons-material/Edit';

const live_key =
  "pk_live_51NFZbOSGt1XEdvQuQvhSL6u8ot8pBnnNvg1biZSJE00BRIF2SWWz32ZKQfju3L7n1tOCdHMKYdkq8ShSB4LFrznI00MOMEEpJc";
const test_key =
  "pk_test_51NFZbOSGt1XEdvQuEpOMBE7pLJzFCZxZyhAsXhrw9O5BV3H3zvmUupLDl4xegpKwVncuAyqlGOxCe9UgtB2HDqeW000wmP02Nm";

// const stripePromise = loadStripe(
//   test_key
// );

const Landing = () => {
  const [url, setUrl] = useState("");
  const [targetLanguage, setTargetLanguage] = useState("");
  const [urlError, setUrlError] = useState(false);
  const [getInfoLoader, setGetInfoLoader] = useState(false);
  const [info, setInfo] = useState(null);
  const [jobs, setJobs] = useState(null);
  const [jobsLoading, setJobsLoading] = useState(true);
  const [ytApiError, setYtApiError] = useState("");
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const [checkoutApiError, setCheckoutApiError] = useState("");
  const [checkoutLoader, setCheckoutLoader] = useState(false);
  const [transactionSuccessful, setTransactionSuccessful] = useState(false);
  const [jobsError, setJobsError] = useState("");
  const jobRef = useRef(null);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [selectedVideoId, setSelectedVideoId] = useState(null);
  const [status, setStatus] = useState(null);
  // const [verify, setVerify] = useState(true);
  const [stripeKey, setStripeKey] = useState(null);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [sourceLanguage, setSourceLanguage] = useState("");
  const [numSpeakers, setNumSpeakers] = useState("");
  const [tabValue, setTabValue] = useState(0);
  const [files, setFiles] = useState([]); 
  const [bgFile, setBgFile] = useState([]);
  const [cloneModalIsOpen,setCloneModalIsOpen] = useState(false)
  const [recommendationModalIsOpen,setRecommendationModalIsOpen] = useState(false)
  const [errorSnackbarOpen,setErrorSnackbarOpen] = useState(false)
  const [selectedAsset,setSelectedAsset] = useState(null)
  const [openMenu,setOpenMenu] = useState(null)
  const [breakOnAudio,setBreakOnAudio] = useState(false)
  const [breakOnVideo,setBreakOnVideo] = useState(false)


  const [editingRowId, setEditingRowId] = useState(null);
  const [editedTitle, setEditedTitle] = useState("");
  const [originalTitle, setOriginalTitle] = useState("");


  const [bgSelectedRowId,setBgSelectedRowId] = useState(null)
  const [bgDumpingLoader,setBgDumpingLoader] = useState(false)



  const getAsset = useApi("https://apy.translatetracks.com/download_asset")
  const updateTitle = useApi("https://apy.translatetracks.com/update_title")
  // const updateTitle = useApi("http://localhost:5000/sleep")




  const handleDownloadClick = (video_id,asset_type) => {
    console.log(video_id,asset_type)
    setSelectedAsset(asset_type)
    getAsset.fetchData('GET',null,{session_id:video_id,asset_type:asset_type},localStorage.getItem('token'))
  }



  useEffect(() => {
    if(getAsset.data){
      console.log(getAsset.data)
      window.open(getAsset.data.data, '_blank')
      handleMenuClose()
    }
  },[getAsset.data])

  useEffect(() => {
    if(getAsset.error){
      setErrorSnackbarOpen(true)
      handleMenuClose()
    }
  },[getAsset.error])

  const onFileProgress = (file,progress) => {
    // console.log(error,file,progress)
    if(progress === 1){
      setGetInfoLoader(true)
    }
  }
  const onBgFileProgress = (file,progress) => {
    // console.log(error,file,progress)
    if(progress === 1){
      setBgDumpingLoader(true)
    }
  }

  const onRemoveFile = (error,file) => {
    setInfo(null)
    setFiles([])
  }

  const onRemoveBgFile = (error,file) => {
    setBgFile([])
  }

  const onProcessFile = (error,file) => {
    const response = JSON.parse(file.serverId)
    if(response.status === 0){
      setInfo(response)
      setUrl(response.video_url)
      setGetInfoLoader(false)
    }else{
      setYtApiError("Some error Occured!Please try again!");
    }
  }
  
  const onProcessBgFile = (error,file) => {
    const response = JSON.parse(file.serverId)
    if(response.status === 0){
      setBgDumpingLoader(false)
      setBgFile([])
      setBgSelectedRowId(null)
      updateJobById(response.video_id,'bg_link',response.bg_link)
    }else{
      // setYtApiError("Some error Occured!Please try again!");
      console.log("error")
    }
  }

  
  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
    setInfo(null)
    setUrl("")
    setFiles([])
  }

  const menuOpen = Boolean(anchorEl);
  const handleHelpClick = (elem) => (event) => {
    setAnchorEl(event.currentTarget);
    setOpenMenu(elem)
  };
  const handleMenuClose = () => {
    setAnchorEl(null);
    setOpenMenu(null)
  };

  // let stripePromise;

  // if (stripeKey) {
  //   console.log("loading stripe ");
  //   stripePromise = loadStripe(stripeKey);
  // }


  const closeModal = () => {
    setModalIsOpen(false);
  };

  const closeCloneModal = () => {
    setCloneModalIsOpen(false);
  };


  // const handleVerifyChange = (event) => {
  //   console.log(event.target.checked);
  //   setVerify(event.target.checked);
  // };

  const handleBreakOnAudioChange = (event) => {
    setBreakOnAudio(event.target.checked)
  }

  const handleBreakOnVideoChange = (event) => {
    setBreakOnVideo(event.target.checked)
  }



  const handleSourceLanguageChange = (event) => {
    // console.log(event.target.checked);
    // if (event.target.value !== "english") {
    //   setNumSpeakers("1");
    // }
    setSourceLanguage(event.target.value);
  };

  const handleNumSpeakersChange = (event) => {
    // console.log(event.target.checked);
    setNumSpeakers(event.target.value);
  };


  let menuItems =  [1, 2, 3, 4, 5].map(value => <MenuItem key={value} value={value.toString()}>{value}</MenuItem>);

// if (sourceLanguage === 'english' || sourceLanguage === "") {
//   menuItems = [1, 2, 3, 4, 5].map(value => <MenuItem key={value} value={value.toString()}>{value}</MenuItem>);
// } else {
//   menuItems = <MenuItem value={"1"}>1</MenuItem>;
// }
  const scrollToJobs = () =>
    jobRef.current.scrollIntoView({ behavior: "smooth" });

  const handleGetTranscriptClick = (video_id, status) => {
    setStatus(status);
    setModalIsOpen(true);
    setSelectedVideoId(video_id);
  };

  const handleEditTitleClick = (video_id,title) => {
    console.log(video_id);
    setEditingRowId(video_id);
    setEditedTitle(title);
    setOriginalTitle(title);
  }

  const handleTitleBlur = () => {
    if(editedTitle === "" || editedTitle === originalTitle){
      setEditingRowId(null)
      setEditedTitle("")
      return
    }
    updateTitle.fetchData('POST',{session_id:editingRowId,title:editedTitle},null,localStorage.getItem('token'))
  }

  useEffect(() => {
    if(updateTitle.data){
      updateJobById(editingRowId,'title',editedTitle)
      setEditingRowId(null)
      setEditedTitle("")
    }
  },[updateTitle.data])

  useEffect(() => {
    if(updateTitle.error){
      setErrorSnackbarOpen(true)
      setEditingRowId(null)
      setEditedTitle("")
    }
  },[updateTitle.error])



  const updateJobById = (video_id,key,value) => {
    const newJobs = [...jobs];
    const index = newJobs.findIndex((job) => job.video_id === video_id);
    newJobs[index][key] = value;
    setJobs(newJobs);
  }

  const getStatusView = (status, video_id,processing) => {
    // status = "translated_waiting"
    if (status === "tp_waiting" || status === "tr_waiting" || status === "ag_waiting") {
      return (
        <div>
          <Typography style={{ textAlign: "center", fontSize: 14 }}>
            {processing === true ? "Processing..." :
            status === "tp_waiting" ? "Transcribed":
              status === "tr_waiting"? "Translated":
              status === "ag_waiting" ? "Audio Generated":
              "None"}
          </Typography>
          <Button
            variant="contained"
            style={{ alignSelf: "center" }}
            color="primary"
            // onClick={() => handleGetTranscriptClick(video_id, status)}
            onClick={() => window.open('/editor?video_id='+video_id, '_blank')}
            disabled={processing}
          >
            Edit
          </Button>
        </div>
      );
    } else if (status === "tp_verified") {
      return "translating";
    } else if (status === "tr_verified") {
      return "generating audio";
    } else if(status === "ag_verified"){
      return "generating video"
    }
     else if (status === "VideoUnavailable") {
      return "The video is unavailable! Initiating Refund!";
    } else if (status === "AgeRestrictedError") {
      return "The video is age restricted! Initiating Refund!";
    } else if (status === "LiveStreamError") {
      return "The video is a live stream! Initiating Refund!";
    } else if (status === "VideoPrivate") {
      return "The video is private! Initiating Refund!";
    } else if (status === "RecordingUnavailable") {
      return "The video is a unavailable! Initiating Refund!";
    } else if (status === "MembersOnly") {
      return "The video is a members only video! Initiating Refund!";
    } else if (status === "VideoRegionBlocked") {
      return "The video is region blocked for our servers! Initiating Refund!";
    } else {
      return status;
    }
  };

  const getBackgroundView = (bg_link,bg_processing,download_link,video_id,download_bg_link) => {
    if(!download_link){
      return <Typography style={{ textAlign: "center", fontSize: 14 }}>Video not generated!</Typography>
    }else
    if(!bg_link || (bg_link && bgSelectedRowId === video_id)){
      if(bgSelectedRowId === video_id){
          return (<div>
          <FileUpload files={bgFile}
          setFiles={setBgFile}
          server={{
            url: `${process.env.REACT_APP_API_URL}/upload_bg`,
            headers: {
              'Authorization': `Bearer ${localStorage.getItem('token')}`,
              video_id:video_id
            }
          }}
          onRemoveFile={onRemoveBgFile}
          onProcessFile={onProcessBgFile}
          onFileProgress={onBgFileProgress}
          maxFiles={1}/>
          {bgDumpingLoader && <LinearProgress style={{ marginTop: 20 }} />}
          </div>)
      }else{

        return <Button
        variant="contained"
        style={{ alignSelf: "center" }}
        color="primary"
        onClick={() => setBgSelectedRowId(video_id)}
      >
        Add Background
      </Button>
      }

  }else if(bg_processing){
    return <Typography style={{ textAlign: "center", fontSize: 14 }}>Processing...</Typography>
  }else{
    //A button to Add Background
    return(
    <div style={{display:"flex",flexDirection:"column"}}>
      {bg_processing === false && download_bg_link &&  <Typography style={{ textAlign: "center", fontSize: 14 }}>
           Available for Download
      </Typography>}

    <Button
    variant="contained"
    style={{ alignSelf: "center" }}
    color="primary"
    // onClick={() => handleGetTranscriptClick(video_id, status)}
    onClick={() => window.open('/addBackground?video_id='+video_id, '_blank')}
    // disabled={processing}
  >
    Configure Bg
  </Button>
  <Button
    variant="contained"
    style={{ alignSelf: "center",marginTop:10 }}
    color="primary"
    onClick={() => setBgSelectedRowId(video_id)}
  >
    Change Bg
  </Button>

  </div>
  )

  }
}

  const getWarningView = () => {
    if (info && info.tooLong) {
      return (
        <Typography
          style={{
            textAlign: "center",
            fontSize: 12,
            marginTop: 5,
            color: "red",
          }}
        >
          Currently you can only process video less than 10 minutes long!
        </Typography>
      );
    } else if (info && !sourceLanguage) {
      return (
        <Typography style={{ textAlign: "center", fontSize: 12, marginTop: 5,color:"white" }}>
          Please select a source language.
        </Typography>
      );
    } 
    else if (info && !targetLanguage) {
      return (
        <Typography style={{ textAlign: "center", fontSize: 12, marginTop: 5,color:"white"  }}>
          Please select a target language.
        </Typography>
      );
    } else if(info && !numSpeakers){
      return (
        <Typography style={{ textAlign: "center", fontSize: 12, marginTop: 5,color:"white" 
      }}>
        Please select number of speakers!
        </Typography>
      );
    } 
    else
      return (
        <Typography style={{ textAlign: "center", fontSize: 12, marginTop: 5,fontWeight:"bold",color:"white"  }}>
          You will be redirected to a stripe link.As we are still in Beta,you can claim no questions
          aksed 100% refund if you are not satisfied with the result!
        </Typography>
      );
  };

  useEffect(() => {
    const handleStripeRedirect = async () => {
      if (searchParams.has("result")) {
        const result = searchParams.get("result");
        const isYoutube = searchParams.get("is_youtube");
        if(isYoutube === '1'){
          setTabValue(0);
        }else{
          setTabValue(1);
        }
        setInfo({
          title: searchParams.get("title"),
          duration: searchParams.get("duration"),
          thumbnail: searchParams.get("thumbnail"),
        });
        setTargetLanguage(searchParams.get("target_language"));
        setNumSpeakers(searchParams.get("num_speakers"));
        setSourceLanguage(searchParams.get("source_language"));
        setUrl(searchParams.get("url"));

        searchParams.delete("result");
        searchParams.delete("is_youtube");
        searchParams.delete("title");
        searchParams.delete("duration");
        searchParams.delete("thumbnail");
        searchParams.delete("target_language");
        searchParams.delete("url");
        searchParams.delete("num_speakers");
        searchParams.delete("source_language");
        setSearchParams(searchParams);
        if (result === "cancelled") {
        } else if (result === "success") {
          setCheckoutLoader(true);
          const session_id = searchParams.get("session_id");
          searchParams.delete("session_id");
          setSearchParams(searchParams);
          try {
            const response = await axios.post(
              `${process.env.REACT_APP_API_URL}/check_payment_status`,
              { checkout_session_id: session_id },
              {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("token"),
                },
              }
            );
            console.log(response);
            if (response.data.status === 0) {
              setTransactionSuccessful(true);
              setCheckoutLoader(false);
            } else {
              setCheckoutApiError(
                "We are working to verify your payment.We will email you once it is done!"
              );
              setCheckoutLoader(false);
            }
          } catch (err) {
            console.log(err);
            setCheckoutApiError(
              "Something went wrong. Please try again later."
            );
            setCheckoutLoader(false);
          }
        }
      }
    };
    handleStripeRedirect();
  }, []);

  useEffect(() => {
    const isUserTest = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/is_user_test`,
          {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("token"),
            },
          }
        );
        console.log(response.data);
        if (response.data.status === 0) {
          if (response.data.test === true) {
            // setStripePromise(loadStripe(test_key));
            setStripeKey(test_key);
          } else {
            console.log("setting live");
            setStripeKey(live_key);
          }
        } else if (response.data.status === -1) {
          localStorage.removeItem("token");
          localStorage.removeItem("email");
          navigate("/auth");
        } else {
          console.log("err");
          
        }
      } catch (err) {
        console.log(err);
        
      }
    };

    isUserTest();
  }, []);

  const handleUrlChange = (event) => {
    setUrl(event.target.value);
    const isValid = validateUrl(event.target.value);
    if (!isValid) {
      setUrlError(true);
    } else {
      setUrlError(false);
    }
  };

  const handleTranslateClick = async () => {
  let stripePromise;

  if (stripeKey) {
    console.log("loading stripe ");
    stripePromise = loadStripe(stripeKey);
  }
    console.log("sp ", stripePromise);
    const stripe = await stripePromise;
    setCheckoutLoader(true);
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/checkout`,
        {
          video_url: url,
          target_language: targetLanguage,
          duration: info.duration,
          title: info.title,
          thumbnail: info.thumbnail,
          num_speakers: numSpeakers,
          source_language: sourceLanguage,
          breakOnAudio:breakOnAudio,
          breakOnVideo:breakOnVideo
        },
        {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        }
      );
      console.log(response);
      if (response.data.status === 0) {
        const session_id = response.data.checkout_session_id;
        const result = await stripe.redirectToCheckout({
          sessionId: session_id,
        });
        console.log(result);
      } else {
        setCheckoutApiError("Something went wrong. Please try again later.");
        setCheckoutLoader(false);
      }
    } catch (err) {
      console.log(err);
      setCheckoutApiError("Something went wrong. Please try again later.");
      setCheckoutLoader(false);
    }
  };

  const validateUrl = (url) => {
    var re = /^(https?\:\/\/)?(www\.youtube\.com|youtu\.?be)\/.+$/;
    return re.test(url);
  };

  const handleLanguageChange = (event) => {
    setTargetLanguage(event.target.value);
  };

  const handleGetInfo = async () => {
    console.log(url);
    setInfo(null);
    setGetInfoLoader(true);
    setTargetLanguage("");
    setTransactionSuccessful(false);
    setYtApiError("");
    setNumSpeakers("");
    setSourceLanguage("");
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/get_video_info`,
        {
          url: url,
        },
        {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        }
      );
      console.log(response);
      if (response.data.status === 0) {
        setInfo(response.data.data);
        setGetInfoLoader(false);
      } else if (response.data.status === -1) {
        localStorage.removeItem("token");
        localStorage.removeItem("email");
        navigate("/auth");
      } else {
        setYtApiError("Some error Occured!Please try again!");
        setGetInfoLoader(false);
      }
    } catch (error) {
      console.log(error);
      setYtApiError("Some error Occured!Please try again!");
      setGetInfoLoader(false);
    }
  };

  const getJobs = async (show_loader = true) => {
    if (show_loader) {
      setJobsLoading(true);
    }
    // setJobsLoading(true);
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/get_videos`,
        {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        }
      );
      console.log(response.data);
      if (response.data.status === 0) {
        setJobs(response.data.data);
        setJobsLoading(false);
      } else if (response.data.status === -1) {
        localStorage.removeItem("token");
        localStorage.removeItem("email");
        navigate("/auth");
      } else {
        setJobsLoading(false);
        setJobsError("Something went wrong.Please try again later!");
      }
    } catch (err) {
      console.log(err);
      setJobsLoading(false);
      setJobsError("Something went wrong.Please try again later!");
    }
  };


  const getModal = () => {
    if(status === 'transcribed_waiting'){
      return <TranscriptionModal selectedVideoId={selectedVideoId} setModalIsOpen={setModalIsOpen}/>
    }else if(status === 'translated_waiting'){
      return <TranslationModal selectedVideoId={selectedVideoId} setModalIsOpen={setModalIsOpen}/>
    }else if(status === 'audio_generated_waiting'){
      return <AudioModal selectedVideoId={selectedVideoId} setModalIsOpen={setModalIsOpen}/>
    }

  }

  useEffect(() => {
    getJobs();
  }, []);

  // useEffect(() => {
  //   const intervalId = setInterval(() => {
  //     getJobs(false);
  //   }, 100000);
  //   return () => clearInterval(intervalId);
  // }, []);

  return (
    <div style={{backgroundColor:"#272727",minHeight:"100vh"}}>
      <Header scrollToJobs={scrollToJobs} openCloneModal={() => setCloneModalIsOpen(true)}/>
      
      <div style={{ display: "flex", justifyContent: "center", marginTop: 20 }}>
        <Card style={{ width: "50%", padding: 20, paddingBottom: 0,paddingTop:0 }}>
        <Tabs value={tabValue} onChange={handleTabChange} aria-label="basic tabs example">
          <Tab label="Youtube URL"/>
          <Tab label="Upload" />
      </Tabs>
      {tabValue === 0 ? (
          <CardContent>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
              }}
            >
              <TextField
                required
                id="outlined-required"
                label="Youtube URL"
                style={{ width: "100%" }}
                onChange={handleUrlChange}
                value={url}
                error={urlError}
                helperText={urlError && "Please enter a valid youtube url!"}
              />
              <Typography style={{ fontSize: 12 }}>
                For multiple speakers, overlapping speech may produce inaccurate results. 
              </Typography>

              {getInfoLoader ? (
                <LinearProgress style={{ marginTop: 20 }} />
              ) : (
                <Button
                  variant="contained"
                  style={{ marginTop: 20, width: "25%", alignSelf: "center" }}
                  disabled={urlError || !url}
                  onClick={handleGetInfo}
                >
                  Get Info!
                </Button>
              )}
            </div>
            {ytApiError && (
              <Typography
                style={{
                  color: "red",
                  fontSize: 14,
                  textAlign: "center",
                  marginTop: 5,
                }}
              >
                {ytApiError}
              </Typography>
            )}
          </CardContent>
      ):(<CardContent>
        <div style={{display:"flex",flexDirection: "column",
                justifyContent: "center"}}>
        <FileUpload files={files} 
                    setFiles={setFiles} 
                    server={{
                        url: `${process.env.REACT_APP_API_URL}/upload`,
                        headers: {
                          'Authorization': `Bearer ${localStorage.getItem('token')}`
                    }
                  }}
                   onFileProgress={onFileProgress} 
                   onProcessFile={onProcessFile}
                   onRemoveFile={onRemoveFile}
                   maxFiles={1}
                    />
                    {getInfoLoader && <LinearProgress style={{ marginTop: 20 }} />  }
                    {getInfoLoader && <Typography style={{ fontSize: 12 ,textAlign:"center"}}>Fetching video info...</Typography>  }

        
            </div>
        </CardContent>)}
        </Card>
      </div>
      {info && (
        <React.Fragment>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-around",
              marginTop: 40,
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                width: "200px",
              }}
            >
              <img src={info.thumbnail} width={200}/>
              <Typography style={{ textAlign: "center",color:"white" }}>
                {info.title + "(" + info.duration + ")"}
              </Typography>
            </div>
            <div
              style={{
                alignSelf: "center",
                width: "70%",
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <FormControl style={{ flex: 1, marginRight: 20 }}>
                <InputLabel id="demo-simple-select-disabled-label">
                  Source Language
                </InputLabel>
                <Select
                  labelId="demo-simple-select-disabled-label"
                  id="demo-simple-select-disabled"
                  value={sourceLanguage}
                  label="Source Language"
                  onChange={handleSourceLanguageChange}
                >
                  <MenuItem value={"english"}>English</MenuItem>
                  <MenuItem value={"hindi"}>Hindi</MenuItem>
                  <MenuItem value={"french"}>French</MenuItem>
                  <MenuItem value={"spanish"}>Spanish</MenuItem>
                  <MenuItem value={"german"}>German</MenuItem>
                  <MenuItem value={"italian"}>Italian</MenuItem>
                  <MenuItem value={"polish"}>Polish</MenuItem>
                </Select>
                <FormHelperText>Adding more soon!</FormHelperText>
              </FormControl>

              <FormControl style={{ flex: 1, marginRight: 20 }}>
                <InputLabel id="language">Target language</InputLabel>
                <Select
                  labelId="language"
                  id="demo-simple-select"
                  value={targetLanguage}
                  label="Age"
                  onChange={handleLanguageChange}
                >
                  <MenuItem value={"spanish"}>Spanish</MenuItem>
                  <MenuItem value={"polish"}>Polish</MenuItem>
                  <MenuItem value={"hindi"}>Hindi</MenuItem>
                  <MenuItem value={"hinglish"}>Hinglish</MenuItem>
                  <MenuItem value={"german"}>German</MenuItem>
                  <MenuItem value={"french"}>French</MenuItem>
                  <MenuItem value={"italian"}>Italian</MenuItem>
                  <MenuItem value={"english"}>English</MenuItem>
                </Select>
              </FormControl>
              <FormControl style={{ flex: 1, marginRight: 20 }}>
                <InputLabel id="num_speakers">Speakers</InputLabel>
                <Select
                  labelId="language"
                  id="demo-simple-select"
                  value={numSpeakers}
                  label="Speakers"
                  onChange={handleNumSpeakersChange}
                >
                
                   {menuItems}
                </Select>
                {/* {(sourceLanguage !== 'english' && sourceLanguage !== "") && <FormHelperText>Multiple speakers only supported for English!</FormHelperText>} */}
                { numSpeakers > 1 && <FormHelperText style={{fontWeight:"bold",color:"white"}}>For best results, the speeches should be non overlapping!</FormHelperText>}

              </FormControl>
              <Tooltip title="Break on Audio Breakpoints">
                <FormControlLabel
                  control={
                    <Checkbox checked={breakOnAudio} onChange={handleBreakOnAudioChange} />
                  }
                  label="Break on Audio"
                  style={{ alignSelf: "baseline",color:"white" }}
                />
              </Tooltip>
              <Tooltip title="Break on Video Breakpoints.">
                <FormControlLabel
                  control={
                    <Checkbox checked={breakOnVideo} onChange={handleBreakOnVideoChange} />
                  }
                  label="Break on Video"
                  style={{ alignSelf: "baseline",color:"white" }}
                />
              </Tooltip>
            </div>
          </div>

          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
            }}
          >
            {checkoutLoader ? (
              <LinearProgress style={{ width: "20%", alignSelf: "center" }} />
            ) : (
              <Button
                variant="contained"
                style={{ alignSelf: "center" }}
                disabled={!targetLanguage || info.tooLong || !numSpeakers || !sourceLanguage}
                onClick={transactionSuccessful ? null : handleTranslateClick}
                color={transactionSuccessful ? "success" : "primary"}
              >
                {transactionSuccessful ? "Translating!" : "Translate!"}
              </Button>
            )}
            {getWarningView()}
            {checkoutApiError && (
              <Typography
                style={{
                  color: "red",
                  fontSize: 14,
                  textAlign: "center",
                  marginTop: 5,
                }}
              >
                {checkoutApiError}
              </Typography>
            )}
          </div>
        </React.Fragment>
      )}
      <div style={{ margin: 20}} ref={jobRef} >
        <div style={{ margin:"auto",textAlign:"center" }}>
          <Typography variant="h5" style={{ color: "white",display:"inline"}}>
            Your Videos
          </Typography>
          <IconButton
            style={{ margin: "auto", display: "inline" }}
            onClick={() => getJobs()}
          >
            <RefreshIcon style={{ color: "white" }} fontSize="medium" />
          </IconButton>
        </div>
        {!jobs || jobsLoading ? (
          !jobsError ? (
            <div style={{ margin: "auto", width: "20%", marginTop: 40}}>
              <LinearProgress />
            </div>
          ) : (
            <Typography
              style={{
                color: "red",
                fontSize: 14,
                textAlign: "center",
                marginTop: 5,
              }}
            >
              {jobsError}
            </Typography>
          )
        ) : (
          <TableContainer component={Paper}>
            <Table aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>Request ID</TableCell>
                  <TableCell align="center">Title</TableCell>
                  <TableCell align="center">Duration</TableCell>
                  <TableCell align="center">Target Language</TableCell>
                  <TableCell align="center">Status</TableCell>
                  <TableCell align="center">Add Background</TableCell> 
                  <TableCell align="center">Download</TableCell>
                </TableRow>
              </TableHead>
              {jobs.length === 0 ? (
                <Typography style={{ textAlign: "center", marginTop: 20 }}>
                  You have not translated any videos yet!
                </Typography>
              ) : (
                <TableBody>
                  {jobs.map((row,i) => (
                    <TableRow key={row.name}>
                      <TableCell component="th" scope="row">
                        {row.request_id}
                      </TableCell>
                      <TableCell align="center">
                        <div style={{display:"flex",justifyContent:"center"}}>
                        {editingRowId === row.video_id ? 
                          <TextField
                          size="small"
                          value={editedTitle}
                          onChange={(e) => setEditedTitle(e.target.value)}
                          onBlur={handleTitleBlur}
                          autoFocus
                          style={{alignSelf:"center"}}
                        />:
                        <a href={row.video_url} target="_blank" style={{color:"white",alignSelf:"center"}}>
                          {row.title.length > 20 ? row.title.substring(0,20) + "...":row.title}
                        </a>
                          }
                          {updateTitle.loading && editingRowId === row.video_id && <CircularProgress size={20} style={{marginLeft:10,alignSelf:"center"}}/>}
                        {((!updateTitle.loading) || (updateTitle.loading && editingRowId !== row.video_id)) &&  <IconButton style={{alignSelf:"center"}} onClick={() => handleEditTitleClick(row.video_id,row.title)}>
                          <EditIcon style={{color:"white"}} fontSize="small"/>
                        </IconButton>}
                        </div>
                      </TableCell>
                      <TableCell align="center">{row.duration}</TableCell>
                      <TableCell align="center">
                        {row.target_language}
                      </TableCell>
                      <TableCell align="center">
                        {getStatusView(row.status, row.video_id,row.processing)}
                      </TableCell>
                      <TableCell align="center">
                        {getBackgroundView(row.bg_link,row.bg_processing,row.download_link,row.video_id,row.download_bg_link)}
                      </TableCell>
                      <TableCell align="center">
                        <Button
                          variant="contained"
                          color="success"
                          onClick={handleHelpClick(i)}
                        >
                          Download
                        </Button>
                        <Menu
                          id="basic-menu"
                          anchorEl={anchorEl}
                          // open={menuOpen}
                          open={openMenu === i}
                          onClose={handleMenuClose}
                          MenuListProps={{
                            "aria-labelledby": "basic-button",
                          }}
                        >
                          <MenuItem
                            onClick={() => handleDownloadClick(row.video_id,"video")}
                            disabled={row.download_link === null}
                          >
                              <DownloadInitiator text={"Translated Video"} loading={getAsset.loading && selectedAsset==='video'}/>
                          </MenuItem>
                          <MenuItem
                            onClick={() => handleDownloadClick(row.video_id,"audio")}
                            disabled={row.download_audio_link === null}
                          >
                             <DownloadInitiator text={"Translated Audio Track"}  loading={getAsset.loading && selectedAsset==='audio'}/>
                          </MenuItem>
                          <MenuItem
                            onClick={() => handleDownloadClick(row.video_id,"audio_bg")}
                            disabled={row.download_bg_audio_link === null}
                          >
                             <DownloadInitiator text={"Translated Audio Track with Bg"}  loading={getAsset.loading && selectedAsset==='audio'}/>
                          </MenuItem>
                          <MenuItem
                            onClick={() => handleDownloadClick(row.video_id,"video_bg")}
                            disabled={row.download_bg_link === null}
                          >
                             <DownloadInitiator text={"Translated Video Track with Bg"}  loading={getAsset.loading && selectedAsset==='audio'}/>
                          </MenuItem>
                          <MenuItem
                            onClick={() => handleDownloadClick(row.video_id,"transcription_subtitles")}
                          >
                              <DownloadInitiator text={"Original Subtitles"}  loading={getAsset.loading && selectedAsset==='transcription_subtitles'}/>
                          </MenuItem>
                          <MenuItem
                            onClick={() => handleDownloadClick(row.video_id,"translation_subtitles")}
                          >
                              <DownloadInitiator text={"Translated Subtitles"}  loading={getAsset.loading && selectedAsset==='translation_subtitles'}/>
                          </MenuItem>

                        </Menu>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              )}
            </Table>
          </TableContainer>
        )}
      </div>
      <ReactModal
        isOpen={modalIsOpen}
        // onAfterOpen={getTranscript}
        onRequestClose={closeModal}
        // style={customStyles}
        contentLabel="Example Modal"
        className="Modal"
        overlayClassName="Overlay"
        onAfterClose={getJobs}
      >
        <React.Fragment>
          <IconButton
            onClick={closeModal}
            style={{ position: "absolute", right: "5px", top: "5px" }}
          >
            <CloseIcon  style={{color:"white"}}/>
          </IconButton>
          {getModal()}
        </React.Fragment>
      </ReactModal>
      <ReactModal
        isOpen={cloneModalIsOpen}
        onRequestClose={closeCloneModal}
        contentLabel="Example Modal"
        className="CloneModal"
        overlayClassName="Overlay"
      >
        <CloneModal closeCloneModal={closeCloneModal}/>
      </ReactModal>
      <Snack open={errorSnackbarOpen} setOpen={setErrorSnackbarOpen} message="Something went wrong" severity="error"/>
      </div>
  );
};

export default Landing;
