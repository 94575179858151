import 'filepond/dist/filepond.min.css';

import { FilePond, registerPlugin } from 'react-filepond';

import FilePondPluginFileValidateSize from 'filepond-plugin-file-validate-size';

// Import FilePond styles




registerPlugin(FilePondPluginFileValidateSize);


const FileUpload = ({files,setFiles,server,onFileProgress,onProcessFile,onRemoveFile,maxFiles}) => {
    return(
        <FilePond
                files={files}
                onupdatefiles={setFiles}
                allowMultiple={true}
                maxFiles={maxFiles}
                name="files"
                maxFileSize={'500MB'}
                labelIdle='Drag & Drop your files or <span class="filepond--label-action">Browse</span>'
                server={server}
                onprocessfileprogress={onFileProgress}
                onprocessfile={onProcessFile}
                onremovefile={onRemoveFile}
                
            />
    )
}

export default FileUpload;