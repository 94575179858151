import { Checkbox, Typography } from "@mui/material"
import AudioPlayButton from "./AudioPlayButton"
import { useState } from "react";

const AudioList = ({ urls,selectedIndex,handleSegmentChange,segmentId,audioDurations,status }) => {

    // const [audioDuration, setAudioDuration] = useState(new Array(urls.length).fill(0));


    // const handleLoadedMetadata = (e,index) => {
    //     const audioDuration = e.target.duration;
    //     setAudioDuration((prev) => {
    //         const newDuration = [...prev];
    //         newDuration[index] = audioDuration;
    //         return newDuration;
    //     });

    // };

    return (
        <div style={{ display: status === "ag_waiting" ? "flex": "none", flexDirection: "column" }}>
            {urls.map((url, index) => (
                <div style={{ marginTop: 2,display:"flex",justifyContent:"center" }}>
                    <AudioPlayButton key={segmentId + String(index)} url={url}   index = {index}/>
                    <Typography style={{color: "#afb8c1",alignSelf:"center"}}>{audioDurations[index]}</Typography>
                    <Checkbox checked={index === selectedIndex} onChange={() => handleSegmentChange("audio_final_index",index)}/>
                </div>
            ))}
        </div>
    )
}

export default AudioList