import "../styles/RangeSlider.css"
import "react-range-slider-input/dist/style.css";
import React, { useEffect, useMemo, useState } from "react";
import { useLocation } from "react-router-dom";
import Player from "../components/Player";
import PlayerControls from "../components/PlayerControls";
import SvgTimeline from "../components/SvgTimeline";
import Timeline from "../components/Timeline";
import { speaker_colors, speaker_mapping } from "../helpers/dummy_data";
import SliderContainer from "../components/SliderContainer";
import { usePlayerControls } from "../hooks/usePlayerControls";
import { useTimlineLogic } from "../hooks/useTimelineLogic";
import { findChangingTime, findSegmentFromId, findSegmentIdFromCurrentTime, findSegmentIndexFromCurrentTime, formatSpeakerLegend, getNextAlphabet, getSegmentIndexFromId, get_audio_count, get_audio_final_duration, get_audio_segment_mismatch, get_button_text, get_mapping_index_from_mapping, get_new_fit_start_end, get_next_segmentId, get_next_segmentId_from_current_time, get_null_audio_segments, get_overlapping_segments, get_previous_segmentId_from_current_time, get_segment_duration, get_segments_with_0_negative_duration, get_segments_with_empty_transcriptions, get_segments_with_empty_translations, initialize_speaker_mapping, isIncreamentPossible, is_button_disabled, resolve_speaker_mapping, round_off_start_end, unformatSpeakerLegend } from "../helpers/functions";
import Console from "../components/Console";
import useApi from "../hooks/useApi";
import AudioPlayButton from "../components/AudioPlayButton";
import SpeakerList from "../components/SpeakerList";
import AudioList from "../components/AudioList";
import { v4 as uuidv4 } from 'uuid';
import axios from "axios";
import ReactModal from "react-modal";
import RecommendationModalNew from "../components/RecommendationModalNew";
import { Button, LinearProgress, Typography, colors } from "@mui/material";
import ContextModal from "../components/ContextModal";
import ActionButtonSet from "../components/ActionButtonSet";
import IssuesModal from "../components/IssuesModal";
import Snack from "../components/Snack";
import { useNavigate } from "react-router-dom";
import SpeakerMappingModal from "../components/SpeakerMappingModal";
import ReactPlayer from "react-player";
import ApprovalTextCheck from "../components/ApprovalTextCheck";




const Editor = () => {
  const leastCount = 60
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const [duration, setDuration] = React.useState(0)
  const video_id = query.get('video_id');
  // const url = 'https://speakmultiassets.s3.amazonaws.com/user-data/d757767e-095b-44e0-aa14-fa12892d3c16/8913068b-cf52-4965-8f2c-4bd0dfcb6766/video.mp4'
  const timelineWidth = 1200
  const totalWidth = duration > leastCount ? duration * (timelineWidth / leastCount) : timelineWidth;

  const sliderRef = React.useRef(null);
  const rangeSliderRef = React.useRef(null);
  // const bgAudioRef = React.useRef(null);
  const navigate = useNavigate()

  const [playing, setPlaying] = React.useState(false);
  const [isMute, setIsMute] = React.useState(false);
  const [isBuffering, setIsBuffering] = React.useState(false);
  const playerRef = React.useRef(null);
  const [status, setStatus] = React.useState(null)

  // const [isBgPlaying, setIsBgPlaying] = React.useState(false);
  // const [bgVolume, setBgVolume] = React.useState(0.5);

  const [originalSegments, setOriginalSegments] = React.useState(null)
  const [segments, setSegments] = React.useState(null)
  const [speakerLegend, setSpeakerLegend] = React.useState(null)

  const [isThumbDragging, setIsThumbDragging] = React.useState(false);
  const [isRangeDragging, setIsRangeDragging] = React.useState(false);
  const [addMode, setAddMode] = useState(false)
  const [splitMode, setSplitMode] = useState(false)


  const [currentSegmentId, setCurrentSegmentId] = React.useState(-1)
  const audioElements = React.useRef({})
  const { startTime, endTime, currentTime,breakpoints,
    selectedBreakpoint, timelineClicked, setEndTime, setCurrentTime,
     setBreakpoints, handleTimelineScroll, handleTimelineClick, handleBreakpointClick, 
     setTimelineClicked, timelineRef,scrollToSegmentId,scrollToNextPage,scrollToPreviousPage,
     setSelectedBreakpoint } = useTimlineLogic(leastCount, duration, timelineWidth, 
      totalWidth, sliderRef, playerRef, rangeSliderRef, segments, status);

  const [audioSrc, setAudioSrc] = React.useState(null);
  const [isRecommendationModalOpen, setIsRecommendationModalOpen] = React.useState(false);
  const [alterContext, setAlterContext] = React.useState(null);

  const [audioChecked, setAudioChecked] = React.useState(false);
  const [sliderValue, setSliderValue] = React.useState(0.4);

  const [isContextModalOpen, setIsContextModalOpen] = useState(false);
  const [context, setContext] = useState("");
  const [verificationInitiated, setVerificationInitiated] = useState(false);
  const [issues, setIssues] = useState([]);

  const [isIssuesModalOpen, setIsIssuesModalOpen] = useState(false);

  const [transcriptVisible, setTranscriptVisible] = useState(true);
  const [translationVisible, setTranslationVisible] = useState(true);

  const [errorSnackbarOpen, setErrorSnackbarOpen] = useState(false);
  const [successSnackbarOpen, setSuccessSnackbarOpen] = useState(false);

  const [isSpeakerModalOpen, setIsSpeakerModalOpen] = useState(false);
  const [speakerMapping, setSpeakerMapping] = useState([]);
  const [videoUrl,setVideoUrl] = useState(null)
  const [taskConfirmed,setTaskConfirmed] = useState(false)

  const [audioLoaded,setAudioLoaded] = useState(false)
  const [videoLoaded,setVideoLoaded] = useState(false)


  // const handleBgVolumeChange = (event, newValue) => {
  //   setBgVolume(newValue);
  // }

  const handleMappingChange = (e,speaker) => {
    const mappingIndex = get_mapping_index_from_mapping(speakerMapping,speaker)
    const new_mapping = [...speakerMapping]
    new_mapping[mappingIndex]['voice_name'] = e.target.value
    setSpeakerMapping(new_mapping)
  }

  const closeSpeakerModal = () => {
    setIsSpeakerModalOpen(false);
  }

  const openRecommendationModal = () => {
    setIsRecommendationModalOpen(true);
  }

  const closeRecommendationModal = () => {
    setAlterContext(null)
    setIsRecommendationModalOpen(false);
  }

  const closeContextModal = () => {
    setIsContextModalOpen(false);
  };

  const closeIssuesModal = () => {
    setIsIssuesModalOpen(false);
  }

  useEffect(() => {
    if (verificationInitiated) {
      closeContextModal();
      // verifyTranscript(segments, context,false,speakerSoundDict);
      handleSubmitClick('update',context)
      console.log(context)
    }
  }, [verificationInitiated]);




  const handleSegmentChange = (key, value) => {
    const newSegments = [...segments]
    const segmentIndex = newSegments.findIndex(segment => segment.id === currentSegmentId)
    newSegments[segmentIndex][key] = value
    setSegments(newSegments)
  }

  const alterSegments = (alter_obj) => {
    let new_segments = [...segments];
    const alter_index = new_segments.findIndex(segment => segment.id === currentSegmentId)
    new_segments[alter_index] = { ...segments[alter_index], ...alter_obj };
    console.log("setting ", new_segments);
    setSegments(new_segments);
  };

  const alterSegmentFromId = (segment_id, alter_obj) => {
    let new_segments = [...segments];
    const alter_index = getSegmentIndexFromId(segments, segment_id)
    new_segments[alter_index] = { ...segments[alter_index], ...alter_obj };
    console.log("setting ", new_segments);
    setSegments(new_segments);
  }

  const handleSpeakerChange = (event, segment_id) => {
    alterSegmentFromId(segment_id, { speaker: event.target.value });
  };


  const retranscribe = useApi('https://apy.translatetracks.com/get_transcript_recommendations')
  const magicSplit = useApi('https://apy.translatetracks.com/split_segment')
  const regenerateAudio = useApi('https://apy.translatetracks.com/regenerate_audio')
  const refitAudio = useApi('https://apy.translatetracks.com/refit_audio')
  const predictTime = useApi('https://apy.translatetracks.com/predict_duration')
  const transcriptTranslation = useApi('https://apy.translatetracks.com/get_accurate_translation')
  const toReferenceTranslation = useApi('https://apy.translatetracks.com/get_accurate_translation')
  const fromReferenceTranslation =  useApi('https://apy.translatetracks.com/get_translation_from_session')
  const videoDetails = useApi(`${process.env.REACT_APP_API_URL}/get_video_details`)
  const submit = useApi('https://apy.translatetracks.com/submit')

  const handleSubmitClick = (task,context=null) => {
    // let speaker_legend = [...speakerLegend]
    let speaker_legend = JSON.parse(JSON.stringify(speakerLegend))
    speaker_legend = unformatSpeakerLegend(speaker_legend)
    submit.fetchData('POST', {segments,task,video_id,speaker_legend,status,context,speakerMapping}, null,localStorage.getItem('token'))
  }

  useEffect(() => {
    if(submit.data){
        setSuccessSnackbarOpen(true)
        submit.data.message === 'updated' && navigate("/")
    }
  }, [submit.data])




  const handleRetranscribeClick = (start_time, end_time, segment_id) => {
    retranscribe.fetchData('GET', null, {
      session_id: video_id,
      start_time: start_time,
      end_time: end_time,
      segment_id: segment_id
    },localStorage.getItem('token'))
  }

  useEffect(() => {
    if (retranscribe.data) {
      const segment = findSegmentFromId(segments, retranscribe.data.segment_id)
      alterSegmentFromId(retranscribe.data.segment_id, { text: segment.text + "\n" + retranscribe.data.text })
    }
  }, [retranscribe.data])


  const handleMagicSplit = () => {
    magicSplit.fetchData('GET', null, { session_id: video_id, ...selectedBreakpoint },localStorage.getItem('token'))
  }

  useEffect(() => {
    if (magicSplit.data) {
      const new_segments = [...segments]
      const segmentId = magicSplit.data.data[0].id
      const segmentIndex = getSegmentIndexFromId(segments,segmentId)
      new_segments.splice(segmentIndex, 1, ...magicSplit.data.data)
      setSegments(new_segments)
      setCurrentSegmentId(segmentId)
      setSelectedBreakpoint(null)
    }
  }, [magicSplit.data])


  const handleRegenerateClick = (segment_id, num_regenerations) => {
    regenerateAudio.fetchData('POST', {
      session_id: video_id,
      num_regenerations: num_regenerations,
      speaker_mapping:speakerMapping,
      segments:segments,
      segment_id: segment_id,

    }, null,localStorage.getItem('token'))

  }


  useEffect(() => {
    if (regenerateAudio.data) {
      const audio_generations = regenerateAudio.data.data.segment.audio_generations.map(audio_generation => {
        const byteCharacters = atob(audio_generation);
        const byteNumbers = Array.from(byteCharacters).map(char => char.charCodeAt(0));
        const byteArray = new Uint8Array(byteNumbers);
        const blob = new Blob([byteArray], { type: 'audio/mpeg' });
        return window.URL.createObjectURL(blob);
      });

      const audio_final = regenerateAudio.data.data.segment.audio_final.map(audio => {
        const byteCharacters = atob(audio);
        const byteNumbers = Array.from(byteCharacters).map(char => char.charCodeAt(0));
        const byteArray = new Uint8Array(byteNumbers);
        const blob = new Blob([byteArray], { type: 'audio/mpeg' });
        return window.URL.createObjectURL(blob);
      });

      console.log("setting segment ",regenerateAudio.data.data.segment)
      const segmentId = regenerateAudio.data.data.segment.id
      alterSegmentFromId(segmentId,{...regenerateAudio.data.data.segment,audio_generations: audio_generations,audio_final: audio_final})
      const segment = findSegmentFromId(segments, segmentId)
      segment.audio_final_index !== null && pauseCurrentAudio(segmentId)
      audioElements.current[segmentId] = new Audio(audio_final[0])
      playerRef.current.seekTo(segment.start, "seconds")
      // bgAudioRef.current.seekTo(segment.start, "seconds")
      seekAudioAndPlay(0, segmentId)

    }
  }, [regenerateAudio.data])

  const handleRefitClick = (configuration, segment_id) => {
    refitAudio.fetchData('POST', {
      'configuration':configuration,
      'session_id':video_id,
      'segments':segments,
      'video_duration':duration,
      'segment_id':segment_id
    }, null,localStorage.getItem('token'))


  }

  useEffect(() => {
    if (refitAudio.data) {
      const audio_generations = refitAudio.data.data.segment.audio_generations.map(audio_generation => {
        const byteCharacters = atob(audio_generation);
        const byteNumbers = Array.from(byteCharacters).map(char => char.charCodeAt(0));
        const byteArray = new Uint8Array(byteNumbers);
        const blob = new Blob([byteArray], { type: 'audio/mpeg' });
        return window.URL.createObjectURL(blob);
      });

      const audio_final = refitAudio.data.data.segment.audio_final.map(audio => {
        const byteCharacters = atob(audio);
        const byteNumbers = Array.from(byteCharacters).map(char => char.charCodeAt(0));
        const byteArray = new Uint8Array(byteNumbers);
        const blob = new Blob([byteArray], { type: 'audio/mpeg' });
        return window.URL.createObjectURL(blob);
      });

      const segmentId = refitAudio.data.data.segment.id
      alterSegmentFromId(segmentId,{...refitAudio.data.data.segment,audio_generations: audio_generations,audio_final: audio_final})
      pauseCurrentAudio(segmentId)
      audioElements.current[segmentId] = new Audio(audio_final[0])
      console.log("seeking to ", refitAudio.data.data.segment.start)
      playerRef.current.seekTo(refitAudio.data.data.segment.start, "seconds")
      // bgAudioRef.current.seekTo(refitAudio.data.data.segment.start, "seconds")
      seekAudioAndPlay(0, segmentId)

    }
  }, [refitAudio.data])


  const handlePredictTimeClick = (text, speaker) => {
    predictTime.fetchData('GET', null, { text: text, speaker: speaker, session_id: video_id },localStorage.getItem('token'))
  }

  useEffect(() => {
    if (predictTime.data) {
      alterSegmentFromId(currentSegmentId, { duration:  Math.round(predictTime.data.data/10)/100 })
    }
  }, [predictTime.data])


  const handleTrimClick = (context) => {
    openRecommendationModal()
    setAlterContext(context)
  }


  const handleTrancriptTranslationClick = (text) => {
    transcriptTranslation.fetchData('GET',null,{text},localStorage.getItem('token'))
  }

  useEffect(() => {
    if (transcriptTranslation.data) {
      alterSegmentFromId(currentSegmentId,{text_trans:transcriptTranslation.data.data})
    }
  }, [transcriptTranslation.data])


  const handleToReferenceTranslationClick = (text) => {
    toReferenceTranslation.fetchData('GET',null,{text},localStorage.getItem('token'))
  }

  useEffect(() => {
    if (toReferenceTranslation.data) {
      alterSegmentFromId(currentSegmentId,{reference_translation:toReferenceTranslation.data.data})
    }
  }, [toReferenceTranslation.data])

  const handleFromReferenceTranslationClick = (text,original_translation,translation) => {
    console.log(text,translation)
    fromReferenceTranslation.fetchData('GET',null,{text:text,session_id:video_id,context:original_translation,translation:translation},localStorage.getItem('token'))
  }

  useEffect(() => {
    if (fromReferenceTranslation.data) {
      alterSegmentFromId(currentSegmentId,{translation:fromReferenceTranslation.data.data})
    }
  }, [fromReferenceTranslation.data])


  //useEffect for all api call errors
  useEffect(() => {
    if(submit.error || transcriptTranslation.error || toReferenceTranslation.error || fromReferenceTranslation.error || refitAudio.error || regenerateAudio.error || magicSplit.error || retranscribe.error || predictTime.error || videoDetails.error){
      console.log("Opeining")
      setErrorSnackbarOpen(true)
    }
  }, [submit.error,transcriptTranslation.error,toReferenceTranslation.error,fromReferenceTranslation.error,refitAudio.error,regenerateAudio.error,magicSplit.error,retranscribe.error,predictTime.error,videoDetails.error])



  const seekAudioAndPlay = (time, segmentId) => {
    console.log("playing...", segmentId)
    audioElements.current[segmentId].currentTime = time
    audioElements.current[segmentId].onseeked = () => {
      console.log("seeked", audioElements.current[segmentId].currentTime);
    };
    console.log(audioElements.current[segmentId].currentTime)
    const promise = audioElements.current[segmentId].play()
    console.log("promise", promise)
    if (promise !== undefined) {
      console.log(segmentId, "promise resolved")
      promise.then(_ => {
        console.log("playing")
        setPlaying(true)
        // setIsBgPlaying(true)
        setIsBuffering(false)
      }).catch(error => {
        console.log("error", error)
        setIsBuffering(false)
      })
    }
  }



  const pauseCurrentAudio = (segmentId) => {
    // console.log("pausing", audioElements.current[segmentId])
    // console.log("paused", audioElements.current[segmentId].paused)
    const currentSegment = findSegmentFromId(segments, segmentId)
    if(currentSegment.audio_final_index !== null && status === 'ag_waiting'){
    audioElements.current[segmentId].pause()
    audioElements.current[segmentId].currentTime = 0
    }
    // console.log("paused", audioElements.current[segmentId].paused)
  }


  const togglePlaying = () => {
    console.log(playing,currentSegmentId)
    if (status !== 'ag_waiting') {
      setPlaying(!playing)
      // setIsBgPlaying(!isBgPlaying)
      return
    }

    const currentSegment = findSegmentFromId(segments, currentSegmentId)

    if (currentSegment.audio_final_index === null) {
      setPlaying(!playing)
      // setIsBgPlaying(!isBgPlaying)
      return
    }
    if (playing && currentSegmentId !== -1) {
      console.log("pauding....")
      setPlaying(false)
      // setIsBgPlaying(false)
      audioElements.current[currentSegmentId].pause()
    } else if (!playing && currentSegmentId !== -1) {
      const currentSegment = findSegmentFromId(segments, currentSegmentId)
      setIsBuffering(true)
      console.log("playing ",currentTime,currentTime - currentSegment.start,currentSegmentId)
      seekAudioAndPlay(currentTime - currentSegment.start, currentSegmentId)
    } else if (currentSegmentId === -1 && !playing) {
      setPlaying(true)
      // setIsBgPlaying(true)
      setIsBuffering(false)
    } else if (currentSegmentId === -1 && playing) {
      setPlaying(false)
      // setIsBgPlaying(false)
      setIsBuffering(false)
    }
  }

  const toggleMute = () => {
    setIsMute(prevMute => !prevMute);
  }

  const onBuffer = () => {
    setIsBuffering(true);
  }

  const onBufferEnd = () => {
    setIsBuffering(false);
  }



  const handleSegmentClick = (segmentId) => {
    console.log("clicked...")
    setIsRangeDragging(true);
    const currentSegment = findSegmentFromId(segments, currentSegmentId)
    const segment = findSegmentFromId(segments, segmentId)
    status === 'ag_waiting' && currentSegmentId !== -1 && currentSegment.audio_final_index !== null && pauseCurrentAudio(currentSegmentId)
    console.log("Seeking to ",segment.start)
    playerRef.current.seekTo(segment.start, "seconds");
    // bgAudioRef.current.seekTo(segment.start, "seconds");
    segment.audio_final_index !== null && status === 'ag_waiting' && playing && seekAudioAndPlay(0, segmentId)
    setCurrentSegmentId(segmentId);

  };





  useEffect(() => {
    videoDetails.fetchData('GET', null, { request_id: video_id },localStorage.getItem('token'))
  }, [])

  useEffect(() => {
    if (videoDetails.data) {
      console.log(videoDetails.data.data)
      const rounded_off_segments = round_off_start_end(videoDetails.data.data.segments)
      setVideoUrl(videoDetails.data.data.video_url)
      setOriginalSegments(rounded_off_segments)
      setSegments(rounded_off_segments)
      setSpeakerLegend(formatSpeakerLegend(videoDetails.data.data.speaker_legend, speaker_colors))
      setStatus(videoDetails.data.data.status)
      status === 'ag_waiting' && setIsMute(true)
      setBreakpoints(videoDetails.data.data.breakpoints)
      !videoDetails.data.data.speaker_mapping ? setSpeakerMapping(initialize_speaker_mapping(videoDetails.data.data.speaker_legend)): setSpeakerMapping(resolve_speaker_mapping(videoDetails.data.data.speaker_mapping,videoDetails.data.data.speaker_legend))
      videoDetails.data.data.status == 'tp_waiting' && setTranslationVisible(false)
      status !== 'ag_waiting' && setAudioLoaded(true)
    }
  },[videoDetails.data])


  useMemo(() => {
    if (originalSegments === null || status !== 'ag_waiting') {
      console.log("returning memo")
      return
    }


    // setIsBuffering(true)
    let loadedAudioCount = 0
    const audioCount = get_audio_count(originalSegments)

    const handleAudioLoaded = (e) => {
      loadedAudioCount += 1
      if (loadedAudioCount === audioCount) {
        console.log("all loaded")
        // setIsBuffering(false)
        setAudioLoaded(true)
      }
    }

    originalSegments.forEach((segment, index) => {
      const selectedIndex = segment['audio_final_index']
      audioElements.current[segment.id] = new Audio(segment["audio_final"][selectedIndex])
      audioElements.current[segment.id].addEventListener("canplaythrough", handleAudioLoaded)
      audioElements.current[segment.id].addEventListener('error', (e) => {
        console.error("Audio error", e);
        console.log("error code ", e.target.error.code)
        console.log(segment)
      });
    })
  }, [originalSegments])


  useEffect(() => {
    if (segments === null || currentSegmentId === -1 || status !== 'ag_waiting' || findSegmentFromId(segments, currentSegmentId)['audio_final_index'] === null) {
      return
    }
    console.log("effect")
    setIsBuffering(true)
    const handleAudioLoaded = () => {
      console.log("loaded")
      setIsBuffering(false)
    }

    // pauseAllAudio()
    pauseCurrentAudio(currentSegmentId)
    const currentSegment = findSegmentFromId(segments, currentSegmentId)
    audioElements.current[currentSegmentId] = new Audio(currentSegment["audio_final"][currentSegment['audio_final_index']])
    audioElements.current[currentSegmentId].addEventListener("canplaythrough", handleAudioLoaded)
    audioElements.current[currentSegmentId].addEventListener('error', (e) => {
      console.error("Audio error", e);
      console.log("error code ", e.target.error.code)
    });
    playerRef.current.seekTo(currentSegment.start, "seconds");
    // bgAudioRef.current.seekTo(currentSegment.start, "seconds");
    seekAudioAndPlay(0, currentSegmentId)
  }, [JSON.stringify(segments ? segments.map(segment => segment['audio_final_index']) : [])])

  const handleVideoProgress = ({ playedSeconds }) => {
    console.log("progressing to ",playedSeconds)
    setCurrentTime(Math.round(playedSeconds * 100)/100);

    if (isThumbDragging || isRangeDragging) {
      console.log("returning...")
      return
    }

    let id = findSegmentIdFromCurrentTime(segments, playedSeconds);
    const segment = findSegmentFromId(segments, id)


    // if status is not audio_generated_waiting then play original audio
    if (status !== 'ag_waiting') {
      if (id !== currentSegmentId) {
        setCurrentSegmentId(id)
        return
      }
    }

    if (segment.audio_final_index === null) {
      setCurrentSegmentId(id)
      return
    }


    //when timeline is clicked in paused state
    if (!playing && timelineClicked) {
      setCurrentSegmentId(id)
      return
    }

    //when the playhead reaches a new segment(from -1 or from other segment(seeked from timeline click))
    //playedSeconds > 0 so that it does not start to play initially without video playing  
    if (id !== currentSegmentId && playedSeconds > 0 && id !== -1) {
      console.log("here")
      setPlaying(false)
      setIsBuffering(true)
      currentSegmentId !== -1 && pauseCurrentAudio(currentSegmentId)
      seekAudioAndPlay(playedSeconds - segment.start, id)
      setCurrentSegmentId(id)
      setTimelineClicked(false)
    }
    //when the playhead leaves a segment 
    else if (id !== currentSegmentId && id === -1 && playedSeconds > 0) {
      const currentSegment = findSegmentFromId(segments, currentSegmentId)
      currentSegment.audio_final_index !== null && pauseCurrentAudio(currentSegmentId)
      setCurrentSegmentId(-1)
      setTimelineClicked(false)
    }
    //when the playhead is seeked to the same segment by timeline click
    else if (id === currentSegmentId && timelineClicked && id !== -1) {
      const currentSegment = findSegmentFromId(segments, currentSegmentId)
      console.log("currentSegment", currentSegment)
      seekAudioAndPlay(playedSeconds - currentSegment.start, id)
      setTimelineClicked(false)
    } else if (id === -1 && timelineClicked) {
      setTimelineClicked(false)
    }
  }



  const onDuration = (duration) => {
    setDuration(duration);
    if (duration < leastCount) {
      setEndTime(duration);
    }
  }

  const onSlide = (new_range, index) => {
    const currentSegment = findSegmentFromId(segments, currentSegmentId)
    if (isIncreamentPossible(segments, new_range, index)) {
      const old_range = [
        currentSegment.start,
        currentSegment.end,
      ];
      const changingTime = findChangingTime(old_range, new_range);
      playerRef.current.seekTo(
        changingTime,
        "seconds"
      );
      // bgAudioRef.current.seekTo(
      //   changingTime,
      //   "seconds"
      // );

      alterSegments({ start: new_range[0], end: new_range[1] });
    } else {
      console.log(currentSegment, new_range, "Not possible!");
    }
  }

  const handleSpeakerAdd = () => {
    const newSpeaker = getNextAlphabet(speakerLegend[speakerLegend.length - 1]['speaker'])
    setSpeakerLegend(speakerLegend => [...speakerLegend, { speaker: newSpeaker, audio: "", color: speaker_colors[speakerLegend.length] }])
    setSpeakerMapping(speakerMapping => [...speakerMapping, { speaker: newSpeaker, voice_name: "default" }])
  }


  const onThumbDragStart = (segmentId) => {
    playing && setPlaying(false)
    // playing && setIsBgPlaying(false)
    const currentSegment = findSegmentFromId(segments, currentSegmentId)
    currentSegment.audio_final_index !== null && pauseCurrentAudio(currentSegmentId)
    setIsThumbDragging(true);
    // setSegmentIndex(index);
    setCurrentSegmentId(segmentId)
  };

  const onThumbDragEnd = () => {
    setIsThumbDragging(false);
  };

  const onRangeDragEnd = () => {
    setIsRangeDragging(false);
  };

  const insertSegment = (startTime) => {
    let new_segments = [...segments];
    let index = 0;
    for (let i = 0; i < new_segments.length; i++) {
      if (new_segments[i].start < startTime) {
        index = i + 1;
      }
    }

    const new_segment = {
      start: startTime,
      end: startTime + 1,
      text: "",
      speaker: "A",
      text_trans: "",
      translation: "",
      url: "",
      id: uuidv4().slice(0, 7),
      audio_final: [],
      audio_final_durations: [],
      audio_final_index: null,
      audio_generations: [],
      audio_generations_durations: [],
      original_translation: "",
      reference_translation: "",
      original_translation_reference: "",
      target_duration: null,
      original_text_duration: null,
      duration: null,
      mapped: null
    }

    new_segments.splice(index, 0, new_segment);

    setSegments(new_segments);
  };

  const handleInsertSegmentClick = (e) => {
    const secondsPerPixel = duration / rangeSliderRef.current.offsetWidth;
    const clickTime =
      (e.clientX - rangeSliderRef.current.getBoundingClientRect().left) *
      secondsPerPixel;
    insertSegment(clickTime);
    console.log('clickTime', clickTime)
    playerRef.current.seekTo(clickTime + 0.5, "seconds");
    // bgAudioRef.current.seekTo(clickTime + 0.5, "seconds");
    setAddMode(false);

  }

  const handleSplitClick = (e) => {
    const secondsPerPixel = duration / rangeSliderRef.current.offsetWidth;
    const clickTime =
      (e.clientX - rangeSliderRef.current.getBoundingClientRect().left) *
      secondsPerPixel;
    const index = findSegmentIndexFromCurrentTime(segments, clickTime);
    console.log("index ", index);
    const defaultSegment = {
      translation: "",
      url: "",
      audio_final: [],
      audio_final_durations: [],
      audio_final_index: null,
      audio_generations: [],
      audio_generations_durations: [],
      original_translation: "",
      reference_translation: "",
      original_translation_reference: "",
      target_duration: null,
      original_text_duration: null,
      duration: null,
      mapped: null
    }
    const first_new_segment = { ...segments[index], end: clickTime, ...defaultSegment };
    const second_new_segment = { ...segments[index], start: clickTime + 0.1, ...defaultSegment, id: uuidv4().slice(0, 7) };
    let new_segments = [...segments];
    new_segments.splice(index, 1, first_new_segment, second_new_segment);
    console.log("new_segs ", new_segments)
    setSegments(new_segments);
    setSplitMode(false);
  };

  const deleteSegment = () => {
    let new_segments = [...segments];
    const segmentIndex = getSegmentIndexFromId(segments, currentSegmentId)
    new_segments.splice(segmentIndex, 1);
    setSegments(new_segments);
    setCurrentSegmentId(-1)
  };

  const mergeSegments = () => {
    const mergeIndex = getSegmentIndexFromId(segments, currentSegmentId)
    const new_segments = [...segments]
    const defaultSegment = {
      translation: "",
      url: "",
      audio_final: [],
      audio_final_durations: [],
      audio_final_index: null,
      audio_generations: [],
      audio_generations_durations: [],
      original_translation: "",
      reference_translation: "",
      original_translation_reference: "",
      target_duration: null,
      original_text_duration: null,
      duration: null,
      mapped: null
    }
    new_segments[mergeIndex] = {
      ...new_segments[mergeIndex], end: new_segments[mergeIndex + 1].end,
      text: new_segments[mergeIndex].text + " " + new_segments[mergeIndex + 1].text,
      text_trans: new_segments[mergeIndex].text_trans + " " + new_segments[mergeIndex + 1].text_trans, ...defaultSegment
    }
    new_segments.splice(mergeIndex + 1, 1)
    setSegments(new_segments)
  }


  useEffect(() => {
    if(segments){
      if(status === 'tp_waiting'){
        const empty = get_segments_with_empty_transcriptions(segments)
        const overlapping = get_overlapping_segments(segments)
        const zero_duration = get_segments_with_0_negative_duration(segments)
        setIssues(empty.concat(overlapping).concat(zero_duration))
      }else if(status === 'tr_waiting'){
        const empty = get_segments_with_empty_translations(segments)
        const overlapping = get_overlapping_segments(segments)
        const zero_duration = get_segments_with_0_negative_duration(segments)
        setIssues(empty.concat(overlapping).concat(zero_duration))
      }else if(status === 'ag_waiting'){
        const overlapping = get_overlapping_segments(segments)
        const empty = get_segments_with_empty_translations(segments)
        const mismatch = get_audio_segment_mismatch(segments)
        const null_audio = get_null_audio_segments(segments)
        const zero_duration = get_segments_with_0_negative_duration(segments)
        setIssues(overlapping.concat(empty).concat(mismatch).concat(null_audio).concat(zero_duration))

      }
    }
  },[segments])


  const handleKeyPress = (event) => {
    if(event.target.tagName === 'TEXTAREA'){
      return
    }
    event.preventDefault()
    if(event.key === ' ' || event.key === 'Spacebar'){
      console.log("pressed...",currentSegmentId)
      togglePlaying()
    }else if(event.key === 'ArrowRight'){
      const nextSegmentId = get_next_segmentId_from_current_time(segments,currentTime)
      console.log("nextSegmentId ",nextSegmentId)
      if(nextSegmentId === -1){
        return
      }
      const currentSegment = findSegmentFromId(segments, currentSegmentId)
      const nextSegment = findSegmentFromId(segments, nextSegmentId)
      status === 'ag_waiting' && currentSegmentId !== -1 && currentSegment.audio_final_index !== null && pauseCurrentAudio(currentSegmentId)
      playerRef.current.seekTo(nextSegment.start, "seconds");
      // bgAudioRef.current.seekTo(nextSegment.start, "seconds");
      nextSegment.audio_final_index !== null && status === 'ag_waiting' && playing && seekAudioAndPlay(0, nextSegmentId)
      setCurrentSegmentId(nextSegmentId)
      currentSegment.end > endTime && scrollToNextPage()

    }else if(event.key === 'ArrowLeft'){
      const previousSegmentId = get_previous_segmentId_from_current_time(segments,currentTime)
      console.log("previousSegmentId ",previousSegmentId)
      if(previousSegmentId === -1){
        return
      }
      const currentSegment = findSegmentFromId(segments, currentSegmentId)
      const previosSegment = findSegmentFromId(segments, previousSegmentId)
      status === 'ag_waiting' && currentSegmentId !== -1 && currentSegment.audio_final_index !== null && pauseCurrentAudio(currentSegmentId)
      playerRef.current.seekTo(previosSegment.start, "seconds");
      // bgAudioRef.current.seekTo(previosSegment.start, "seconds");
      previosSegment.audio_final_index !== null && status === 'ag_waiting' && playing && seekAudioAndPlay(0, previousSegmentId)
      setCurrentSegmentId(previousSegmentId)
      previosSegment.start < startTime && scrollToPreviousPage()
    }
  }


  useEffect(() => {
    window.addEventListener('keydown', handleKeyPress);

    return () => {
      window.removeEventListener('keydown', handleKeyPress);
    };
  }, [currentSegmentId,playing,currentTime,segments]); // Empty dependency array means this effect runs once on mount




  if (segments === null || speakerLegend === null || 
    status === null || breakpoints === null || originalSegments === null 
    || !audioLoaded) {
    if(videoDetails.error){
      return(
        <div style={{minHeight:"100vh",backgroundColor:"#272727",display:"flex",justifyContent:"center",alignItems:"center"}}>
          <Typography 
            style={{
              color: "red",
              fontSize: 14,
              textAlign: "center",
            }}>
            {videoDetails.error}
            </Typography>
        </div>
      )
    }
    
    return(
    <div style={{minHeight:"100vh",backgroundColor:"#272727",display:"flex",flexDirection:"column",justifyContent:"center"}}>
      <div style={{ width: "20%",alignSelf:"center" }}>
            <LinearProgress/>
      </div>
      <Typography style={{color:"white",alignSelf:"center"}}>Downloading Audio Files...</Typography>
    </div>
    )
  }


  return (
    <React.Fragment>
      <div style={{ minHeight: "100vh", backgroundColor: "#272727" }}>
        <div style={{ display: "flex", flexDirection: "column" }}>
          <div style={{ display: "flex" }}>
            <div style={{ flex: 1 }}>
              <AudioList
                urls={currentSegmentId !== -1 ? findSegmentFromId(segments, currentSegmentId)['audio_generations'] : []}
                selectedIndex={findSegmentFromId(segments, currentSegmentId)['audio_final_index']}
                handleSegmentChange={handleSegmentChange}
                segmentId={currentSegmentId}
                audioDurations={findSegmentFromId(segments, currentSegmentId)['audio_generations_durations']}
                status={status} />
            </div>
            {!videoLoaded && <div style={{ height: "100vh", display: "flex", width: "100%",flexDirection:"column",justifyContent:"center" }}>
              <div style={{ width: "20%", alignSelf: "center" }}>
                <LinearProgress />
              </div>
              <Typography style={{ color: "white", alignSelf: "center" }}>Downloading Video Files...</Typography>
            </div>}
            <Player url={videoUrl}
              handleVideoProgress={handleVideoProgress}
              playing={playing}
              isMute={isMute}
              onBuffer={onBuffer}
              onBufferEnd={onBufferEnd}
              onDuration={onDuration}
              playerRef={playerRef}
              onReady={() => {
                console.log("Ready")
                setVideoLoaded(true)
                
              }}
              videoLoaded= {videoLoaded}
            />
            <div style={{ flex: 1, marginTop: 10 }}>
              {videoLoaded && <SpeakerList speakerLegend={speakerLegend} handleSpeakerAdd={handleSpeakerAdd} status={status}/>}
            </div>
          </div>
          <PlayerControls togglePlaying={togglePlaying}
            toggleMute={toggleMute}
            playing={playing}
            isMute={isMute}
            isBuffering={isBuffering}
            hide={!videoLoaded}
            // handleBgVolumeChange={handleBgVolumeChange}
            // bgVolume={bgVolume}
            bgVolume={null}
          />
          {/* <ReactPlayer
           url={"https://speakmultiassets.s3.amazonaws.com/test/3_monster_bg_edit.mp3"}
           height={0}
           width={0}
           ref={bgAudioRef}
           playing={isBgPlaying}
            volume={bgVolume}

          /> */}
          {<Typography style={{alignSelf:"center",color:"#afb8c1",minHeight:30}}>{currentSegmentId !== -1 ? "Video Segment Duration: " + get_segment_duration(segments,currentSegmentId):""}</Typography>}
          {status === 'ag_waiting' &&  <Typography style={{alignSelf:"center",color:"#afb8c1",minHeight:30}}>{currentSegmentId !== -1 ? "Fitted Audio duration: " + get_audio_final_duration(segments,currentSegmentId):""}</Typography>}
          <Timeline segments={segments}
            timelineWidth={timelineWidth}
            startTime={startTime}
            endTime={endTime}
            currentTime={currentTime}
            timelineRef={timelineRef}
            handleTimelineClick={handleTimelineClick}
            handleBreakpointClick={handleBreakpointClick}
            selectedBreakpoint={selectedBreakpoint}
            breakpoints={breakpoints}
            sliderValue={sliderValue}
            audioChecked={audioChecked}
            hide={!videoLoaded}
          />
          <SliderContainer segments={segments}
            currentSegmentId={currentSegmentId}
            timelineWidth={timelineWidth}
            totalWidth={totalWidth} sliderRef={sliderRef}
            handleScroll={handleTimelineScroll}
            rangeSliderRef={rangeSliderRef} duration={duration}
            speakerLegend={speakerLegend}
            handleSegmentClick={handleSegmentClick}
            onSlide={onSlide}
            onThumbDragEnd={onThumbDragEnd}
            onThumbDragStart={onThumbDragStart}
            onRangeDragEnd={onRangeDragEnd}
            addMode={addMode}
            handleInsertSegmentClick={handleInsertSegmentClick}
            splitMode={splitMode}
            handleSplitClick={handleSplitClick}
            hide={!videoLoaded}

          />
          {/* {currentSegmentId !== -1 && <Typography style={{color:"#afb8c1",
          position:"absolute",
          top:timelineRef.current ?  timelineRef.current.getBoundingClientRect().bottom + 80 : 0,
          left: timelineRef.current ? timelineRef.current.getBoundingClientRect().left + (1200/60) * (findSegmentFromId(segments,currentSegmentId).start - startTime)  : 0,
          fontSize:14,
          }}>{get_segment_duration(segments,currentSegmentId)}</Typography>} */}
          <div style={{ marginTop: 20,display: !videoLoaded ? "none" : "block" }}>
            <Console
              handleTrancriptTranslationClick={handleTrancriptTranslationClick} 
              transcriptTranslationLoader={transcriptTranslation.loading}
              handleToReferenceTranslationClick={handleToReferenceTranslationClick}
              toReferenceTranslationLoader={toReferenceTranslation.loading}
              handleFromReferenceTranslationClick={handleFromReferenceTranslationClick}
              fromReferenceTranslationLoader={fromReferenceTranslation.loading}
              handleSegmentChange={handleSegmentChange}
              segment={findSegmentFromId(segments, currentSegmentId)}
              setAddMode={setAddMode}
              addMode={addMode}
              status={status}
              setSplitMode={setSplitMode}
              splitMode={splitMode}
              handleDeleteClick={deleteSegment}
              handleMergeClick={mergeSegments}
              handleRetranscribeClick={handleRetranscribeClick}
              retranscribeLoader={retranscribe.loading}
              handleMagicSplit={handleMagicSplit}
              magicSplitLoader={magicSplit.loading}
              handleRegenerateClick={handleRegenerateClick}
              regenrateLoader={regenerateAudio.loading}
              handleRefitClick={handleRefitClick}
              refitLoader={refitAudio.loading}
              handlePredictTimeClick={handlePredictTimeClick}
              predictTimeLoader={predictTime.loading}
              handleTrimClick={handleTrimClick}
              audioChecked={audioChecked}
              setAudioChecked={setAudioChecked}
              sliderValue={sliderValue}
              setSliderValue={setSliderValue}
              speakerLegend={speakerLegend}
              handleSpeakerChange={handleSpeakerChange}
              transcriptVisible={transcriptVisible}
              setTranscriptVisible={setTranscriptVisible}
              translationVisible={translationVisible}
              setTranslationVisible={setTranslationVisible}
              segments={segments}
              video_duration={duration}
              setIsSpeakerModalOpen={setIsSpeakerModalOpen}
              selectedBreakpoint={selectedBreakpoint}
            /> 
          </div>
        </div>
            
            {submit.loading ?  <div style={{ width: "20%", margin: "auto" }}>
                                  <LinearProgress style={{marginTop:10}}/> 
                              </div> : 
            <div>
            <ActionButtonSet status={status} 
                        issues={issues}
                         setIsIssuesModalOpen={setIsIssuesModalOpen}
                          handleSubmitClick={handleSubmitClick}
                          setIsContextModalOpen={setIsContextModalOpen}
                          hide={!videoLoaded}
                          taskConfirmed={taskConfirmed}
                          />
            {status === 'tp_waiting' && <ApprovalTextCheck text={"I have assigned the speaker mapping."} checked={taskConfirmed} onCheckChange={(event) => {
              setTaskConfirmed(event.target.checked)
            }}/>}
            </div>
                          
                          }
            {submit.error && (
              <Typography
                style={{
                  color: "red",
                  fontSize: 14,
                  textAlign: "center",
                  marginTop: 5,
                }}
              >
                {submit.error}
              </Typography>
            )
            }
      </div>
      <ReactModal
        isOpen={isRecommendationModalOpen}
        onRequestClose={closeRecommendationModal}
        contentLabel="Example Modal"
        className="CloneModal"
        overlayClassName="Overlay"
      >
        <RecommendationModalNew
          closeRecommendationModal={closeRecommendationModal}
          segmentId={currentSegmentId}
          session_id={video_id}
          alterContext={alterContext}
          alterSegmentFromId={alterSegmentFromId}
          translation={findSegmentFromId(segments, currentSegmentId)['translation']}
          segments={segments}
          />
      </ReactModal>
      <ReactModal
        isOpen={isContextModalOpen}
        onRequestClose={closeContextModal}
        contentLabel="Example Modal"
        className="ContextModal"
        overlayClassName="Overlay"
      >
        <ContextModal
          closeContextModal={closeContextModal}
          context={context}
          setContext={setContext}
          setVerificationInitiated={setVerificationInitiated}
        />
      </ReactModal>
      <ReactModal
        isOpen={isIssuesModalOpen}
        onRequestClose={closeIssuesModal}
        contentLabel="Example Modal"
        className="ContextModal"
        overlayClassName="Overlay"
      >
        <IssuesModal 
          closeIssuesModal={closeIssuesModal}
          issues={issues}
          scrollToSegmentId={scrollToSegmentId}
          setCurrentSegmentId={setCurrentSegmentId}          
          />
      </ReactModal>
      <ReactModal
        isOpen={isSpeakerModalOpen}
        onRequestClose={closeSpeakerModal}
        contentLabel="Example Modal"
        className="ContextModal"
        overlayClassName="Overlay"
      >
        <SpeakerMappingModal speakerLegend={speakerLegend} 
        closeSpeakerModal={closeSpeakerModal} speakerMapping={speakerMapping}
        handleMappingChange = {handleMappingChange}
        session_id={video_id}
        setSpeakerMapping={setSpeakerMapping}/>
      </ReactModal>

      <Snack open={errorSnackbarOpen} setOpen={setErrorSnackbarOpen} message="Something went wrong" severity="error"/>
      <Snack open={successSnackbarOpen} setOpen={setSuccessSnackbarOpen} message="Saved successfully!" severity="success"/>
    </React.Fragment>
  );
};

export default Editor;