import AudioPlayer, { RHAP_UI } from "react-h5-audio-player"
import "../styles/AudioPlayButton.css"
import styled from "styled-components";


const CustomColorAudioPlayButton = styled.div`
    .rhap_main-controls-button {
        color: ${(props) => props.color};
    }
`;

const AudioPlayButton = ({ url,color,handleLoadedMetadata,index }) => {


    return (
        <CustomColorAudioPlayButton color={color}>
            <AudioPlayer
                src={url}
                volume={0.5}
                customProgressBarSection={[]}
                showJumpControls={false}
                customControlsSection={[RHAP_UI.MAIN_CONTROLS]}
                autoPlayAfterSrcChange={false}
                // onLoadedMetaData={(e) => handleLoadedMetadata(e,index)}

            />
        </CustomColorAudioPlayButton>
    )
}

export default AudioPlayButton