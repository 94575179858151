const SliderOverlay = ({splitMode,addMode,handleInsertClick,handleSplitClick,totalWidth}) => {
    return(
        <div
              style={{
                margin: "auto",
                height: 60,
                width: totalWidth,
                backgroundColor: "transparent",
                zIndex: splitMode || addMode ? 9999 : -1,
                position: "absolute",
              }}
              onClick={addMode ? handleInsertClick : handleSplitClick}
            ></div>
    )
}

export default SliderOverlay;