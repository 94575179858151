import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import React, { useState } from "react";
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import { IconButton, Typography } from "@mui/material";

const ToggleButton = ({ flag, setFlag,toggleText }) => {
    return (
        <div style={{ display: "flex" }}>
            {!flag ?
                <IconButton onClick={() => setFlag(true)}>
                    <ExpandMoreIcon style={{ color: "#afb8c1" }} fontSize="medium" />
                </IconButton> :
                <IconButton onClick={() => setFlag(false)}>
                    <ExpandLessIcon style={{ color: "#afb8c1" }} fontSize="medium" />
                </IconButton>}
            <div style={{ flex: 1,alignSelf:"center" }}>
                <Typography style={{color: "#afb8c1"}}>{toggleText}</Typography>
            </div>
        </div>
    )
}

export default ToggleButton;