import { Typography, IconButton, LinearProgress, Button } from "@mui/material"
import AudioPlayButton from "./AudioPlayButton"
import Chip from '@mui/material/Chip';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import "../styles/SpeakerMappingModal.css"
import React from "react";
import CloseIcon from "@mui/icons-material/Close";
import { get_mapped_name_from_mapping, resolve_speaker_mapping } from "../helpers/functions";
import useApi from "../hooks/useApi";


const SpeakerMappingSet = ({ url, name, tags }) => {
    return (
        <div style={{ display: "flex", alignItems: "center" }}>
            <AudioPlayButton url={url} />
            <Typography style={{ marginRight: 10 }}>{name}</Typography>
            <div style={{ display: "flex", flexDirection: "row" }}>
                {tags.map((tag, index) => (
                    <TagChip key={index} text={tag} color="success" />
                ))}
            </div>
        </div>
    )

}

const TagChip = ({ text, color }) => {
    return (
        <Chip label={text} color={color} style={{ marginLeft: 10, marginRight: 10 }} />
    )

}

const MappingSelectSet = ({ speaker, handleMappingChange, speakerMapping, availableVoices }) => {
    return (
        <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-around", marginTop: 15 }}>
            <Typography style={{ alignSelf: "center", color: "#afb8c1", fontSize: 20 }}>{speaker}</Typography>
            <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={get_mapped_name_from_mapping(speakerMapping, speaker)}
                onChange={(e) => handleMappingChange(e, speaker)}
                className="speaker-mapping-select"
            >
                {availableVoices.map((speaker_obj, index) => (
                    <MenuItem value={speaker_obj.name}>{speaker_obj.name !== 'default' ? <SpeakerMappingSet url={speaker_obj.preview} name={speaker_obj.name}
                        tags={speaker_obj.tags} /> : <Typography>{speaker_obj.name}</Typography>}</MenuItem>
                ))}
            </Select>
        </div>
    )
}


const SpeakerMappingModal = ({ speakerLegend, closeSpeakerModal, handleMappingChange, speakerMapping,setSpeakerMapping,session_id }) => {

    const [availableVoices, setAvailableVoices] = React.useState(null)

    const voices = useApi("https://apy.translatetracks.com/get_available_voices")
    const saveMapping = useApi("https://apy.translatetracks.com/update_speaker_mapping")

    const handleSaveClick = () => {
        saveMapping.fetchData('POST', { session_id: session_id, speaker_mapping: speakerMapping },null, localStorage.getItem('token'))
    }

    React.useEffect(() => {
        voices.fetchData('GET',null, {session_id}, localStorage.getItem('token'))
    }, [])

    React.useEffect(() => {
        if (saveMapping.data && saveMapping.data.status === 0) {
            closeSpeakerModal()
        }
    
    },[saveMapping.data])


    React.useEffect(() => {
        if (voices.data) {
            setAvailableVoices(voices.data.data.voices)
            setSpeakerMapping(resolve_speaker_mapping(voices.data.data.speaker_mapping,speakerLegend))
        }
    }, [voices.data])

    return (
        <React.Fragment>
            <div style={{ display: "flex", flexDirection: "row" }}>
                <div style={{ flex: 1 }} />
                <IconButton
                    onClick={closeSpeakerModal}
                    style={{ padding: 0, alignSelf: "flex-start" }}
                >
                    <CloseIcon style={{ color: "white" }} fontSize="small" />
                </IconButton>
            </div>
            {!availableVoices ? <div>
                <LinearProgress style={{ marginTop: 10, width: "20%", margin: "auto" }} />
            </div> :

                <div>

                    {speakerLegend.map((speaker_obj, index) => (
                        speaker_obj.speaker !== 'emotion' && <MappingSelectSet key={index} speaker={speaker_obj.speaker}
                            handleMappingChange={handleMappingChange} speakerMapping={speakerMapping}
                            availableVoices={availableVoices} />
                    ))}

                    {saveMapping.loading ? <LinearProgress style={{ width: "20%", margin: "auto",marginTop: 20 }} />
                        : <div style={{ display: "flex", justifyContent: "center" }}>
                            <Button
                                variant="contained"
                                color="primary"
                                style={{ alignSelf: "center", marginTop: 20 }}
                                onClick={handleSaveClick}
                            >
                                Save
                            </Button>
                        </div>}
                    {saveMapping.error && <Typography style={{ color: "red", fontSize: 14, textAlign: "center", marginTop: 10 }}>{saveMapping.error}</Typography>}

                </div>


            }
        </React.Fragment>
    )
}

export default SpeakerMappingModal;