import { IconButton, Typography } from "@mui/material";
import TextAreaSet from "./TextAreaSet"
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import React, { useState } from "react";
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ToggleButton from "./ToggleButton";

const Inference = ({transcriptTranslationLoader,
    handleTrancriptTranslationClick,handleSegmentChange,
    segment,status,handleToReferenceTranslationClick,toReferenceTranslationLoader,
    handleFromReferenceTranslationClick,fromReferenceTranslationLoader,transcriptVisible,setTranscriptVisible,translationVisible,setTranslationVisible}) => {
    

    return (
        <div style={{ display: "flex", flexDirection: "column" }}>
            <ToggleButton flag={transcriptVisible} setFlag={setTranscriptVisible} toggleText={"Transcription"}/>
            <TextAreaSet 
            visible={transcriptVisible}
            textValue={segment.text} 
            loading={transcriptTranslationLoader}
            onButtonClick={() => handleTrancriptTranslationClick(segment.text)}
            title="Translate the transcript"
            handleTextChange={(event) => {
                handleSegmentChange("text",event.target.value)
                }} />
            <Typography style={{ color: "#afb8c1", 
            textAlign: "center", 
            marginBottom: 10, 
            width: "90%", 
            alignSelf: "center",
            display: transcriptVisible ? "block" : "none" }}>{segment.text_trans}</Typography>
            {status !== 'transcribed_waiting' && (<>
            <ToggleButton flag={translationVisible} setFlag={setTranslationVisible} toggleText={"Translation"} />
            <TextAreaSet visible={translationVisible} 
                        title="Translate the translation"
                        textValue={segment.translation}
                        handleTextChange={(event) => handleSegmentChange("translation",event.target.value)} 
                        target_duration={segment.target_duration}
                        onButtonClick={() => handleToReferenceTranslationClick(segment.translation)}
                        loading={toReferenceTranslationLoader}/>
            <TextAreaSet visible={translationVisible} 
                        title="Translate to translation" 
                        textValue={segment.reference_translation}
                        handleTextChange={(event) => handleSegmentChange("reference_translation",event.target.value)} 
                        onButtonClick={() => handleFromReferenceTranslationClick(segment.reference_translation,segment.original_translation,segment.translation)}
                        loading={fromReferenceTranslationLoader}
                        />
            
            <Typography style={{ color: "#afb8c1", 
            textAlign: "center", 
            width: "90%", 
            alignSelf: "center",
            display: translationVisible ? "block" : "none" }}>{segment.original_translation}</Typography>
            <Typography style={{ color: "#afb8c1", 
            textAlign: "center", 
            marginBottom: 10, 
            width: "90%", 
            alignSelf: "center",
            display: translationVisible ? "block" : "none" }}>{"(" + segment.original_translation_reference + ")"}</Typography>
            </>)}
        </div>
    )
}

export default Inference;