import { IconButton, Typography } from "@mui/material";
import EmptyTextarea from "./EmptyTextArea";
import TranslateIcon from "@mui/icons-material/Translate";
import LoaderIconButton from "./LoaderIconButton";

const TextAreaSet = ({ textValue, onButtonClick, handleTextChange,visible,loading,title}) => {
    return (
        <div style={{ display: !visible ? "none" :"flex",justifyContent:"center",marginBottom:10 }}>
            <EmptyTextarea
                value={textValue}
                onChange={handleTextChange}
            />
            <LoaderIconButton loading={loading} 
            ButtonType={TranslateIcon} onClick={onButtonClick}
            title={title} />
        </div>

    )
}

export default TextAreaSet;