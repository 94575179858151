import CancelPresentationIcon from "@mui/icons-material/CancelPresentation";
import LoaderIconButton from "./LoaderIconButton";
import { Typography } from "@mui/material";



const SplitWidget = ({setSplitMode}) => {
    return (
    <div style={{display:"flex",flexDirection:"column"}}>
        <LoaderIconButton loading={false} 
        ButtonType={CancelPresentationIcon} styles={{alignSelf:"center"}}
        onClick={() => setSplitMode(false)}/>
        <Typography variant="h6"
        style={{ color: "white", margin: "auto" }}>Click on the segment to split it!</Typography>
    </div>
    )
}

export default SplitWidget