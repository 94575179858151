import './App.css';

import { ThemeProvider, createTheme } from '@mui/material/styles';

import AppRouter from './routes/AppRouter';
import { BrowserRouter } from 'react-router-dom';
import logo from './logo.svg';
import { useEffect } from 'react';

const darkTheme = createTheme({
  palette: {
    mode: 'dark',
  },
});

function App() {

  useEffect(() => {
    document.title = process.env.REACT_APP_TITLE
  },[])

  return (
    <ThemeProvider theme={darkTheme}>
   <BrowserRouter>
    <AppRouter />
   </BrowserRouter>
   </ThemeProvider>
  );
}

export default App;
