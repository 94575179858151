import { Button } from "@mui/material"
import { get_button_text } from "../helpers/functions"

const ActionButtonSet = ({ status, issues, 
    setIsIssuesModalOpen,handleSubmitClick,setIsContextModalOpen,hide,taskConfirmed }) => {
    if (issues.length !== 0) {
        return (
            <div style={{ display: hide ? "none" : "flex", justifyContent: "center" }}>
                <Button
                    variant="contained"
                    color="error"
                    style={{ alignSelf: "center", marginTop: 10 }}
                    onClick={() => setIsIssuesModalOpen(true)}
                >
                    Resolve
                </Button>
                <Button
                    variant="contained"
                    color="primary"
                    style={{ alignSelf: "center", marginTop: 10, marginLeft: 10 }}
                onClick={() => handleSubmitClick('save')}
                >
                    Save
                </Button>
            </div>
            
        )
    }
    else {
        return (

            <div style={{ display: hide ? "none" : "flex", justifyContent: "center" }}>
                <Button
                    variant="contained"
                    color="primary"
                    style={{ alignSelf: "center", marginTop: 10 }}
                    onClick={status === "tp_waiting" ? () => setIsContextModalOpen(true): () => handleSubmitClick('update')}
                    disabled={status === "tp_waiting" && !taskConfirmed}
                >
                    {get_button_text(status)}
                </Button>
                <Button
                    variant="contained"
                    color="primary"
                    style={{ alignSelf: "center", marginTop: 10, marginLeft: 10 }}
                onClick={() => handleSubmitClick('save')}
                >
                    Save
                </Button>
            </div>
        )
    }
}

export default ActionButtonSet