import "../styles/DataGroup.css";

import { Typography } from "@mui/material";
import VideoGroup from "../components/VideoGroup";
import englishFlag from "../assets/images/english.png";
import frenchFlag from "../assets/images/french.png";
import germanFlag from "../assets/images/german.png";
import hindiFlag from "../assets/images/hindi.png";
import hinglishFlag from "../assets/images/hinglish.png";
import italianFlag from "../assets/images/italian.png";
import polishFlag from "../assets/images/polish.png";
import portugeseFlag from "../assets/images/portugese.png";
import spainFlag from "../assets/images/spanish.png";
import speakmultiLogo from "../assets/images/speakmulti_logo.png";

const getFlagImage = (language) => {
  switch (language) {
    case "English":
      return englishFlag;
    case "Spanish":
      return spainFlag;
    case "Hindi":
      return hindiFlag;
    case "French":
      return frenchFlag;
    case "German":
      return germanFlag;
    case "Hinglish":
      return hinglishFlag;
    case "Italian":
      return italianFlag;
    case "Polish":
      return polishFlag;
    case "Portuguese":
      return portugeseFlag;
    default:
      return englishFlag;
  }
};

const DataGroup = ({ data }) => {
  return (
    <div>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          alignItems: "baseline",
          flexWrap: "nowrap",
        }}
      >
        <Typography className="title">{data.title}</Typography>
        <Typography className="subtitle">{data.subtitle}</Typography>
      </div>

      <div style={{ display: "flex", flexDirection: "row" }}>
        <VideoGroup
          url={data.source_url}
          flagImage={getFlagImage(data.source_language)}
          language={data.source_language}
          containerStyle={{ flex: 1, alignSelf: "center" }}
          thumbnail={data.thumbnail}
        />
        <img
          src={speakmultiLogo}
          alt="flag"
          style={{ alignSelf: "center" }}
          className="seperator"
        />
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            flexWrap: "wrap",
            flex: 1,
            justifyContent: "center",
          }}
          className="translated"
        >
          {data.targets.map((target, index) => {
            return (
              <VideoGroup
                url={target.target_url}
                flagImage={getFlagImage(target.target_language)}
                language={target.target_language}
                containerStyle={{ flex: "0 0 100%" }}
                key={index}
                thumbnail={target.thumbnail}
              />
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default DataGroup;
