import "../styles/CloneModal.css";

import { LinearProgress, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";

import ReactModal from "react-modal";
import VideoPlayer from "./VideoPlayer";
import axios from "axios";
import { useNavigate } from "react-router-dom";

const TranscriptionModal = ({ selectedVideoId, setModalIsOpen }) => {
  const [transcript, setTranscript] = useState({
    response: null,
    loading: false,
    error: "",
  });
  const [transcriptionVerification, setTranscriptionVerification] = useState({
    loading: false,
    error: "",
  });


  const updateDict = (state, setState, update_object) => {
    console.log("updating to ", { ...state, ...update_object });
    setState({ ...state, ...update_object });
  };
  const navigate = useNavigate();
  const [transcriptRecommendation,setTranscriptRecommendation] = useState(null)
  const [recommendationLoading,setRecommendationLoading] = useState(false)
  
  const getTranscript = async (video_id) => {
    updateDict(transcript, setTranscript, { error: "", loading: true });
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/get_transcript`,
        {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
          params: {
            request_id: video_id,
          },
        }
      );
      console.log(response.data);
      if (response.data.status === 0) {
        updateDict(transcript, setTranscript, {
          response: response.data.data,
          loading: false,
        });
      } else if (response.data.status === -1) {
        localStorage.removeItem("token");
        localStorage.removeItem("email");
        navigate("/auth");
      } else {
        updateDict(transcript, setTranscript, {
          loading: false,
          error: "Something went wrong.Please try again later!",
        });
      }
    } catch (err) {
      console.log(err);

      updateDict(transcript, setTranscript, {
        loading: false,
        error: "Something went wrong.Please try again later!",
      });
    }
  };

  // //save every 10 seconds
  // useEffect(() => {
  //   const interval = setInterval(() => {
  //     if(transcript.response){
  //       verifyTranscript(transcript.response.transcript,transcript.response.context,true,transcript.response.speaker_sound_dict,true)
  //     }
  //   }, 60000);
  //   return () => clearInterval(interval);
  // }, [transcript]);

  const getTranscriptRecommendation = async (start_time,end_time) => {
    setRecommendationLoading(true)
    try {
      const response = await axios.get(
        "https://apx.translatetracks.com/get_transcript_recommendations",
        {
          headers: {
            token: localStorage.getItem("token"),
          },
          params: {
            session_id: selectedVideoId,
            start_time: start_time,
            end_time: end_time
          },
        }
      );
      console.log(response.data);
      if (response.data.status === 0) {
        setRecommendationLoading(false)
        setTranscriptRecommendation(response.data.text)
        console.log(response.data.text)
      } else if (response.data.status === -1) {
        localStorage.removeItem("token");
        localStorage.removeItem("email");
        navigate("/auth");
      } else {
        setRecommendationLoading(false)
      }
    } catch (err) {
      console.log(err);
      setRecommendationLoading(false)
    }
  };

  const verifyTranscript = async (updated_transcript,context,only_save,speakerSoundDict,auto_save) => {
    !auto_save && updateDict(transcriptionVerification, setTranscriptionVerification, {
      error: "",
      loading: true,
    });
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/update_transcript`,
        {
          video_id: selectedVideoId,
          transcript: updated_transcript,
          context:context,
          only_save:only_save,
          speaker_legend:speakerSoundDict
        },
        {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        }
      );
      console.log(response.data);
      if (response.data.status === 0) {
        !auto_save && updateDict(transcriptionVerification, setTranscriptionVerification, {
          loading: false,
        });
        if(!only_save){
        setModalIsOpen(false);
        }
      } else if (response.data.status === -1) {
        localStorage.removeItem("token");
        localStorage.removeItem("email");
        navigate("/auth");
      } else {
        updateDict(transcriptionVerification, setTranscriptionVerification, {
          loading: false,
          error: "Something went wrong.Please try again later!",
        });
      }
    } catch (err) {
      console.log(err);
      updateDict(transcriptionVerification, setTranscriptionVerification, {
        loading: false,
        error: "Something went wrong.Please try again later!",
      });
    }
  };

  useEffect(() => {
    getTranscript(selectedVideoId);
  }, []);

  if (transcript.error) {
    return (
      <Typography
        style={{
          color: "red",
          fontSize: 14,
          textAlign: "center",
          marginTop: 5,
        }}
      >
        {transcript.error}
      </Typography>
    );
  } else if (transcript.loading || !transcript.response) {
    return (
      <div style={{ margin: "auto", width: "20%" }}>
        <LinearProgress style={{ marginTop: 20 }} />
      </div>
    );
  } else {
    return (
      <React.Fragment>
        <VideoPlayer
          videoUrl={transcript.response.video_url}
          initialSpeakerSoundDict={transcript.response.speaker_sound_dict}
          initial_segments={transcript.response.transcript}
          transcriptionVerificationLoader={transcriptionVerification.loading}
          trancriptionVerificationError={transcriptionVerification.error}
          verifyTranscript={verifyTranscript}
          getTranscriptRecommendation={getTranscriptRecommendation}
          recommendationLoading = {recommendationLoading}
          transcriptRecommendation={transcriptRecommendation}
          breakpoints={transcript.response.breakpoints}
          selectedVideoId={selectedVideoId}
          audioBreakpoints={transcript.response.audio_breakpoints}
        />
      </React.Fragment>
    );
  }
};

export default TranscriptionModal;
