import 'react-h5-audio-player/lib/styles.css';
import "../styles/AudPlayer.css"

import AudioPlayer from "react-h5-audio-player"

const AudPlayer = ({src,setAudioDuration}) => {

    const handleLoadedMetadata = (e) => {
        const audioDuration = e.target.duration;
        setAudioDuration(audioDuration);
    };

    return (
        <AudioPlayer
         src={src}
         showJumpControls={false}
         style={{backgroundColor:"#272727"}}
         customVolumeControls={[]}
         customAdditionalControls={[]}
         autoPlay={false}
         onLoadedMetaData={handleLoadedMetadata}
            />
    )
}

export default AudPlayer