
import RangeSlider from "react-range-slider-input";
import styled from "styled-components";
import { findColorFromSpeaker } from "../helpers/functions";


const StyledSlider = styled.div`
  .range-slider__range {
    background-color: ${(props) => props.color};

    ${(props) =>
        props.shadow &&
        `
      box-shadow: 0 0 0 ${props.totalWidth + "px"}  rgba(34, 32, 34, 0.75);
    `}
    pointer-events: auto;
    border: ${(props) => props.borderThickness} solid #f7ff6f;
  }

  .range-slider {
    pointer-events: none;
  }

  .range-slider__thumb {
    pointer-events: auto;
    cursor: e-resize;
    display: ${(props) => (props.displayThumbs ? "block" : "none")};
  }
`;


const Slider = ({segment,segmentId,currentSegmentId,
  totalWidth,duration,index,speakerLegend,handleSegmentClick,onSlide,
  onThumbDragStart,onThumbDragEnd,onRangeDragEnd
  }) => {
    return (
        <StyledSlider
            color={
                currentSegmentId === segmentId
                    ? findColorFromSpeaker(segment.speaker,speakerLegend)
                    : "#807f80"
            }
            shadow={index === 0}
            key={segmentId}
            borderThickness={currentSegmentId === segmentId ? "3px" : "0px"}
            displayThumbs={currentSegmentId === segmentId}
            totalWidth={totalWidth}
        >
            <RangeSlider
                value={[segment.start, segment.end]}
                onInput={(new_range) => onSlide(new_range, index)}
                min={0}
                max={duration}
                step={0.001}
                onRangeDragStart={() => handleSegmentClick(segmentId)}
                onThumbDragStart={() => onThumbDragStart(segmentId)}
                onThumbDragEnd={onThumbDragEnd}
                onRangeDragEnd={onRangeDragEnd}
            />
        </StyledSlider>
    )
}

export default Slider;