import { Typography } from "@mui/material";
import LoaderIconButton from "./LoaderIconButton"
import AddBoxIcon from "@mui/icons-material/AddBox";
import CancelPresentationIcon from "@mui/icons-material/CancelPresentation";


const AddWidget = ({addMode,setAddMode}) => {
        return(
            <div style={{display:"flex",flexDirection:"column"}}>
                <LoaderIconButton loading={false} 
                ButtonType={!addMode ? AddBoxIcon : CancelPresentationIcon} styles={{alignSelf:"center"}}
                onClick={!addMode ? () => setAddMode(true) : () => setAddMode(false)}/>
                <Typography variant="h6"
                style={{ color: "white", margin: "auto" }}>{!addMode ? "Add a new segment":"Cancel"}</Typography>
            </div>
        )
}

export default AddWidget