import "../styles/RangeSlider.css";
import "react-range-slider-input/dist/style.css";

import { FormControl, IconButton, InputLabel, MenuItem, Select, Typography } from "@mui/material";

import AddBoxIcon from "@mui/icons-material/AddBox";
import CancelPresentationIcon from "@mui/icons-material/CancelPresentation";
import DeleteIcon from "@mui/icons-material/Delete";
import Draggable from "react-draggable";
import EmptyTextarea from "../components/EmptyTextArea";
import PauseCircleIcon from "@mui/icons-material/PauseCircle";
import PlayCircleIcon from "@mui/icons-material/PlayCircle";
import RangeSlider from "react-range-slider-input";
import ReactPlayer from "react-player";
import TimelineSvg from "../components/TimelineSvg";
import VolumeUpIcon from "@mui/icons-material/VolumeUp";
import styled from "styled-components";
import { useEffect } from "react";
import { useRef } from "react";
import { useState } from "react";

const StyledRangeSlider = styled.div`
  .range-slider__range {
    background-color: ${(props) => props.color};

    ${(props) =>
      props.shadow &&
      `
      box-shadow: 0 0 0 9999px rgba(0, 0, 0, 0.75);
    `}
    pointer-events: auto
  }

  .range-slider {
    pointer-events: none;
  }

  .range-slider__thumb {
    pointer-events: auto;
    cursor:e-resize
  }
`;

const colors = [
  "red",
  "green",
  "blue",
  "yellow",
  "orange",
  "purple",
  "pink",
  "brown",
  "black",
  "white",
];


const segs = [
  {
    text: "What advice would you give to young folks today in high school and college? How to be successful in their career. How to be successful in their life.",
    start: 0.09,
    end: 7.37,
    speaker: "A",
  },
  {
    text: " Yeah. So the tools that are available today are just like I sometimes bore kids by describing what it was like to go look up a book to try to discover a fact in the old days, the 1970s, 1980s, and go to the library and the card catalog and the whole thing. You go through all that work and then the book is checked out. You have to wait two weeks to be in a world not only where you can get the answer to any question, but also the world.",
    start: 7.49,
    end: 31.33,
    speaker: "B",
  },
  {
    text: " Now, the AI world where you've got, like, the assistant that will help you do anything, help you teach, learn anything. Your ability both to learn and also to produce is just like, I don't know, a million fold beyond what it used to be. I have a blog post I've been wanting to write which I call where are the hyperproductive People?",
    start: 31.37,
    end: 47.58,
    speaker: "B",
  },
  {
    text: "Good question.",
    start: 49.07,
    end: 49.77,
    speaker: "A",
  },
  {
    text: "Right. With these tools, there should be authors that are writing like hundreds or thousands of outstanding books.",
    start: 49.84,
    end: 56.49,
    speaker: "B",
  },
  {
    text: "Well, with the authors, there's a consumption question too. Well, maybe not. Maybe not. You're right. So the tools are much more powerful.",
    start: 56.65,
    end: 65.4,
    speaker: "A",
  },
  {
    text: "Getting much more artists, musicians. Why aren't musicians producing 1000 times the number of songs? Right. The tools are spectacular.",
    start: 65.51,
    end: 74.25,
    speaker: "B",
  },
  {
    text: "So what's the explanation? And by way of advice, is motivation starting to be turned down a little bit or what?",
    start: 74.38,
    end: 83.28,
    speaker: "A",
  },
  {
      "text": "I think it might be distraction.",
      "start": 83.38,
      "end": 84.78,
      "speaker": "B"
  },
  {
      "text": "Distraction.",
      "start": 84.89,
      "end": 85.74,
      "speaker": "A"
  },
  {
      "text": " It's so easy to just sit and consume that I think people get distracted from production. But if you wanted to as a young person, if you wanted to really stand out, you could get on like a hyperproductivity curve very early on. There's a great story in Roman history of plenty of the elder who was this legendary statesman died in the Vesuvius eruption trying to rescue his friends.",
      "start": 85.85,
      "end": 107.93,
      "speaker": "B"
  },
  {
      "text": " But he was famous both for being basically being a polymath, but also being an author. And he wrote apparently, like, hundreds of books, most of which have been lost. But he wrote all these encyclopedias and he literally would be reading and writing all day long no matter what else was going on. So he would travel with four slaves and two of them were responsible for reading to him and two of them were responsible for taking dictation.",
      "start": 107.97,
      "end": 127.91,
      "speaker": "B"
  },
  {
      "text": " And so he'd be going cross country and literally he would be writing books all the time. And apparently they were spectacular. There's only a few that have survived, but apparently they were amazing.",
      "start": 128.09,
      "end": 136.31,
      "speaker": "B"
  },
  {
      "text": "So there's a lot of value to being somebody who finds focus in this life.",
      "start": 136.38,
      "end": 139.75,
      "speaker": "A"
  },
  {
      "text": " Yeah, and there are examples. There's this guy, Judge what's his name? Posner. Posner. Who wrote like, 40 books and was also a great federal judge. Our friend biology, I think it's like this. He's one of these where his output is just prodigious. And so it's like, yeah, I mean, with these tools, why not? And I kind of think we're at this interesting kind of freeze frame moment where these tools are now in everybody's hands, and everybody's just kind of staring at them, trying to figure out what to do.",
      "start": 139.82,
      "end": 163.87,
      "speaker": "B"
  },
  {
      "text": "The new tools we have discovered fire and trying to figure out how to use it to cook.",
      "start": 164.02,
      "end": 168.57,
      "speaker": "A"
  }
];

const speakerSoundDict = {
  A: "https://speakmulti-assets.s3.amazonaws.com/user-data/ba05a40f-f0de-4d02-8341-8fd2b9f8dda5/bdcd0d8e-1a8d-407e-8a02-883ce6a00c82/speaker_A/segment_1.wav",
  B: "https://speakmulti-assets.s3.amazonaws.com/user-data/ba05a40f-f0de-4d02-8341-8fd2b9f8dda5/bdcd0d8e-1a8d-407e-8a02-883ce6a00c82/speaker_B/segment_1.wav",
};

const formatDuration = (duration) => {
  let seconds = Math.ceil(duration);
  const hrs = Math.floor(seconds / 3600);
  const mins = Math.floor((seconds % 3600) / 60);
  seconds = seconds % 60;

  let durationStr =
    (hrs > 0 ? `${hrs.toString().padStart(2, "0")}:` : "") +
    `${mins.toString().padStart(1, "0")}:` +
    `${seconds.toString().padStart(2, "0")}`;

  return durationStr;
};

const findChangingTime = (old_range, new_range) => {
  if (old_range[0] !== new_range[0]) {
    return new_range[0];
  } else {
    return new_range[1];
  }
};

const isIncreamentPossible = (segments, new_range, index) => {
  //first range of multiple ranges
  if (index === 0 && segments.length > 1) {
    if (new_range[1] < segments[index + 1].start) {
      return true;
    } else {
      return false;
    }
    //first range of single range
  } else if (segments.length === 1) {
    return true;
    //last range of multiple ranges
  } else if (index === segments.length - 1) {
    if (new_range[0] > segments[index - 1].end) {
      return true;
    } else {
      return false;
    }
  } else {
    if (
      new_range[0] > segments[index - 1].end &&
      new_range[1] < segments[index + 1].start
    ) {
      return true;
    } else {
      return false;
    }
  }
};

const findSegemntIndexFromCurrentTime = (segments, playedSeconds) => {
  for (let i = 0; i < segments.length; i++) {
    if (
      playedSeconds >= segments[i].start &&
      playedSeconds <= segments[i].end
    ) {
      return i;
    }
  }
  return -1;
};

const initializeSpeakerColorDict = (segments) => {
  let dict = {};
  for (let i = 0; i < segments.length; i++) {
    if (!(segments[i].speaker in dict)) {
      //randomly get from colors list such that it is not already in dict
      let randomColor = colors[Math.floor(Math.random() * colors.length)];
      while (Object.values(dict).includes(randomColor)) {
        randomColor = colors[Math.floor(Math.random() * colors.length)];
      }
      dict[segments[i].speaker] =
        colors[Math.floor(Math.random() * colors.length)];
    }
  }
  console.log(dict);
  return dict;
};



const VideoTest = () => {
  const [playing, setPlaying] = useState(false);
  const [duration, setDuration] = useState(0);
  const [currentTime, setCurrentTime] = useState(0);
  const timelineRef = useRef(null);
  const playerRef = useRef(null);
  const rangeSliderRef = useRef(null);
  const [segments, setSegments] = useState(segs);
  const [segmentIndex, setSegmentIndex] = useState(-1);
  const [addMode, setAddMode] = useState(false);
  const [speakerColorDict, setSpeakerColorDict] = useState(() =>
    initializeSpeakerColorDict(segments)
  );
  const [startTime, setStartTime] = useState(0);
  const [endTime, setEndTime] = useState(60);
  const sliderRef = useRef(null);

  const onVideoProgress = ({ playedSeconds }) => {
    setCurrentTime(playedSeconds);
    let index = findSegemntIndexFromCurrentTime(segments, playedSeconds);
    setSegmentIndex(index);
  };

  //insert into segments maintainng startTime order
  const insertSegment = (startTime) => {
    let new_segments = [...segments];
    let index = 0;
    for (let i = 0; i < new_segments.length; i++) {
      if (new_segments[i].start < startTime) {
        index = i + 1;
      }
    }
    new_segments.splice(index, 0, {
      start: startTime,
      end: startTime + 1,
      text: "",
      speaker:"A"
    });

    setSegments(new_segments);
  };

  const deleteSegment = () => {
    let new_segments = [...segments];
    new_segments.splice(segmentIndex, 1);
    setSegments(new_segments);
    setSegmentIndex(-1);
  };

  const alterSegments = (alter_index, alter_obj) => {
    let new_segments = [...segments];
    new_segments[alter_index] = { ...segments[alter_index], ...alter_obj };
    setSegments(new_segments);
  };

  const onSlide = (new_range, slide_index) => {
    if (isIncreamentPossible(segments, new_range, slide_index)) {
      const old_range = [
        segments[slide_index].start,
        segments[slide_index].end,
      ];
      playerRef.current.seekTo(
        findChangingTime(old_range, new_range),
        "seconds"
      );
      alterSegments(slide_index, { start: new_range[0], end: new_range[1] });
    } else {
      console.log(segments[slide_index], new_range, "Not possible!");
    }
  };

  const togglePlaying = () => {
    setPlaying(!playing);
  };

  const handleInsertClick = (e) => {
    const secondsPerPixel = duration / rangeSliderRef.current.offsetWidth;
    const clickTime =
      (e.clientX - rangeSliderRef.current.getBoundingClientRect().left) *
      secondsPerPixel;
    insertSegment(clickTime);
    playerRef.current.seekTo(clickTime + 0.5, "seconds");
    setAddMode(false);
  };

  const handleTimelineClick = (e) => {
    const secondsPerPixel =
      (endTime - startTime) / timelineRef.current.offsetWidth;
    const clickTime =
      (e.clientX - rangeSliderRef.current.getBoundingClientRect().left) *
      secondsPerPixel;
    playerRef.current.seekTo(clickTime, "seconds");
  };

  const handleSegmentClick = (index) => {
    playerRef.current.seekTo(segments[index].start, "seconds");
    setSegmentIndex(index);
  };

  const handleTranscriptChange = (event, index) => {
    alterSegments(index,{text:event.target.value})
  };

  useEffect(() => {
    if (Math.floor(currentTime) === Math.floor(endTime)) {
      const secondsPerPixel = duration / 2267;
      const newScrollLeft = currentTime / secondsPerPixel;
      if (sliderRef.current) {
        sliderRef.current.scrollLeft = newScrollLeft;
        const newStartTime = newScrollLeft * secondsPerPixel;
        const newEndTime =
          newStartTime + 800 * secondsPerPixel < duration
            ? newStartTime + 800 * secondsPerPixel
            : duration;
        setStartTime(newStartTime);
        setEndTime(newEndTime);
      }
    }
  }, [currentTime]);

  const handleScroll = (e) => {
    const secondsPerPixel = duration / 2267;
    const newStartTime = e.currentTarget.scrollLeft * secondsPerPixel;
    const newEndTime = newStartTime + 800 * secondsPerPixel;
    setStartTime(newStartTime);
    setEndTime(newEndTime);
  };

  const handleSpeakerChange = (event,index) => {
    alterSegments(index,{speaker:event.target.value})
  }

  return (
    <div style={{ backgroundColor: "#161313", height: "100vh" }}>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
        }}
      >
        <div
          className="video-player"
          style={{ display: "flex", flexDirection: "row" }}
        >
          <div style={{ flex: 1 }}></div>
          <ReactPlayer
            className="react-player"
            url={
              "https://speakmulti-assets.s3.amazonaws.com/user-data/ba05a40f-f0de-4d02-8341-8fd2b9f8dda5/bdcd0d8e-1a8d-407e-8a02-883ce6a00c81/video.mp4"
            }
            playing={playing}
            onDuration={(duration) => setDuration(duration)}
            onProgress={onVideoProgress}
            ref={playerRef}
            progressInterval={10}
            width={480}
            height={270}
          />
          <div style={{ alignSelf: "center", flex: 1 }}>
            <div
              style={{
                padding: 10,
                backgroundColor: speakerColorDict["A"],
                width: "50%",
                margin: "auto",
                display:"flex"
              }}
            >
              <Typography style={{marginRight:5}}>A</Typography>
              <audio controls style={{flex:1,height:20}}>
                <source src={speakerSoundDict['A']}/>
              </audio>

            </div>
            <div
              style={{
                padding: 10,
                backgroundColor: speakerColorDict["B"],
                width: "50%",
                margin: "auto",
                display:"flex"
              }}
            >
              <Typography style={{marginRight:5}}>B</Typography>
              <audio controls style={{flex:1,height:20}}>
                <source src={speakerSoundDict['B']}/>
              </audio>
            </div>
          </div>
        </div>
        <div
          className="controls"
          style={{ display: "flex", alignSelf: "center", flexDirection: "row" }}
        >
          <IconButton onClick={togglePlaying}>
            {playing ? (
              <PauseCircleIcon style={{ color: "white" }} fontSize="medium" />
            ) : (
              <PlayCircleIcon style={{ color: "white" }} fontSize="medium" />
            )}
          </IconButton>
          <IconButton>
            <VolumeUpIcon style={{ color: "white" }} fontSize="medium" />
          </IconButton>
        </div>
        <div
          className="timeline"
          ref={timelineRef}
          style={{
            display: "flex",
            alignSelf: "center",
            flexDirection: "row",
            position: "relative",
            marginTop: 5,
          }}
          onClick={handleTimelineClick}
        >
          <time
            style={{
              color: "white",
              position: "absolute",
              top: "-4px",
              fontSize: 12,
              transform: "translateX(-50%)",
            }}
          >
            {formatDuration(startTime)}
          </time>
          <TimelineSvg lineSpacing={36} />
          <time
            style={{
              color: "white",
              position: "absolute",
              top: "-4px",
              right: 0,
              fontSize: 12,
              transform: "translateX(50%)",
            }}
          >
            {formatDuration(endTime)}
          </time>
          <time
            style={{
              color: "black",
              position: "absolute",
              top: "-8px",
              left: `${
                ((currentTime - startTime) / (endTime - startTime)) * 100
              }%`,
              fontSize: 12,
              padding: 4,
              backgroundColor: "white",
              borderRadius: 20,
              transform: "translateX(-50%)",
              cursor: "pointer",
              display:
                currentTime < startTime || currentTime > endTime
                  ? "none"
                  : "block",
            }}
          >
            {formatDuration(currentTime)}
          </time>
        </div>
        <div
          style={{ width: 800, margin: "auto", overflow: "auto" }}
          ref={sliderRef}
          onScroll={handleScroll}
        >
          <div id="range-slider" ref={rangeSliderRef} style={{ width: 2267 }}>
            {segments.map((segment, index) => {
              return (
                <StyledRangeSlider
                  color={
                    segmentIndex === index
                      ? speakerColorDict[segment.speaker]
                      : "pink"
                  }
                  shadow={index === 0}
                  key={index}
                >
                  <RangeSlider
                    value={[segment.start, segment.end]}
                    onInput={(new_range) => onSlide(new_range, index)}
                    min={0}
                    max={duration}
                    step={0.001}
                    onRangeDragStart={() => handleSegmentClick(index)}
                  />
                </StyledRangeSlider>
              );
            })}
            <div
              style={{
                margin: "auto",
                height: 60,
                width: 2267,
                backgroundColor: "transparent",
                zIndex: addMode ? 9999 : -1,
                position: "absolute",
              }}
              onClick={handleInsertClick}
            ></div>
          </div>
        </div>
        <div
          className="console"
          style={{ alignSelf: "center", width: 800, marginTop: 10 }}
        >
          {segmentIndex !== -1 ? (
            <div style={{ display: "flex" }}>
              <EmptyTextarea value={segments[segmentIndex].text}  onChange={(event) => {
                      handleTranscriptChange(event, segmentIndex);
                    }}/>
                    <div style={{display:"flex",flexDirection:"column"}}>
                    <FormControl style={{color:"white",width:100,marginLeft:10}}>
                <InputLabel id="speaker" style={{color:"white"}}>Speaker</InputLabel>
                <Select
                  labelId="speaker"
                  id="demo-simple-select"
                  value={segments[segmentIndex].speaker}
                  label="Speaker"
                  onChange={(e) => handleSpeakerChange(e,segmentIndex)}
                >
                  <MenuItem value={"A"}>A</MenuItem>
                  <MenuItem value={"B"}>B</MenuItem>
                </Select>
              </FormControl>

                  
              <IconButton style={{ margin: "auto" }} onClick={deleteSegment}>
                <DeleteIcon style={{ color: "white" }} fontSize="large" />
              </IconButton>
              </div>
            </div>
          ) : (
            <div style={{ display: "flex", flexDirection: "column" }}>
              {!addMode && (
                <IconButton
                  style={{ margin: "auto" }}
                  onClick={() => setAddMode(true)}
                >
                  <AddBoxIcon style={{ color: "white" }} fontSize="large" />
                </IconButton>
              )}
              <Typography
                variant="h7"
                style={{ color: "white", margin: "auto" }}
              >
                {!addMode
                  ? "Add a new segment"
                  : "Click on the slider bar to add a new segment!"}
              </Typography>
              {addMode && (
                <IconButton
                  style={{ margin: "auto" }}
                  onClick={() => setAddMode(false)}
                >
                  <CancelPresentationIcon
                    style={{ color: "white" }}
                    fontSize="large"
                  />
                </IconButton>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default VideoTest;
