import { Button, LinearProgress, TextField, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";

import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Header from "../components/Header";
import axios from "axios";
import logoImageSm from "../assets/images/white_speakmulti_logo.png";
import logoImagett from "../assets/images/white_translatetracks_logo.png"
import { useNavigate } from "react-router-dom";

const Authentication = () => {

  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState(false);
  const [verificationScreen, setVerificationScreen] = useState(false);
  const [otpLoader, setOtpLoader] = useState(false);
  const [verificationLoader, setVerificationLoader] = useState(false);
  const [otp, setOtp] = useState("");
  const [otpError, setOtpError] = useState(false);
  const navigate = useNavigate();
  const [apiError,setApiError] = useState('')

  
  const handleEmailChange = (event) => {
    setEmail(event.target.value);
    const isValid = validateEmail(event.target.value);
    if (!isValid) {
      setEmailError(true);
    } else {
      setEmailError(false);
    }
  };

  const validateEmail = (email) => {
    var re = /\S+@\S+\.\S+/;
    return re.test(email);
  };

  const handleOTPRequest = async () => {
    console.log(email);
    setOtpLoader(true);
    setApiError('')
    try{
        const response = await axios.post(`${process.env.REACT_APP_API_URL}/get_otp`,{
            'email':email
        })
        console.log(response);
        if(response.data.status === 0){
            setOtpLoader(false);
            setVerificationScreen(true);
        }else{
            setApiError('Something went wrong!Please try again.');
            setOtpLoader(false);
        }
    }catch(err){
        setApiError('Something went wrong!Please try again.');
        setOtpLoader(false);
        console.log(err);
    }
  };

  const handleOtpVerify = async () => {
    console.log(otp);
    setVerificationLoader(true);
    setApiError('')
    try{
        const response = await axios.post(`${process.env.REACT_APP_API_URL}/verify_otp`,{
            'email':email,
            'otp':otp
        })
        console.log(response);
        if(response.data.status === 0){
            setVerificationLoader(false);
            localStorage.setItem('token',response.data.token);
            localStorage.setItem('email',email)
            navigate("/")
        }else if(response.data.status === 1){
            setVerificationLoader(false);
            setApiError("Incorrect OTP!")
        }else if(response.data.status === 3){
            setVerificationLoader(false);
            setApiError('OTP expired,Please resend it!')
        }else{
            setApiError('Something went wrong!Please try again.');
            setVerificationLoader(false);
        }
  }catch(err){
        setApiError('Something went wrong!Please try again.');
        setVerificationLoader(false);
        console.log(err);
    }
}

  const validateOTP = (otp) => {
    var re = /^\d{4}$/;
    return re.test(otp);
  };

  const handleOtpChange = (event) => {
    setOtp(event.target.value);
    const isValid = validateOTP(event.target.value);
    if (!isValid) {
      setOtpError(true);
    } else {
      setOtpError(false);
    }
  };

  const tryLocalLogin = () => {
    
    const token = localStorage.getItem("token");
    if (token) {
      navigate("/");
    }
  }


  useEffect(() => {
    tryLocalLogin();
  }, [])

  return (
    <React.Fragment>
      <Header />
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
        // backgroundColor:"#272727"
        backgroundColor: "rgb(60,9,9)",
    background: "linear-gradient(42deg, rgba(60,9,9,1) 0%, rgba(5,27,57,1) 46%, rgba(0,0,0,1) 100%)"
      }}
    >
      <Card style={{ width: "30%",minWidth:'300px' }}>
        <CardContent>
            {verificationScreen && <div style={{display:"flex"}}>
                <ArrowBackIcon style={{cursor:"pointer"}} onClick={()=>setVerificationScreen(false)}/>
            </div>}
            <div style={{padding:20}}>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
            }}
          >
            <img
              src={process.env.REACT_APP_DOMAIN === 'sm' ? logoImageSm : logoImagett}
              alt="logo"
              height={40}
              // width={50}
              style={{ marginRight: 10 }}
            />
            {/* <Typography
              variant="h5"
              component="div"
              style={{ alignSelf: "center" }}
            >
              SpeakMulti
            </Typography> */}
          </div>
          <div>
            <Typography
              component="div"
              style={{ marginTop: 10, textAlign: "center" }}
            >
              {verificationScreen
                ? "OTP sent to " + email
                : "Verify to continue!"}
            </Typography>
          </div>
          <div style={{ marginTop: 20, width: "100%" }}>
            {verificationScreen ? (
              <TextField
                required
                id="standard-required"
                label="OTP"
                variant="standard"
                style={{ width: "100%" }}
                onChange={handleOtpChange}
                error={otpError}
                helperText={otpError ? "Please enter a valid 4 digit OTP" : ""}
              />
            ) : (
              <TextField
                required
                id="outlined-required"
                label="Email"
                type="email"
                style={{ width: "100%" }}
                value={email}
                onChange={handleEmailChange}
                error={emailError}
                helperText={emailError ? "Please enter a valid email" : ""}
              />
            )}
            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
              }}
            >
              {verificationScreen ? (
                !verificationLoader ? (
                <Button
                  variant="contained"
                  style={{ marginTop: 20 }}
                  disabled={otpError || otp.length === 0 || otp.length !== 4}
                  onClick={handleOtpVerify}
                >
                  Verify
                </Button>)
                : <LinearProgress style={{width:"100%",marginTop:20}}/>
              ) : (
                !otpLoader ? (
                <Button
                  variant="contained"
                  style={{ marginTop: 20 }}
                  disabled={emailError || email.length === 0}
                  onClick={handleOTPRequest}
                >
                  Get OTP
                </Button>
              ): <LinearProgress style={{width:"100%",marginTop:20}}/>) }
            </div>
            {apiError && <Typography style={{fontSize:12,textAlign:"center",marginTop:10,color:"red"}}>
                    {apiError}
              </Typography>}
          </div>
          </div>
        </CardContent>
      </Card>
    </div>
    </React.Fragment>

  );
};

export default Authentication;
