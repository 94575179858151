export const segmentData = [
    {
        "text": " Siamo al 1993, boom dei videogiochi, ci sono Sega e Nintendo e viene presentato il Sega VR.",
        "start": 0.05,
        "end": 6.24,
        "speaker": "A",
        "id": "2e62aa2",
        "audio_final": [
            "https://speakmultiassets.s3.amazonaws.com/user-data/cdaf80e6-cdb4-442e-ba31-72b354783b16/98447cd0-65d6-46e3-96b6-9da9b5a73c98/2e62aa2/low_audio_seg_output_1.wav"
        ],
        "audio_final_durations": [
            6.21
        ],
        "audio_final_index": 0,
        "audio_generations": [
            "https://speakmultiassets.s3.amazonaws.com/user-data/cdaf80e6-cdb4-442e-ba31-72b354783b16/98447cd0-65d6-46e3-96b6-9da9b5a73c98/2e62aa2/desilenced_1.wav"
        ],
        "audio_generations_durations": [
            5.89
        ],
        "translation": "We are in 1993, the boom of video games, there are Sega and Nintendo and the Sega VR is introduced.",
        "original_translation": "We are in 1993, the boom of video games, there are Sega and Nintendo and the Sega VR is introduced.",
        "reference_translation": "",
        "original_translation_reference": "",
        "target_duration": 6.19,
        "original_text_duration": 4.6499423828125,
        "duration": 4.6499423828125,
        "mapped": true,
        "text_trans": "We are in 1993, the video game boom, Sega and Nintendo are there, and the Sega VR is presented."
    },
    {
        "text": "30 anni fa",
        "speaker": "A",
        "start": 6.54,
        "end": 7.38,
        "text_trans": "30 years ago",
        "translation": "30 years ago",
        "id": "df989bb",
        "audio_final": [
            "https://speakmultiassets.s3.amazonaws.com/user-data/cdaf80e6-cdb4-442e-ba31-72b354783b16/98447cd0-65d6-46e3-96b6-9da9b5a73c98/df989bb/low_audio_seg_output_1.wav"
        ],
        "audio_final_durations": [
            0.86
        ],
        "audio_final_index": 0,
        "audio_generations": [
            "https://speakmultiassets.s3.amazonaws.com/user-data/cdaf80e6-cdb4-442e-ba31-72b354783b16/98447cd0-65d6-46e3-96b6-9da9b5a73c98/df989bb/desilenced_1.wav"
        ],
        "audio_generations_durations": [
            0.96
        ],
        "original_translation": "30 years ago",
        "reference_translation": "",
        "original_translation_reference": "",
        "target_duration": 0.84,
        "original_text_duration": 0.626497314453125,
        "duration": 0.626497314453125,
        "mapped": true
    },
    {
        "text": "queste erano le immagini della presentazione, cioè follia!",
        "speaker": "A",
        "start": 7.84,
        "end": 11.58,
        "text_trans": "These were the images from the presentation, that is madness!",
        "translation": "these were the images of the presentation, it was madness!",
        "id": "35c5f33",
        "audio_final": [
            "https://speakmultiassets.s3.amazonaws.com/user-data/cdaf80e6-cdb4-442e-ba31-72b354783b16/98447cd0-65d6-46e3-96b6-9da9b5a73c98/35c5f33/low_audio_seg_output_1.wav"
        ],
        "audio_final_durations": [
            3.76
        ],
        "audio_final_index": 0,
        "audio_generations": [
            "https://speakmultiassets.s3.amazonaws.com/user-data/cdaf80e6-cdb4-442e-ba31-72b354783b16/98447cd0-65d6-46e3-96b6-9da9b5a73c98/35c5f33/desilenced_1.wav"
        ],
        "audio_generations_durations": [
            3.11
        ],
        "original_translation": "these were the images of the presentation, it was madness!",
        "reference_translation": "",
        "original_translation_reference": "",
        "target_duration": 3.74,
        "original_text_duration": 2.822815673828125,
        "duration": 2.822815673828125,
        "mapped": true
    },
    {
        "text": " Ecco, peccato che poi si siano dimenticati e non l'abbiano mai realmente messo in produzione, forse perché era tipo irrealizzabile per l'epoca.",
        "start": 11.76,
        "end": 19.42,
        "speaker": "A",
        "id": "43f28cf",
        "audio_final": [
            "https://speakmultiassets.s3.amazonaws.com/user-data/cdaf80e6-cdb4-442e-ba31-72b354783b16/98447cd0-65d6-46e3-96b6-9da9b5a73c98/43f28cf/low_audio_seg_output_1.wav"
        ],
        "audio_final_durations": [
            7.68
        ],
        "audio_final_index": 0,
        "audio_generations": [
            "https://speakmultiassets.s3.amazonaws.com/user-data/cdaf80e6-cdb4-442e-ba31-72b354783b16/98447cd0-65d6-46e3-96b6-9da9b5a73c98/43f28cf/desilenced_1.wav"
        ],
        "audio_generations_durations": [
            6.28
        ],
        "translation": "Unfortunately, they then forgot about it and never really put it into production, perhaps because it was kind of unachievable for the time.",
        "original_translation": "Unfortunately, they then forgot about it and never really put it into production, perhaps because it was kind of unachievable for the time.",
        "reference_translation": "",
        "original_translation_reference": "",
        "target_duration": 7.66,
        "original_text_duration": 6.6047919921875,
        "duration": 6.6047919921875,
        "mapped": true,
        "text_trans": "Here, it's a shame that they then forgot about it and never really put it into production, perhaps because it was unrealizable for the time."
    },
    {
        "text": " Nel 1995 Nintendo ci riprova con il Virtual Boy che è un po' strano, perché invece che indossarlo lo tieni su un cavalletto e ti metti sopra.",
        "start": 19.6,
        "end": 27.96,
        "speaker": "A",
        "id": "6723aa8",
        "audio_final": [
            "https://speakmultiassets.s3.amazonaws.com/user-data/cdaf80e6-cdb4-442e-ba31-72b354783b16/98447cd0-65d6-46e3-96b6-9da9b5a73c98/6723aa8/low_audio_seg_output_1.wav"
        ],
        "audio_final_durations": [
            8.38
        ],
        "audio_final_index": 0,
        "audio_generations": [
            "https://speakmultiassets.s3.amazonaws.com/user-data/cdaf80e6-cdb4-442e-ba31-72b354783b16/98447cd0-65d6-46e3-96b6-9da9b5a73c98/6723aa8/desilenced_1.wav"
        ],
        "audio_generations_durations": [
            7.46
        ],
        "translation": "In 1995 Nintendo tries again with the Virtual Boy which is a bit strange, because instead of wearing it you put it on a stand and lean over it.",
        "original_translation": "In 1995 Nintendo tries again with the Virtual Boy which is a bit strange, because instead of wearing it you put it on a stand and lean over it.",
        "reference_translation": "",
        "original_translation_reference": "",
        "target_duration": 8.36,
        "original_text_duration": 6.607400390625,
        "duration": 6.607400390625,
        "mapped": true,
        "text_trans": "In 1995, Nintendo tries again with the Virtual Boy, which is a bit strange because instead of wearing it, you place it on a stand and position yourself above it."
    },
    {
        "text": " Ci sono delle cose tipo Mario... tennis, fighissimo, però insomma costi di produzione, cose, non ha successo e finisce malissimo.",
        "start": 28.14,
        "end": 35.22,
        "speaker": "A",
        "id": "5dbe53a",
        "audio_final": [
            "https://speakmultiassets.s3.amazonaws.com/user-data/cdaf80e6-cdb4-442e-ba31-72b354783b16/98447cd0-65d6-46e3-96b6-9da9b5a73c98/5dbe53a/low_audio_seg_output_1.wav"
        ],
        "audio_final_durations": [
            7.1
        ],
        "audio_final_index": 0,
        "audio_generations": [
            "https://speakmultiassets.s3.amazonaws.com/user-data/cdaf80e6-cdb4-442e-ba31-72b354783b16/98447cd0-65d6-46e3-96b6-9da9b5a73c98/5dbe53a/desilenced_1.wav"
        ],
        "audio_generations_durations": [
            6.81
        ],
        "translation": "There are things like Mario... tennis, very cool, but anyway production costs, things, it was not successful and ended very badly.",
        "original_translation": "There are things like Mario... tennis, very cool, but anyway production costs, things, it was not successful and ended very badly.",
        "reference_translation": "",
        "original_translation_reference": "",
        "target_duration": 7.08,
        "original_text_duration": 6.33405615234375,
        "duration": 6.33405615234375,
        "mapped": true,
        "text_trans": "There are things like Mario... tennis, really cool, but you know, production costs, things, it doesn't succeed and it ends really badly."
    },
    {
        "text": " Ma la situazione cambia completamente quando viene presentato Oculus Rift.",
        "start": 35.32,
        "end": 39.08,
        "speaker": "A",
        "id": "1cdb348",
        "audio_final": [
            "https://speakmultiassets.s3.amazonaws.com/user-data/cdaf80e6-cdb4-442e-ba31-72b354783b16/98447cd0-65d6-46e3-96b6-9da9b5a73c98/1cdb348/low_audio_seg_output_1.wav"
        ],
        "audio_final_durations": [
            3.78
        ],
        "audio_final_index": 0,
        "audio_generations": [
            "https://speakmultiassets.s3.amazonaws.com/user-data/cdaf80e6-cdb4-442e-ba31-72b354783b16/98447cd0-65d6-46e3-96b6-9da9b5a73c98/1cdb348/desilenced_1.wav"
        ],
        "audio_generations_durations": [
            3.7
        ],
        "translation": "But the situation changes completely when Oculus Rift is introduced.",
        "original_translation": "But the situation changes completely when Oculus Rift is introduced.",
        "reference_translation": "",
        "original_translation_reference": "",
        "target_duration": 3.76,
        "original_text_duration": 3.307410888671875,
        "duration": 3.307410888671875,
        "mapped": true,
        "text_trans": "But the situation completely changes when Oculus Rift is introduced."
    },
    {
        "text": " Ci vogliono anni per svilupparlo, il Kickstarter viene lanciato nel 2012, raccoglie 2 milioni di euro, dollari, va beh.",
        "start": 39.32,
        "end": 45.19,
        "speaker": "A",
        "id": "2869aef",
        "audio_final": [
            "https://speakmultiassets.s3.amazonaws.com/user-data/cdaf80e6-cdb4-442e-ba31-72b354783b16/98447cd0-65d6-46e3-96b6-9da9b5a73c98/2869aef/low_audio_seg_output_1.wav"
        ],
        "audio_final_durations": [
            5.89
        ],
        "audio_final_index": 0,
        "audio_generations": [
            "https://speakmultiassets.s3.amazonaws.com/user-data/cdaf80e6-cdb4-442e-ba31-72b354783b16/98447cd0-65d6-46e3-96b6-9da9b5a73c98/2869aef/desilenced_1.wav"
        ],
        "audio_generations_durations": [
            5.44
        ],
        "translation": "It takes years to develop it, the Kickstarter is launched in 2012, it raises 2 million euros, dollars, well.",
        "original_translation": "It takes years to develop it, the Kickstarter is launched in 2012, it raises 2 million euros, dollars, well.",
        "reference_translation": "",
        "original_translation_reference": "",
        "target_duration": 5.87,
        "original_text_duration": 5.19093408203125,
        "duration": 5.19093408203125,
        "mapped": true,
        "text_trans": "It takes years to develop it, the Kickstarter is launched in 2012, it raises 2 million euros, dollars, well."
    },
    {
        "text": " Nel 2014 Facebook compra Oculus per 2 miliardi di dollari e nel 2016 esce finalmente il primo visore diciamo per tutti.",
        "start": 45.43,
        "end": 54.35,
        "speaker": "A",
        "id": "be3e2a0",
        "audio_final": [
            "https://speakmultiassets.s3.amazonaws.com/user-data/cdaf80e6-cdb4-442e-ba31-72b354783b16/98447cd0-65d6-46e3-96b6-9da9b5a73c98/be3e2a0/low_audio_seg_output_1.wav"
        ],
        "audio_final_durations": [
            8.94
        ],
        "audio_final_index": 0,
        "audio_generations": [
            "https://speakmultiassets.s3.amazonaws.com/user-data/cdaf80e6-cdb4-442e-ba31-72b354783b16/98447cd0-65d6-46e3-96b6-9da9b5a73c98/be3e2a0/desilenced_1.wav"
        ],
        "audio_generations_durations": [
            5.9
        ],
        "translation": "In 2014 Facebook buys Oculus for 2 billion dollars and in 2016 the first headset for everyone finally comes out.",
        "original_translation": "In 2014 Facebook buys Oculus for 2 billion dollars and in 2016 the first headset for everyone finally comes out.",
        "reference_translation": "",
        "original_translation_reference": "",
        "target_duration": 8.92,
        "original_text_duration": 5.261669921875,
        "duration": 5.261669921875,
        "mapped": true,
        "text_trans": "In 2014, Facebook buys Oculus for 2 billion dollars and in 2016 finally releases the first virtual reality headset for everyone."
    },
    {
        "text": " L'Oculus Rift io mi ricordo lo provai e rimasi impressionato ai tempi.",
        "start": 54.55,
        "end": 58.85,
        "speaker": "A",
        "id": "4abe852",
        "audio_final": [
            "https://speakmultiassets.s3.amazonaws.com/user-data/cdaf80e6-cdb4-442e-ba31-72b354783b16/98447cd0-65d6-46e3-96b6-9da9b5a73c98/4abe852/low_audio_seg_output_1.wav"
        ],
        "audio_final_durations": [
            4.32
        ],
        "audio_final_index": 0,
        "audio_generations": [
            "https://speakmultiassets.s3.amazonaws.com/user-data/cdaf80e6-cdb4-442e-ba31-72b354783b16/98447cd0-65d6-46e3-96b6-9da9b5a73c98/4abe852/desilenced_1.wav"
        ],
        "audio_generations_durations": [
            2.92
        ],
        "translation": "I remember trying the Oculus Rift and I was impressed at the time.",
        "original_translation": "I remember trying the Oculus Rift and I was impressed at the time.",
        "reference_translation": "",
        "original_translation_reference": "",
        "target_duration": 4.3,
        "original_text_duration": 3.117483642578125,
        "duration": 3.117483642578125,
        "mapped": true,
        "text_trans": null
    },
    {
        "text": " Parallelamente anche quello di HTC. In tutto ciò parallelamente nel 2013 Google presenta i Google Glass.",
        "start": 59.09,
        "end": 65.36,
        "speaker": "A",
        "id": "06996fa",
        "audio_final": [
            "https://speakmultiassets.s3.amazonaws.com/user-data/cdaf80e6-cdb4-442e-ba31-72b354783b16/98447cd0-65d6-46e3-96b6-9da9b5a73c98/06996fa/low_audio_seg_output_1.wav"
        ],
        "audio_final_durations": [
            6.29
        ],
        "audio_final_index": 0,
        "audio_generations": [
            "https://speakmultiassets.s3.amazonaws.com/user-data/cdaf80e6-cdb4-442e-ba31-72b354783b16/98447cd0-65d6-46e3-96b6-9da9b5a73c98/06996fa/desilenced_1.wav"
        ],
        "audio_generations_durations": [
            6.14
        ],
        "translation": "At the same time also that of HTC. In all this, in parallel in 2013, Google presents the Google Glass.",
        "original_translation": "At the same time also that of HTC. In all this, in parallel in 2013, Google presents the Google Glass.",
        "reference_translation": "",
        "original_translation_reference": "",
        "target_duration": 6.27,
        "original_text_duration": 4.82920654296875,
        "duration": 4.82920654296875,
        "mapped": true,
        "text_trans": "Parallel to that, also HTC's. In all this, parallel to that, in 2013 Google presents the Google Glass."
    },
    {
        "text": " Questi occhiali con un piccolo prisma che faceva delle proiezioni, qua parliamo di realtà aumentata, quindi vedi il mondo normalmente attraverso gli occhiali trasparenti ma nello schermino.",
        "start": 65.64,
        "end": 73.53,
        "speaker": "A",
        "id": "e1374b4",
        "audio_final": [
            "https://speakmultiassets.s3.amazonaws.com/user-data/cdaf80e6-cdb4-442e-ba31-72b354783b16/98447cd0-65d6-46e3-96b6-9da9b5a73c98/e1374b4/low_audio_seg_output_1.wav"
        ],
        "audio_final_durations": [
            7.88
        ],
        "audio_final_index": 0,
        "audio_generations": [
            "https://speakmultiassets.s3.amazonaws.com/user-data/cdaf80e6-cdb4-442e-ba31-72b354783b16/98447cd0-65d6-46e3-96b6-9da9b5a73c98/e1374b4/desilenced_1.wav"
        ],
        "audio_generations_durations": [
            8.91
        ],
        "translation": "These glasses, with a small prism that made projections, here we are talking about augmented reality, so you see the world normally through the transparent glasses but on the small screen.",
        "original_translation": "These glasses, with a small prism that made projections, here we are talking about augmented reality, so you see the world normally through the transparent glasses but on the small screen.",
        "reference_translation": "",
        "original_translation_reference": "",
        "target_duration": 7.89,
        "original_text_duration": 8.8989775390625,
        "duration": 8.8989775390625,
        "mapped": true,
        "text_trans": "These glasses with a small prism that made projections, here we are talking about augmented reality, so you see the world normally through the transparent glasses but on the small screen."
    }
]
export const speaker_legend = [{"speaker":"A","audio":"https://speakmultiassets.s3.amazonaws.com/segment_0.mp3"},{"speaker":"B","audio":"https://speakmultiassets.s3.amazonaws.com/segment_0.mp3"},{"speaker":"C","audio":"https://speakmultiassets.s3.amazonaws.com/segment_0.mp3"},{"speaker":"D","audio":"https://speakmultiassets.s3.amazonaws.com/segment_0.mp3"},{"speaker":"emotion","audio":"https://speakmultiassets.s3.amazonaws.com/segment_0.mp3"}]
export const speaker_colors = [
    "#FF6B6B", // Bright Red
    "#FFD64D", // Bright Yellow
    "#4DFF92", // Bright Green
    "#4DE0FF", // Bright Cyan
    "#B94DFF", // Bright Purple
    "#FF914D", // Bright Orange
    "#4D89FF", // Bright Blue
    "#FF4DA6", // Bright Magenta
    "#4DFFC3", // Aqua Green
    "#FFB14D", // Gold
    "#A24DFF", // Violet
    "#FF4D4D", // Strong Red
    "#4DFFDB", // Teal
    "#7E4DFF", // Lavender
    "#FF634D", // Tomato Red
    "#4DFF58", // Lime
    "#FF4D77", // Rose Pink
    "#4DFFFF", // Bright Turquoise
    "#FF4D4D", // Deep Orange
    "#524DFF", // Royal Blue
    "#FF6E4D", // Salmon
    "#70FF4D", // Neon Green
    "#D14DFF", // Bright Lilac
    "#FF4D9E", // Hot Pink
    "#6A4DFF", // Indigo
    "#FF984D"  // Tangerine
];

export const system_status = "audio_generated_waiting"

export const all_breakpoints = {
    "audio":{
    "1":[
        13.4667,
        17.0333,
        30.1333,
        31.9667,
        39.1,
        41.2,
        43.2333,
        44.6,
        46.6,
        47.8333,
        61.2333,
        64.2333,
        65.2333,
        67.3,
        71.7,
        73.7,
        78.8333,
        86.5333],
    
        "2":[
            13.4667,
            17.0333,
            30.1333,
            43.2333,
            44.6,
            46.6,
            47.8333,
            61.2333,
            64.2333,
            65.2333,
            67.3,
            78.8333,
            86.5333],
        "3":[
            13.4667,
            30.1333,
            31.9667,
            39.1,
            41.2,
            43.2333,
            44.6,
            46.6,
            47.8333,
            65.2333,
            67.3,
            71.7,
            73.7,
            78.8333,
            86.5333],
        "4":[
            13.4667,
            31.9667,
            39.1,
            41.2,
            43.2333,
            47.8333,
            61.2333,
            64.2333,
            65.2333,
            67.3,
            71.7,
            73.7,
            78.8333,
            86.5333]
        },
        "video":{
            "1":[
                13.4667,
                17.0333,
                30.1333,
                31.9667,
                46.6,
                47.8333,
                61.2333,
                64.2333,
                67.3,
                71.7,
                73.7,
                78.8333,
                86.5333],
            
                "2":[
                    13.4667,
                    43.2333,
                    44.6,
                    46.6,
                    47.8333,
                    61.2333,
                    64.2333,
                    67.3,
                    78.8333,
                    86.5333],
                "3":[
                    39.1,
                    41.2,
                    43.2333,
                    44.6,
                    46.6,
                    47.8333,
                    67.3,
                    71.7,
                    73.7,
                    78.8333,
                    86.5333],
                "4":[
                    13.4667,
                    31.9667,
                    47.8333,
                    61.2333,
                    64.2333,
                    67.3,
                    71.7,
                    73.7,
                    78.8333,
                    86.5333]

        }
}


export const speaker_mapping_data = [
    {
        "category":"default",
        "voice_id":"default",
        "name":"default",
        "tags":[],
        "preview":""
    },
    {
      "category": "premade",
      "voice_id": "21m00Tcm4TlvDq8ikWAM",
      "name": "Rachel",
      "tags": [
        "american",
        "calm",
        "young",
        "female",
        "narration"
      ],
      "preview": "https://storage.googleapis.com/eleven-public-prod/premade/voices/21m00Tcm4TlvDq8ikWAM/df6788f9-5c96-470d-8312-aab3b3d8f50a.mp3"
    },
    {
      "category": "premade",
      "voice_id": "2EiwWnXFnvU5JabPnv8n",
      "name": "Clyde",
      "tags": [
        "american",
        "war veteran",
        "middle aged",
        "male",
        "video games"
      ],
      "preview": "https://storage.googleapis.com/eleven-public-prod/premade/voices/2EiwWnXFnvU5JabPnv8n/65d80f52-703f-4cae-a91d-75d4e200ed02.mp3"
    },
    {
      "category": "premade",
      "voice_id": "AZnzlk1XvdvUeBnXmlld",
      "name": "Domi",
      "tags": [
        "american",
        "strong",
        "young",
        "female",
        "narration"
      ],
      "preview": "https://storage.googleapis.com/eleven-public-prod/premade/voices/AZnzlk1XvdvUeBnXmlld/508e12d0-a7f7-4d86-a0d3-f3884ff353ed.mp3"
    },
    {
      "category": "premade",
      "voice_id": "CYw3kZ02Hs0563khs1Fj",
      "name": "Dave",
      "tags": [
        "british-essex",
        "conversational",
        "young",
        "male",
        "video games"
      ],
      "preview": "https://storage.googleapis.com/eleven-public-prod/premade/voices/CYw3kZ02Hs0563khs1Fj/872cb056-45d3-419e-b5c6-de2b387a93a0.mp3"
    },
    {
      "category": "premade",
      "voice_id": "D38z5RcWu1voky8WS1ja",
      "name": "Fin",
      "tags": [
        "irish",
        "sailor",
        "old",
        "male",
        "video games"
      ],
      "preview": "https://storage.googleapis.com/eleven-public-prod/premade/voices/D38z5RcWu1voky8WS1ja/a470ba64-1e72-46d9-ba9d-030c4155e2d2.mp3"
    },
    {
      "category": "premade",
      "voice_id": "EXAVITQu4vr4xnSDxMaL",
      "name": "Bella",
      "tags": [
        "american",
        "soft",
        "young",
        "female",
        "narration"
      ],
      "preview": "https://storage.googleapis.com/eleven-public-prod/premade/voices/EXAVITQu4vr4xnSDxMaL/941b779e-c2ad-48d4-bddb-28d1a68fa27e.mp3"
    },
    {
      "category": "premade",
      "voice_id": "ErXwobaYiN019PkySvjV",
      "name": "Antoni",
      "tags": [
        "american",
        "well-rounded",
        "young",
        "male",
        "narration"
      ],
      "preview": "https://storage.googleapis.com/eleven-public-prod/premade/voices/ErXwobaYiN019PkySvjV/ee9ac367-91ee-4a56-818a-2bd1a9dbe83a.mp3"
    },
    {
      "category": "premade",
      "voice_id": "GBv7mTt0atIp3Br8iCZE",
      "name": "Thomas",
      "tags": [
        "american",
        "calm",
        "young",
        "male",
        "meditation"
      ],
      "preview": "https://storage.googleapis.com/eleven-public-prod/premade/voices/GBv7mTt0atIp3Br8iCZE/98542988-5267-4148-9a9e-baa8c4f14644.mp3"
    },
    {
      "category": "premade",
      "voice_id": "IKne3meq5aSn9XLyUdCD",
      "name": "Charlie",
      "tags": [
        "australian",
        "casual",
        "middle aged",
        "male",
        "conversational"
      ],
      "preview": "https://storage.googleapis.com/eleven-public-prod/premade/voices/IKne3meq5aSn9XLyUdCD/102de6f2-22ed-43e0-a1f1-111fa75c5481.mp3"
    },
    {
      "category": "premade",
      "voice_id": "LcfcDJNUP1GQjkzn1xUU",
      "name": "Emily",
      "tags": [
        "american",
        "calm",
        "young",
        "female",
        "meditation"
      ],
      "preview": "https://storage.googleapis.com/eleven-public-prod/premade/voices/LcfcDJNUP1GQjkzn1xUU/e4b994b7-9713-4238-84f3-add8fccaaccd.mp3"
    },
    {
      "category": "premade",
      "voice_id": "MF3mGyEYCl7XYWbV9V6O",
      "name": "Elli",
      "tags": [
        "american",
        "emotional",
        "young",
        "female",
        "narration"
      ],
      "preview": "https://storage.googleapis.com/eleven-public-prod/premade/voices/MF3mGyEYCl7XYWbV9V6O/d8ecadea-9e48-4e5d-868a-2ec3d7397861.mp3"
    },
    {
      "category": "premade",
      "voice_id": "N2lVS1w4EtoT3dr4eOWO",
      "name": "Callum",
      "tags": [
        "american",
        "hoarse",
        "middle aged",
        "male",
        "video games"
      ],
      "preview": "https://storage.googleapis.com/eleven-public-prod/premade/voices/N2lVS1w4EtoT3dr4eOWO/ac833bd8-ffda-4938-9ebc-b0f99ca25481.mp3"
    },
    {
      "category": "premade",
      "voice_id": "ODq5zmih8GrVes37Dizd",
      "name": "Patrick",
      "tags": [
        "american",
        "shouty",
        "middle aged",
        "male",
        "video games"
      ],
      "preview": "https://storage.googleapis.com/eleven-public-prod/premade/voices/ODq5zmih8GrVes37Dizd/0ebec87a-2569-4976-9ea5-0170854411a9.mp3"
    },
    {
      "category": "premade",
      "voice_id": "SOYHLrjzK2X1ezoPC6cr",
      "name": "Harry",
      "tags": [
        "american",
        "anxious",
        "young",
        "male",
        "video games"
      ],
      "preview": "https://storage.googleapis.com/eleven-public-prod/premade/voices/SOYHLrjzK2X1ezoPC6cr/86d178f6-f4b6-4e0e-85be-3de19f490794.mp3"
    },
    {
      "category": "premade",
      "voice_id": "TX3LPaxmHKxFdv7VOQHJ",
      "name": "Liam",
      "tags": [
        "american",
        "young",
        "male",
        "narration",
        "neutral"
      ],
      "preview": "https://storage.googleapis.com/eleven-public-prod/premade/voices/TX3LPaxmHKxFdv7VOQHJ/63148076-6363-42db-aea8-31424308b92c.mp3"
    },
    {
      "category": "premade",
      "voice_id": "ThT5KcBeYPX3keUQqHPh",
      "name": "Dorothy",
      "tags": [
        "british",
        "pleasant",
        "young",
        "female",
        "children's stories"
      ],
      "preview": "https://storage.googleapis.com/eleven-public-prod/premade/voices/ThT5KcBeYPX3keUQqHPh/981f0855-6598-48d2-9f8f-b6d92fbbe3fc.mp3"
    },
    {
      "category": "premade",
      "voice_id": "TxGEqnHWrfWFTfGW9XjX",
      "name": "Josh",
      "tags": [
        "american",
        "deep",
        "young",
        "male",
        "narration"
      ],
      "preview": "https://storage.googleapis.com/eleven-public-prod/premade/voices/TxGEqnHWrfWFTfGW9XjX/3ae2fc71-d5f9-4769-bb71-2a43633cd186.mp3"
    },
    {
      "category": "premade",
      "voice_id": "VR6AewLTigWG4xSOukaG",
      "name": "Arnold",
      "tags": [
        "american",
        "crisp",
        "middle aged",
        "male",
        "narration"
      ],
      "preview": "https://storage.googleapis.com/eleven-public-prod/premade/voices/VR6AewLTigWG4xSOukaG/316050b7-c4e0-48de-acf9-a882bb7fc43b.mp3"
    },
    {
      "category": "premade",
      "voice_id": "XB0fDUnXU5powFXDhCwa",
      "name": "Charlotte",
      "tags": [
        "english-swedish",
        "seductive",
        "middle aged",
        "female",
        "video games"
      ],
      "preview": "https://storage.googleapis.com/eleven-public-prod/premade/voices/XB0fDUnXU5powFXDhCwa/942356dc-f10d-4d89-bda5-4f8505ee038b.mp3"
    },
    {
      "category": "premade",
      "voice_id": "XrExE9yKIg1WjnnlVkGX",
      "name": "Matilda",
      "tags": [
        "american",
        "warm",
        "young",
        "female",
        "audiobook"
      ],
      "preview": "https://storage.googleapis.com/eleven-public-prod/premade/voices/XrExE9yKIg1WjnnlVkGX/b930e18d-6b4d-466e-bab2-0ae97c6d8535.mp3"
    },
    {
      "category": "premade",
      "voice_id": "Yko7PKHZNXotIFUBG7I9",
      "name": "Matthew",
      "tags": [
        "british",
        "middle aged",
        "male",
        "audiobook",
        "calm"
      ],
      "preview": "https://storage.googleapis.com/eleven-public-prod/premade/voices/Yko7PKHZNXotIFUBG7I9/02c66c93-a237-436f-8a7d-43e8c49bc6a3.mp3"
    },
    {
      "category": "premade",
      "voice_id": "ZQe5CZNOzWyzPSCn5a3c",
      "name": "James",
      "tags": [
        "australian",
        "calm ",
        "old",
        "male",
        "news"
      ],
      "preview": "https://storage.googleapis.com/eleven-public-prod/premade/voices/ZQe5CZNOzWyzPSCn5a3c/35734112-7b72-48df-bc2f-64d5ab2f791b.mp3"
    },
    {
      "category": "premade",
      "voice_id": "Zlb1dXrM653N07WRdFW3",
      "name": "Joseph",
      "tags": [
        "british",
        "middle aged",
        "male",
        "news",
        "ground reporter "
      ],
      "preview": "https://storage.googleapis.com/eleven-public-prod/premade/voices/Zlb1dXrM653N07WRdFW3/daa22039-8b09-4c65-b59f-c79c48646a72.mp3"
    },
    {
      "category": "premade",
      "voice_id": "bVMeCyTHy58xNoL34h3p",
      "name": "Jeremy",
      "tags": [
        "american-irish",
        "excited",
        "young",
        "male",
        "narration"
      ],
      "preview": "https://storage.googleapis.com/eleven-public-prod/premade/voices/bVMeCyTHy58xNoL34h3p/66c47d58-26fd-4b30-8a06-07952116a72c.mp3"
    },
    {
      "category": "premade",
      "voice_id": "flq6f7yk4E4fJM5XTYuZ",
      "name": "Michael",
      "tags": [
        "american",
        "old",
        "male",
        "audiobook",
        "orotund"
      ],
      "preview": "https://storage.googleapis.com/eleven-public-prod/premade/voices/flq6f7yk4E4fJM5XTYuZ/c6431a82-f7d2-4905-b8a4-a631960633d6.mp3"
    },
    {
      "category": "premade",
      "voice_id": "g5CIjZEefAph4nQFvHAz",
      "name": "Ethan",
      "tags": [
        "american",
        "young",
        "male",
        "ASMR",
        "whisper"
      ],
      "preview": "https://storage.googleapis.com/eleven-public-prod/premade/voices/g5CIjZEefAph4nQFvHAz/26acfa99-fdec-43b8-b2ee-e49e75a3ac16.mp3"
    },
    {
      "category": "premade",
      "voice_id": "jBpfuIE2acCO8z3wKNLl",
      "name": "Gigi",
      "tags": [
        "american",
        "childlish",
        "young",
        "female",
        "animation"
      ],
      "preview": "https://storage.googleapis.com/eleven-public-prod/premade/voices/jBpfuIE2acCO8z3wKNLl/3a7e4339-78fa-404e-8d10-c3ef5587935b.mp3"
    },
    {
      "category": "premade",
      "voice_id": "jsCqWAovK2LkecY7zXl4",
      "name": "Freya",
      "tags": [
        "american",
        "young",
        "female",
        "overhyped",
        "video games"
      ],
      "preview": "https://storage.googleapis.com/eleven-public-prod/premade/voices/jsCqWAovK2LkecY7zXl4/8e1f5240-556e-4fd5-892c-25df9ea3b593.mp3"
    },
    {
      "category": "premade",
      "voice_id": "oWAxZDx7w5VEj9dCyTzz",
      "name": "Grace",
      "tags": [
        "american-southern",
        "young",
        "female",
        "audiobook ",
        "gentle"
      ],
      "preview": "https://storage.googleapis.com/eleven-public-prod/premade/voices/oWAxZDx7w5VEj9dCyTzz/84a36d1c-e182-41a8-8c55-dbdd15cd6e72.mp3"
    },
    {
      "category": "premade",
      "voice_id": "onwK4e9ZLuTAKqWW03F9",
      "name": "Daniel",
      "tags": [
        "british",
        "deep",
        "middle aged",
        "male",
        "news presenter"
      ],
      "preview": "https://storage.googleapis.com/eleven-public-prod/premade/voices/onwK4e9ZLuTAKqWW03F9/7eee0236-1a72-4b86-b303-5dcadc007ba9.mp3"
    },
    {
      "category": "premade",
      "voice_id": "pMsXgVXv3BLzUgSXRplE",
      "name": "Serena",
      "tags": [
        "american",
        "pleasant",
        "middle aged",
        "female",
        "interactive"
      ],
      "preview": "https://storage.googleapis.com/eleven-public-prod/premade/voices/pMsXgVXv3BLzUgSXRplE/d61f18ed-e5b0-4d0b-a33c-5c6e7e33b053.mp3"
    },
    {
      "category": "premade",
      "voice_id": "pNInz6obpgDQGcFmaJgB",
      "name": "Adam",
      "tags": [
        "american",
        "deep",
        "middle aged",
        "male",
        "narration"
      ],
      "preview": "https://storage.googleapis.com/eleven-public-prod/premade/voices/pNInz6obpgDQGcFmaJgB/38a69695-2ca9-4b9e-b9ec-f07ced494a58.mp3"
    },
    {
      "category": "premade",
      "voice_id": "piTKgcLEGmPE4e6mEKli",
      "name": "Nicole",
      "tags": [
        "american",
        "whisper",
        "young",
        "female",
        "audiobook"
      ],
      "preview": "https://storage.googleapis.com/eleven-public-prod/premade/voices/piTKgcLEGmPE4e6mEKli/c269a54a-e2bc-44d0-bb46-4ed2666d6340.mp3"
    },
    {
      "category": "premade",
      "voice_id": "t0jbNlBVZ17f02VDIeMI",
      "name": "Jessie",
      "tags": [
        "american",
        "raspy ",
        "old",
        "male",
        "video games"
      ],
      "preview": "https://storage.googleapis.com/eleven-public-prod/premade/voices/t0jbNlBVZ17f02VDIeMI/e26939e3-61a4-4872-a41d-33922cfbdcdc.mp3"
    },
    {
      "category": "premade",
      "voice_id": "wViXBPUzp2ZZixB1xQuM",
      "name": "Ryan",
      "tags": [
        "Auto-Cloned Voice from samples for Jamy"
      ],
      "preview": "https://storage.googleapis.com/eleven-public-prod/premade/voices/wViXBPUzp2ZZixB1xQuM/4a82f749-889c-4097-85f0-a3826a28b1d8.mp3"
    },
    {
      "category": "premade",
      "voice_id": "yoZ06aMxZJJ28mfd3POQ",
      "name": "Sam",
      "tags": [
        "american",
        "raspy",
        "young",
        "male",
        "narration"
      ],
      "preview": "https://storage.googleapis.com/eleven-public-prod/premade/voices/yoZ06aMxZJJ28mfd3POQ/ac9d1c91-92ce-4b20-8cc2-3187a7da49ec.mp3"
    },
    {
      "category": "premade",
      "voice_id": "z9fAnlkpzviPz146aGWa",
      "name": "Glinda",
      "tags": [
        "american",
        "witch",
        "middle aged",
        "female",
        "video games"
      ],
      "preview": "https://storage.googleapis.com/eleven-public-prod/premade/voices/z9fAnlkpzviPz146aGWa/cbc60443-7b61-4ebb-b8e1-5c03237ea01d.mp3"
    },
    {
      "category": "premade",
      "voice_id": "zcAOhNBS3c14rBihAFp1",
      "name": "Giovanni",
      "tags": [
        "english-italian",
        "foreigner",
        "young",
        "male",
        "audiobook"
      ],
      "preview": "https://storage.googleapis.com/eleven-public-prod/premade/voices/zcAOhNBS3c14rBihAFp1/e7410f8f-4913-4cb8-8907-784abee5aff8.mp3"
    },
    {
      "category": "premade",
      "voice_id": "zrHiDhphv9ZnVXBqCLjz",
      "name": "Mimi",
      "tags": [
        "english-swedish",
        "childish",
        "young",
        "female",
        "animation"
      ],
      "preview": "https://storage.googleapis.com/eleven-public-prod/premade/voices/zrHiDhphv9ZnVXBqCLjz/decbf20b-0f57-4fac-985b-a4f0290ebfc4.mp3"
    },
    {
      "category": "cloned",
      "voice_id": "1hEsT5s6q7rqK9fI37iH",
      "name": "10f82bc9-2727-44ce-b930-2b538373885a_ec4cc1f0-38db-42e2-bc1e-a7544113118b_A",
      "preview": "https://storage.googleapis.com/eleven-public-prod/yfWOUnkHL8Uur2ieGzH2jBWPdgi1/voices/1hEsT5s6q7rqK9fI37iH/68e8eba7-327d-488d-af64-c9577f6f3b80.mp3"
    },
    {
      "category": "cloned",
      "voice_id": "6ZXtYnvDGwxpc50aDb0F",
      "name": "d757767e-095b-44e0-aa14-fa12892d3c16_e0f990fd-2ffa-44aa-afef-0ed71b7961e4_A",
      "preview": "https://storage.googleapis.com/eleven-public-prod/yfWOUnkHL8Uur2ieGzH2jBWPdgi1/voices/6ZXtYnvDGwxpc50aDb0F/09f41ef1-49e3-41d0-9e68-32230c57f056.mp3"
    },
    {
      "category": "cloned",
      "voice_id": "CzwB9ACN6j4yHMaEjPzr",
      "name": "10f82bc9-2727-44ce-b930-2b538373885a_a135a49a-45c4-46f1-89e7-05c7b6c8125b_A",
      "preview": "https://storage.googleapis.com/eleven-public-prod/yfWOUnkHL8Uur2ieGzH2jBWPdgi1/voices/CzwB9ACN6j4yHMaEjPzr/cf4cfd59-fe2f-4f33-bb0c-41bc6fd43ad2.mp3"
    },
    {
      "category": "cloned",
      "voice_id": "I1fwxt5nFXGm3AAvnRwu",
      "name": "10f82bc9-2727-44ce-b930-2b538373885a_3636ada6-2f9a-4b00-81ac-94711a606427_A",
      "preview": "https://storage.googleapis.com/eleven-public-prod/yfWOUnkHL8Uur2ieGzH2jBWPdgi1/voices/I1fwxt5nFXGm3AAvnRwu/4a7c96b9-be17-4e87-b784-0d7405007102.mp3"
    },
    {
      "category": "cloned",
      "voice_id": "K15cgseBYECrrG1kl5mK",
      "name": "10f82bc9-2727-44ce-b930-2b538373885a_5f68a8b0-ff88-48c6-9265-7786bb26a283_A",
      "preview": "https://storage.googleapis.com/eleven-public-prod/yfWOUnkHL8Uur2ieGzH2jBWPdgi1/voices/K15cgseBYECrrG1kl5mK/be596569-4c9d-4553-92c0-8e83e784b284.mp3"
    },
    {
      "category": "cloned",
      "voice_id": "YjA1H5tNmA3agtQidErW",
      "name": "10f82bc9-2727-44ce-b930-2b538373885a_5db8a446-f0a4-4ce2-9cf0-a8e787d71368_A",
      "preview": "https://storage.googleapis.com/eleven-public-prod/yfWOUnkHL8Uur2ieGzH2jBWPdgi1/voices/YjA1H5tNmA3agtQidErW/07691f5d-32e5-4b74-a7e1-db21769ca92d.mp3"
    },
    {
      "category": "cloned",
      "voice_id": "hVv6FQDyXTKCmPkMmZtJ",
      "name": "10f82bc9-2727-44ce-b930-2b538373885a_29f92f99-fbbe-4e89-94da-987a16aef390_A",
      "preview": "https://storage.googleapis.com/eleven-public-prod/yfWOUnkHL8Uur2ieGzH2jBWPdgi1/voices/hVv6FQDyXTKCmPkMmZtJ/197f2ea5-55f4-46ff-9a0d-6cc3527718e1.mp3"
    },
    {
      "category": "cloned",
      "voice_id": "nLrjij07H2sA1etiporV",
      "name": "10f82bc9-2727-44ce-b930-2b538373885a_a92bb3be-3985-4787-adde-7725f3fccacb_A",
      "preview": "https://storage.googleapis.com/eleven-public-prod/yfWOUnkHL8Uur2ieGzH2jBWPdgi1/voices/nLrjij07H2sA1etiporV/6be7c0cd-d9a1-4a1f-9c25-d2d60cc7b3f1.mp3"
    },
    {
      "category": "cloned",
      "voice_id": "rOmrBsEKf8CBlG38u2ys",
      "name": "10f82bc9-2727-44ce-b930-2b538373885a_e8ae612b-dab0-4138-bf3f-d2900531fb07_A",
      "preview": "https://storage.googleapis.com/eleven-public-prod/yfWOUnkHL8Uur2ieGzH2jBWPdgi1/voices/rOmrBsEKf8CBlG38u2ys/6eb42dd7-37b6-41de-8282-b985b9a037fe.mp3"
    },
    {
      "category": "cloned",
      "voice_id": "wasfHkE77sTPaXel2ICE",
      "name": "10f82bc9-2727-44ce-b930-2b538373885a_7761b855-260d-4d59-ba71-117e59136855_A",
      "preview": "https://storage.googleapis.com/eleven-public-prod/yfWOUnkHL8Uur2ieGzH2jBWPdgi1/voices/wasfHkE77sTPaXel2ICE/06d64a5a-fdaa-49b0-a4a4-017792f4521d.mp3"
    },
    {
      "category": "cloned",
      "voice_id": "xheXaq3tErqhhJfwBkaL",
      "name": "10f82bc9-2727-44ce-b930-2b538373885a_cd94addb-4c89-4f62-ad08-229d98b3081e_A",
      "preview": "https://storage.googleapis.com/eleven-public-prod/yfWOUnkHL8Uur2ieGzH2jBWPdgi1/voices/xheXaq3tErqhhJfwBkaL/535db585-71bd-4bd3-bd9c-ccd20a18f708.mp3"
    },
  ]


  export const speaker_mapping = [
    {'speaker':'A','voice_name':'default'}
  ]

