import { IconButton, LinearProgress, Typography } from "@mui/material";
import React, { useEffect } from "react";

import CloseIcon from "@mui/icons-material/Close";
import NearMeIcon from "@mui/icons-material/NearMe";
import axios from "axios";
import { useNavigate } from "react-router-dom";

const RecommendationSet = ({
  text,
  segmentIndex,
  updateTranslationFromRecommendation,
  closeRecommendationModal,
  recommendationTranslation
}) => {
  return (
    <div style={{ display: "flex", marginTop: 20 }}>
      <Typography style={{ color: "white", textAlign: "center" }}>
        {text + "(" + recommendationTranslation + ")"}
      </Typography>
      <IconButton
        style={{ marginLeft: 20 }}
        onClick={() => {
          updateTranslationFromRecommendation(segmentIndex, text);
          closeRecommendationModal();
        }}
        disableRipple={true}
      >
        <NearMeIcon fontSize="medium" color="white" />
      </IconButton>
    </div>
  );
};

const RecommendationModal = ({
  segmentIndex,
  updateTranslationFromRecommendation,
  closeRecommendationModal,
  originalText,
  selectedVideoId,
  alterContext,
  pageNumber
}) => {
  const navigate = useNavigate();
  const [recommendations, setRecommendations] = React.useState(null);
  const [loading, setLoading] = React.useState(true);
  const [error, setError] = React.useState(null);
  const [recommendationTranslation,setRecommendationTranslation] = React.useState(null)

  const getRecommendations = async () => {
    setLoading(true);
    try {
      const response = await axios.get(
        "https://apx.translatetracks.com/generate_recommendations",
        // `${process.env.REACT_APP_API_URL}/get_recommendations`,
        {
          headers: {
            token: localStorage.getItem("token"),
          },
          params: {
            // segment_id:segmentIndex ,
            segment_id:(pageNumber - 1)*10 + segmentIndex,
            session_id:selectedVideoId,
            type:alterContext
          },
        }
      );
      console.log(response.data);
      if (response.data.status === 0) {
        setLoading(false);
        setRecommendations(response.data.data);
        setRecommendationTranslation(response.data.english_translation)
      } else if (response.data.status === -1) {
        localStorage.removeItem("token");
        localStorage.removeItem("email");
        navigate("/auth");
      } else {
        setLoading(false);
        setError("Some error occured! Please try again!");
      }
    } catch (err) {
      console.log(err);
      setLoading(false);
      setError("Some error occured! Please try again!");
    }
  };

  useEffect(() => {
    getRecommendations();
  },[])

  if (error) {
    return (
      <Typography
        style={{
          color: "red",
          fontSize: 14,
          textAlign: "center",
          marginTop: 5,
        }}
      >
        {error}
      </Typography>
    );
  } else if (loading || !recommendations) {
    return (
      <div style={{ margin: "auto", width: "20%" }}>
        <LinearProgress style={{ marginTop: 20 }} />
      </div>
    );
  } else {
    return (
      <React.Fragment>
        <div style={{display:"flex",flexDirection:"row"}}>
    <div style={{flex:1}}/>
    <IconButton
        onClick={closeRecommendationModal}
        style={{ padding: 0, alignSelf: "flex-start" }}
        >
        <CloseIcon style={{ color: "white" }} fontSize="small" />
    </IconButton>
    </div>
        <Typography
          style={{
            color: "white",
            textAlign: "center",
            fontSize: 20,
            marginBottom: 20,
          }}
        >
          Recommendations
        </Typography>
        {recommendations.map((recommendation, index) => (
            <RecommendationSet
                key={index}
                text={recommendation}
                segmentIndex={segmentIndex}
                updateTranslationFromRecommendation={
                    updateTranslationFromRecommendation
                }
                closeRecommendationModal={closeRecommendationModal}
                recommendationTranslation={recommendationTranslation.length !== 0 ? recommendationTranslation[index] : "" }
            />
        ))}
      </React.Fragment>
    );
  }
};

export default RecommendationModal;
