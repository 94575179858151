import "../styles/Player.css";

import ReactPlayer from "react-player/youtube";
import { Typography } from "@mui/material";

const VideoGroup = ({ url, flagImage, language, containerStyle,thumbnail }) => {
  return (
    <div
      style={{ display: "flex", flexDirection: "column",marginTop:20, ...containerStyle }}
    >
      <ReactPlayer url={url} 
                   controls={true} 
                    light={thumbnail ? thumbnail : true} 
                    width={"80%"} 
                    className="player" 
                    style={{"alignSelf":"center",marginBottom:10,minWidth:100}} 
                    playIcon={<img src={flagImage} alt="flag" style={{ width: 80,alignSelf:"center",marginBottom:10}}/>}
                    playing={true}
                    />
      <Typography variant="h5" style={{ color: "white","alignSelf":"center",fontSize:15,fontFamily:"DM Sans" }}>
        {language}
      </Typography>
    </div>
  );
};

export default VideoGroup;
