import {
  CircularProgress,
  IconButton,
  LinearProgress,
  Tooltip,
  Typography,
} from "@mui/material";

import AudPlayer from "./AudPlayer";
import React from "react";
import RefreshIcon from "@mui/icons-material/Refresh";

const AudioGroup = ({
  src,
  left,
  index,
  video_id,
  onRegenerateClick,
  regenerateLoading,
  regenerateError,
  regenerateDisabled
}) => {
  const [audioDuration,setAudioDuration] = React.useState(null)
  return (
    <div
      style={{
        display: "flex",
        alignSelf: "center",
        width: "80%",
        margin: "auto",
      }}
    >
      <AudPlayer src={src} setAudioDuration={setAudioDuration}/>
      <Typography style={{color: "white",paddingTop:9}}>{"(" + Math.round(audioDuration * 100) / 100 + ")"}</Typography>
      <div style={{ display: "flex", flexDirection: "column" }}>
        {regenerateLoading[index] ? (
          <CircularProgress color="secondary" />
        ) : (
          <React.Fragment>
            <Tooltip title={regenerateDisabled ? "You have used up all your characters!" : "Regenerate audio segment"}>
              <IconButton
                // disabled={left === 0}
                disabled={regenerateDisabled}
                onClick={() => onRegenerateClick(index, video_id)}  
              >
                <RefreshIcon fontSize="large" />
              </IconButton>
            </Tooltip>
            {/* <Tooltip title={"You can only regenerate once!"}>
              <Typography style={{ color: "white", textAlign: "center" }}>
                {left + "/1"}
              </Typography>
            </Tooltip> */}
          </React.Fragment>
        )}
      </div>
    </div>
  );
};

export default AudioGroup;
