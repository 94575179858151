import Checkbox from '@mui/material/Checkbox';
import Typography from '@mui/material/Typography';

//component with checkbox and text of kind "I accept the terms and conditions"
const ApprovalTextCheck = ({text,checked,onCheckChange}) => {
    return (
        <div style={{ display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center", marginTop: 10 }}>
            <Checkbox checked={checked} onChange={onCheckChange}/>
            <Typography style={{alignSelf:"center",color:"#afb8c1"}}>{text}</Typography>
        </div>
    )

}

export default ApprovalTextCheck;