import React from "react";
import { IconButton, Tooltip } from "@mui/material";
import PlayCircleIcon from "@mui/icons-material/PlayCircle";
import PauseCircleIcon from "@mui/icons-material/PauseCircle";
import VolumeUpIcon from "@mui/icons-material/VolumeUp";
import VolumeOffIcon from "@mui/icons-material/VolumeOff";
import CircularProgress from "@mui/material/CircularProgress";
import VolumeUp from '@mui/icons-material/VolumeUp';
import Slider from '@mui/material/Slider';



const PlayerControls = ({ isMute, isBuffering, playing, togglePlaying, toggleMute, hide, handleBgVolumeChange, bgVolume }) => {
  return (
    <div
      className="controls"
      style={{ display: hide ? "none" : "flex", alignSelf: "center", flexDirection: "row" }}
    >
      <IconButton onClick={togglePlaying}>
        {isBuffering ? (
          <CircularProgress style={{ color: "white" }} size={20} />
        ) : playing ? (
          <PauseCircleIcon style={{ color: "white" }} fontSize="medium" />
        ) : (
          <PlayCircleIcon style={{ color: "white" }} fontSize="medium" />
        )}
      </IconButton>
      <IconButton onClick={toggleMute}>
        {isMute ? (
          <VolumeOffIcon style={{ color: "white" }} fontSize="medium" />
        ) : (
          <VolumeUpIcon style={{ color: "white" }} fontSize="medium" />
        )}
      </IconButton>
      {bgVolume !== null && handleBgVolumeChange !== null &&  <Tooltip title="Background Track Volume">
        <div style={{ width: 100, display: "flex", flexDirection: "row", alignSelf: "center", marginLeft: 20 }}>
          <VolumeUp style={{ color: "white", alignSelf: "center",marginRight:5 }} fontSize="medium" />
          <Slider aria-label="Volume" value={bgVolume} min={0} max={1} size="small" step={0.1} onChange={handleBgVolumeChange} />
        </div>
      </Tooltip>}


    </div>
  )
}

export default PlayerControls;