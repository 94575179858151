import { Button, IconButton, Typography } from "@mui/material"
import CloseIcon from "@mui/icons-material/Close";


const IssueSet = ({issue_text,issue_id,scrollToSegmentId,setCurrentSegmentId,closeIssuesModal}) => {
    return(
        <div style={{display:"flex",justifyContent:"space-around"}}>
            <Typography style={{ color: "#afb8c1", 
            alignSelf: "center"}}>{issue_text}</Typography>
            <Button type="button" variant="text" color="success" onClick={() => {
                scrollToSegmentId(issue_id,setCurrentSegmentId)
                closeIssuesModal()
                }}>Resolve</Button>
        </div>
    )
}

const IssuesModal = ({closeIssuesModal,issues,scrollToSegmentId,setCurrentSegmentId}) => {
    return (
        <div>
            <div style={{ display: "flex", flexDirection: "row" }}>
                <div style={{ flex: 1 }} />
                <IconButton
                    onClick={closeIssuesModal}
                    style={{ padding: 0, alignSelf: "flex-start" }}
                >
                    <CloseIcon style={{ color: "white" }} fontSize="small" />
                </IconButton>
            </div>
            {/* <Typography>Issues Modal</Typography> */}
            {issues.map((issue,index) => (
                <IssueSet key={index} 
                    issue_text={issue.text} 
                    issue_id={issue.id}
                    scrollToSegmentId={scrollToSegmentId}
                    setCurrentSegmentId={setCurrentSegmentId}
                    closeIssuesModal={closeIssuesModal}/>
            ))}
        </div>
    )
}

export default IssuesModal;