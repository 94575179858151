import { Typography,CircularProgress } from "@mui/material"

const DownloadInitiator = ({text,loading}) => {

    if(loading){
        return(
            <div style={{display:"flex",flexDirection:"row",alignItems:"center"}}>
                <CircularProgress style={{alignSelf:"center"}} size={20}/>
            </div>
        )
    }else
    return(
        
        <Typography>{text}</Typography>
    )
}

export default DownloadInitiator;