import React from 'react';

function TimelineSvg({ lineSpacing }) {
  const numLines = Math.floor(800 / lineSpacing); // Assuming viewBox width is 100
  let d = '';
  for (let i = 0; i < numLines; i++) {
    const x = i * lineSpacing;
    d += `M ${x} 0 L${x} 4 `;
  }

  const svgStyle = {
    // width: '75%' // Set the width to be 75% of its parent
    width:800,
    height:32
  };

  return (
    <svg style={svgStyle} viewBox="0 0 700 32" xmlns="http://www.w3.org/2000/svg" aria-hidden="true" focusable="false">
      <path fillRule="evenodd" stroke="white" strokeWidth="2" d={d.trim()} />
    </svg>
  );
}

export default TimelineSvg;
