import { useState } from 'react';
import axios from 'axios';
import { useNavigate } from "react-router-dom";

const useApi = (url) => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  const fetchData = async (method = 'GET', body = null, params = {},token=null) => {
    setLoading(true);
    setError(null);
    const headers = {}
    if (token) {
      headers['Authorization'] = `Bearer ${token}`
    }

    try {
      let response;
      if (method === 'GET') {
        response = await axios.get(url, { params,headers });
      } else if (method === 'POST') {
        response = await axios.post(url, body,{headers});
      } else {
        throw new Error('Unsupported method type');
      }
      if(response.data.status === 0){
      setData(response.data);
    }else if(response.data.status === -1){
      localStorage.removeItem("token");
      localStorage.removeItem("email");
      navigate("/auth");
    }else{
      setError("Something went wrong!Please try again.")
    }
    } catch (err) {
      console.log(err);
      setError("Something went wrong!Please try again.");
    } finally {
      setLoading(false);
    }
  };

  return { data, loading, error, fetchData };
};

export default useApi;
