import { IconButton, Tooltip } from "@mui/material"
import CircularProgress from "@mui/material/CircularProgress"

const LoaderIconButton = ({ loading, ButtonType, onClick,title,styles,disabled }) => {
    return (
        <Tooltip title={title}>
        <IconButton onClick={onClick} style={styles} disabled={disabled} >
            {loading ? <CircularProgress size={20} /> :
                <ButtonType style={{ color: "white" }} fontSize="medium" />}
        </IconButton>
        </Tooltip>
    )
}

export default LoaderIconButton;