import { Button, IconButton, Typography } from "@mui/material";

import CloseIcon from "@mui/icons-material/Close";
import EmptyTextarea from "./EmptyTextArea";
import React from "react";

const ContextModal = ({closeContextModal,context,setContext,setVerificationInitiated}) => {
  return (
    <React.Fragment>
    <div style={{display:"flex",flexDirection:"row"}}>
    <div style={{flex:1}}/>
    <IconButton
        onClick={closeContextModal}
        style={{ padding: 0, alignSelf: "flex-start" }}
        >
        <CloseIcon style={{ color: "white" }} fontSize="small" />
    </IconButton>
    </div>
    <div style={{display:"flex",flexDirection:"column",}}>
      <Typography
        style={{
          color: "white",
          fontSize: 20,
          textAlign: "center",
          marginBottom: 10,
        }}
      >
        What is this video about?
      </Typography>{" "}
      <EmptyTextarea 
        style={{alignSelf:"center"}}
        placeholder="This video is commentry over a FIFA twitch stream..."
        maxLength={100}
        value={context}
        onChange = {(e) => setContext(e.target.value)}
        />
      <Button
        variant="contained"
        color="primary"
        style={{ alignSelf: "center", marginTop: 10 }}
        disabled={context.length === 0}
        onClick={() => setVerificationInitiated(true)}
        // onClick={() => setIsContextModalOpen(true)}
      >
        Translate!
      </Button>
    </div>
    </React.Fragment>
  );
};

export default ContextModal;
