import TimeLabel from "./TimeLabel";
import SvgTimeline from "./SvgTimeline";
import { findSegmentDetailsFromTime } from "../helpers/functions";
import BreakpointImage from "./BreakPointImage";

const Timeline = ({timelineWidth,startTime,endTime,
  currentTime,timelineRef,handleTimelineClick,
  handleBreakpointClick,selectedBreakpoint,breakpoints,
sliderValue,audioChecked,hide}) => {
    return (
        <div className="timeline" style={{
            display: hide ? "none" : "flex",
            alignSelf: "center",
            flexDirection: "row",
            position: "relative",
            marginTop: 5,
          }}
          ref={timelineRef}
          onClick={handleTimelineClick}
          >
          {breakpoints[audioChecked ? 'audio' : 'video'][sliderValue*10].map(breakpoint => 
            <BreakpointImage 
              breakpoint={breakpoint} 
              startTime={startTime} 
              endTime={endTime} 
              onBreakpointClick={(event) => handleBreakpointClick(event,breakpoint)}
              key={breakpoint} 
              selectedBreakpoint={selectedBreakpoint}
            />
          )}

          <TimeLabel time={startTime} isStartTime />
          <SvgTimeline lineSpacing={30} timelineWidth={timelineWidth}/>
          <TimeLabel time={endTime} isEndTime />
          <TimeLabel time={currentTime} startTime={startTime} endTime={endTime} isCurrentTime/>
          {/* Vertical line */}
          {/* ... */}
        </div>
      );
}

export default Timeline;