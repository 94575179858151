import SpeakerSelect from "./SpeakerSelect";
import LoaderIconButton from "./LoaderIconButton";
import TranslateIcon from "@mui/icons-material/Translate";
import Slider from '@mui/material/Slider';
import { FormControlLabel, Switch, Typography } from "@mui/material";
import AutoFixOffIcon from '@mui/icons-material/AutoFixOff';
import MergeIcon from '@mui/icons-material/Merge';
import RefreshIcon from "@mui/icons-material/Refresh";
import DeleteIcon from "@mui/icons-material/Delete";
import CallSplitIcon from "@mui/icons-material/CallSplit";
import LoopIcon from '@mui/icons-material/Loop';
import FitnessCenterIcon from '@mui/icons-material/FitnessCenter';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import ContentCutIcon from '@mui/icons-material/ContentCut';
import ExpandIcon from '@mui/icons-material/Expand';
import GenericSelect from "./GenericSelect";
import Forward5Icon from '@mui/icons-material/Forward5';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { useState } from "react";
import { can_segment_fit } from "../helpers/functions";
import DeviceHubIcon from '@mui/icons-material/DeviceHub';



const InferenceControls = ({ status, setSplitMode,
    handleDeleteClick, handleMergeClick, handleRetranscribeClick,
    retranscribeLoader,segment,handleMagicSplit,magicSplitLoader,
    handleRegenerateClick,regenrateLoader,handleRefitClick,refitLoader,
    handlePredictTimeClick,predictTimeLoader,handleTrimClick,
    audioChecked,sliderValue,setAudioChecked,setSliderValue,
    speakerLegend,handleSpeakerChange,segments,video_duration, setIsSpeakerModalOpen,
    selectedBreakpoint }) => {


    const [anchorEl,setAnchorEl] = useState(null)
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const open = Boolean(anchorEl);

    const handleClose = (configuration) => {
        setAnchorEl(null);
        console.log(typeof(configuration))
        typeof(configuration) === 'number' && handleRefitClick(configuration,segment.id)
      };
    

    // const speakerSoundDict = {
    //     "A": "A",
    //     "B": "B"
    // }

    return (
        <div style={{ display: "flex", flexDirection: "column", }}>
            <div style={{display:"flex"}}>
                <SpeakerSelect speakerLegend={speakerLegend} segment={segment} handleSpeakerChange={handleSpeakerChange}/>
                <LoaderIconButton loading={false} ButtonType={DeviceHubIcon} title={"Speaker Mapping"} onClick={() => setIsSpeakerModalOpen(true)} />
            </div>
            <div style={{ display: "flex", justifyContent: "center" }}>
                <LoaderIconButton loading={retranscribeLoader} ButtonType={LoopIcon} title={"Re-Transcribe"} onClick={() => handleRetranscribeClick(segment.start,segment.end,segment.id)} />
                <LoaderIconButton loading={false} ButtonType={DeleteIcon} title={"Delete Segment"} onClick={handleDeleteClick} />
                <div style={{display:status === 'tr_waiting' ? "flex" : "none",flexDirection:"column"}}>
                    <LoaderIconButton loading={predictTimeLoader} 
                                    ButtonType={AccessTimeIcon} title={"Predict Time"} 
                                    onClick={() => handlePredictTimeClick(segment.translation,segment.speaker)}
                                    styles={{paddingBottom:0}}/>
                    {segment.duration && <Typography style={{fontSize:12,textAlign:"center",color:"#afb8c1"}}>{segment.duration + "s"}</Typography>}
                </div>
                <LoaderIconButton loading={false} ButtonType={CallSplitIcon} title={"Split Segment"} onClick={() => setSplitMode(true)} />
                <LoaderIconButton loading={magicSplitLoader} ButtonType={AutoFixOffIcon} title={"Magic Split Segment"} onClick={handleMagicSplit} disabled={!selectedBreakpoint}/>
                <LoaderIconButton loading={false} ButtonType={MergeIcon} title={"Merge with the next segment"} onClick={handleMergeClick} />
            </div>
            <Slider
                aria-label="Temperature"
                defaultValue={sliderValue}
                valueLabelDisplay="auto"
                step={0.1}
                marks
                min={0.1}
                max={0.4}
                style={{ width: "80%", alignSelf: "center" }}
                onChange={(event,value) => setSliderValue(value)}
            />
            <FormControlLabel control={<Switch
                checked={audioChecked}
                onChange={(event) => setAudioChecked(event.target.checked)}
                inputProps={{ 'aria-label': 'controlled' }} />} label="Audio Bp" style={{ color: "white", margin: "auto" }} />

            <div style={{ display: status === "ag_waiting" ? "flex" : "none", flexDirection: "row", justifyContent: "center", flexWrap: "wrap" }}>
                <LoaderIconButton loading={regenrateLoader} ButtonType={RefreshIcon} title={"Regenerate Segment"} onClick={() => handleRegenerateClick(segment.id,1)} />
                <LoaderIconButton loading={refitLoader} ButtonType={FitnessCenterIcon} title={"Refit Segment"} onClick={handleClick}/>
                <div style={{display:"flex",flexDirection:"column"}}>
                    <LoaderIconButton loading={predictTimeLoader} 
                                    ButtonType={AccessTimeIcon} title={"Predict Time"} 
                                    onClick={() => handlePredictTimeClick(segment.translation,segment.speaker)}
                                    styles={{paddingBottom:0}}/>
                    {segment.duration && <Typography style={{fontSize:12,textAlign:"center",color:"#afb8c1"}}>{segment.duration + "s"}</Typography>}
                </div>
                <LoaderIconButton loading={false} ButtonType={ContentCutIcon} title={"Get a shorter Recommendation"} onClick={() => handleTrimClick('shorten')} />
                <LoaderIconButton loading={false} ButtonType={ExpandIcon} title={"Get a longer recommendation"} onClick={() => handleTrimClick('expand')}/>
                <LoaderIconButton loading={regenrateLoader} ButtonType={Forward5Icon} title={"Regenerate x3"} onClick={() => handleRegenerateClick(segment.id,3)}/>

            </div>
            {/* <div style={{ display: status === "audio_generated_waiting" ? "flex" : "none", justifyContent: "space-around", marginTop: 20 }}>
                <GenericSelect itemList={["A", "B"]} label={"Stability"} styles={{ width: "40%" }} />
                <GenericSelect itemList={["A", "B"]} label={"Similarity"} styles={{ width: "40%" }} />

            </div> */}
            <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                    'aria-labelledby': 'basic-button',
                }}
            >
                <MenuItem onClick={() => handleClose(0)}>Fit Audio to Segment</MenuItem>
                {/* <MenuItem onClick={() => handleClose(1)} >Only Tempo Manipulation</MenuItem> */}
                {/* <MenuItem onClick={() => handleClose(2)} disabled={segment.audio_generations_durations[segment.audio_final_index] > (segment.end - segment.start) }>Only Pauses Manipulation</MenuItem> */}
                <MenuItem onClick={() => handleClose(3)} disabled={!can_segment_fit(
                    segment.audio_generations_durations[segment.audio_final_index],
                    segment.id,
                    segments,
                    video_duration
                )} >Fit Segment to Audio</MenuItem>

            </Menu>



        </div>
    )
}

export default InferenceControls;