import { IconButton, LinearProgress, Typography } from "@mui/material";
import React, { useEffect } from "react";

import CloseIcon from "@mui/icons-material/Close";
import NearMeIcon from "@mui/icons-material/NearMe";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import useApi from "../hooks/useApi";
import { generate_context } from "../helpers/functions";


const RecommendationSet = ({
  text,
  // segmentIndex,
  // updateTranslationFromRecommendation,
  closeRecommendationModal,
  recommendationTranslation,
  alterSegmentFromId,
  segmentId,
  score
}) => {


  

  return (
    <div style={{ display: "flex", marginTop: 20 }}>
      <Typography style={{ color: "white", textAlign: "left",flex:1,marginLeft:10 }}>
        {text + "(" + recommendationTranslation + ")" + " " + score}
      </Typography>
      <IconButton
        style={{ marginLeft: 20 }}
        //   onClick={() => {
        //     updateTranslationFromRecommendation(segmentIndex, text);
        //     closeRecommendationModal();
        //   }}
        onClick={() => {
          alterSegmentFromId(segmentId,{translation:text});
          closeRecommendationModal();
        }}
        disableRipple={true}
      >
        <NearMeIcon fontSize="medium" color="white" />
      </IconButton>
    </div>
  );
};







const RecommendationModalNew = ({ closeRecommendationModal,segmentId,session_id,alterContext,alterSegmentFromId,translation,segments }) => {

  const recommendation = useApi("https://apy.translatetracks.com/generate_recommendations")
  const [recommendations,setRecommendations] = React.useState(null)
  const [recommendationTranslation,setRecommendationTranslation] = React.useState(null)
  const [recommendationScores,setRecommendationScores] = React.useState(null)


  useEffect(() => {
    if(alterContext){
      // recommendation.fetchData('GET',null,{segment_id:segmentId,session_id:session_id,type:alterContext,text:translation,context:null},localStorage.getItem('token'))
      // recommendation.fetchData('GET',null,{segment_id:segmentId,session_id:session_id,type:alterContext,text:null,context:null},localStorage.getItem('token'))
      const [context,position] = generate_context(segmentId,3,segments)
      console.log("context ",context)
      
      recommendation.fetchData('GET',null,{segment_id:segmentId,session_id:session_id,type:alterContext,text:translation,context:context.join("###"),position:position},localStorage.getItem('token'))

    }
  },[alterContext])

  useEffect(() => {
    if(recommendation.data){
      console.log(recommendation.data)
      setRecommendations(recommendation.data.data)
      setRecommendationTranslation(recommendation.data.english_translation)
      setRecommendationScores(recommendation.data.scores)
    }
  },[recommendation.data])

  if((!recommendations || recommendation.loading) && !recommendation.error){
    return (
      <div style={{display:"flex",justifyContent:"center"}}>
        <div style={{ width: "20%", margin: "auto",alignSelf:"center" }}>
            <LinearProgress style={{marginTop:10}}/> 
        </div>
      </div>
    )
  }
  if (recommendation.error) {
    return (
      <div style={{ display: "flex", justifyContent: "center" }}>
        <Typography
          style={{
            color: "red",
            fontSize: 14,
            textAlign: "center",
          }}>
          {recommendation.error}
        </Typography>
      </div>
    )
  }
  return (
    <div>
      <div style={{ display: "flex", flexDirection: "row" }}>
        <div style={{ flex: 1 }} />
        <IconButton
          onClick={closeRecommendationModal}
          style={{ padding: 0, alignSelf: "flex-start" }}
        >
          <CloseIcon style={{ color: "white" }} fontSize="small" />
        </IconButton>
      </div>
      {/* <RecommendationSet text={"text"} recommendationTranslation={"recommendationTranslation"} /> */}
      <Typography
          style={{
            color: "white",
            textAlign: "center",
            fontSize: 20,
            marginBottom: 20,
          }}
        >
          Recommendations
      </Typography>
      {recommendations.map((recommendation, index) => (
            <RecommendationSet
                key={index}
                text={recommendation}
                score={recommendationScores[index] ? recommendationScores[index] : ""}
                // segmentIndex={segmentIndex}
                // updateTranslationFromRecommendation={
                //     updateTranslationFromRecommendation
                // }
                closeRecommendationModal={closeRecommendationModal}
                recommendationTranslation={recommendationTranslation.length !== 0 ? recommendationTranslation[index] : "" }
                alterSegmentFromId={alterSegmentFromId}
                segmentId={segmentId}

            />
        ))}
    </div>
  )
}

export default RecommendationModalNew;