import "../styles/RangeSlider.css";
import "react-range-slider-input/dist/style.css";

import {
  Button,
  CircularProgress,
  FormControl,
  IconButton,
  InputLabel,
  LinearProgress,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import React, { useEffect, useMemo } from "react";

import AddBoxIcon from "@mui/icons-material/AddBox";
import CallSplitIcon from "@mui/icons-material/CallSplit";
import CancelPresentationIcon from "@mui/icons-material/CancelPresentation";
import ContextModal from "./ContextModal";
import DeleteIcon from "@mui/icons-material/Delete";
import EmptyTextarea from "../components/EmptyTextArea";
import PauseCircleIcon from "@mui/icons-material/PauseCircle";
import PlayCircleIcon from "@mui/icons-material/PlayCircle";
import RangeSlider from "react-range-slider-input";
import ReactModal from "react-modal";
import ReactPlayer from "react-player";
import RefreshIcon from "@mui/icons-material/Refresh";
import TimelineSvg from "../components/TimelineSvg";
import VolumeOffIcon from "@mui/icons-material/VolumeOff";
import VolumeUpIcon from "@mui/icons-material/VolumeUp";
import styled from "styled-components";
import { useRef } from "react";
import { useState } from "react";
import redDotImage from "../assets/images/red_dot.png"
import AutoFixOffIcon from '@mui/icons-material/AutoFixOff';
import Slider from '@mui/material/Slider';
import axios from "axios";
import { useNavigate } from "react-router-dom";
import MergeIcon from '@mui/icons-material/Merge';
import TranslateIcon from '@mui/icons-material/Translate';
import blueDotImage from '../assets/images/blue_dot.png'
import verticalLineImage from "../assets/images/vertical_line.png"
import Switch from "@mui/material/Switch";
import FormControlLabel from "@mui/material/FormControlLabel";


const StyledRangeSlider = styled.div`
  .range-slider__range {
    background-color: ${(props) => props.color};

    ${(props) =>
      props.shadow &&
      `
      box-shadow: 0 0 0 ${props.totalWidth + "px"}  rgba(34, 32, 34, 0.75);
    `}
    pointer-events: auto;
    border: ${(props) => props.borderThickness} solid #f7ff6f;
  }

  .range-slider {
    pointer-events: none;
  }

  .range-slider__thumb {
    pointer-events: auto;
    cursor: e-resize;
    display: ${(props) => (props.displayThumbs ? "block" : "none")};
  }
`;

const colors = [
  "#40b0b4",
  "#7a2627",
  "#7a5727",
  "#7a266f",
  "#30762f",
  "#513ca8",
];

const leastCount = 60;

const formatDuration = (duration) => {
  let seconds = Math.ceil(duration);
  const hrs = Math.floor(seconds / 3600);
  const mins = Math.floor((seconds % 3600) / 60);
  seconds = seconds % 60;

  let durationStr =
    (hrs > 0 ? `${hrs.toString().padStart(2, "0")}:` : "") +
    `${mins.toString().padStart(1, "0")}:` +
    `${seconds.toString().padStart(2, "0")}`;

  return durationStr;
};

const findChangingTime = (old_range, new_range) => {
  if (old_range[0] !== new_range[0]) {
    console.log("zero ", new_range[0]);
    return new_range[0];
  } else {
    console.log("one ", new_range[1]);
    return new_range[1];
  }
};

const isIncreamentPossible = (segments, new_range, index) => {
  //first range of multiple ranges
  if (index === 0 && segments.length > 1) {
    if (new_range[1] < segments[index + 1].start) {
      return true;
    } else {
      return false;
    }
    //first range of single range
  } else if (segments.length === 1) {
    return true;
    //last range of multiple ranges
  } else if (index === segments.length - 1) {
    if (new_range[0] > segments[index - 1].end) {
      return true;
    } else {
      return false;
    }
  } else {
    if (
      new_range[0] > segments[index - 1].end &&
      new_range[1] < segments[index + 1].start
    ) {
      return true;
    } else {
      return false;
    }
  }
};

const findSegemntIndexFromCurrentTime = (segments, playedSeconds) => {
  for (let i = 0; i < segments.length; i++) {
    if (
      playedSeconds >= segments[i].start &&
      playedSeconds <= segments[i].end
    ) {
      return i;
    }
  }
  return -1;
};

// const initializeSpeakerColorDict = (segments) => {
//   let dict = {};
//   for (let i = 0; i < segments.length; i++) {
//     if (!(segments[i].speaker in dict)) {
//       //randomly get from colors list such that it is not already in dict
//       let randomColor = colors[Math.floor(Math.random() * colors.length)];
//       while (Object.values(dict).includes(randomColor)) {
//         randomColor = colors[Math.floor(Math.random() * colors.length)];
//       }
//       dict[segments[i].speaker] =
//         colors[Math.floor(Math.random() * colors.length)];
//     }
//   }
//   console.log(dict);
//   return dict;
// };

const initializeSpeakerColorDict = (speakerSoundDict) => {
  let dict = {}
  for( let speaker in speakerSoundDict){
    const existingColors = new Set(Object.values(dict));
    const availableColor = colors.find(color => !existingColors.has(color));
    dict[speaker] = availableColor
  }
  return dict
}

// function nextLetter(letter) {
//   const charCode = letter.charCodeAt(0);

//   // Check if letter is between 'A' and 'Y' or between 'a' and 'y'
//   if (charCode >= 65 && charCode <= 89) {
//       return String.fromCharCode(charCode + 1);
//   }
  
//   // If letter is 'Z' or 'z', wrap around
//   if (charCode === 90) return 'A'; // 'Z' to 'A'

// }
function nextLetter(s) {
  if (s.length === 1) {
      const charCode = s.charCodeAt(0);

      // Check if letter is between 'A' and 'Y'
      if (charCode >= 65 && charCode <= 89) {
          return String.fromCharCode(charCode + 1);
      }

      // If letter is 'Z', move to 'AA'
      if (charCode === 90) return 'AA';
  } else if (s.length === 2) {
      // For double letters like 'AA', 'BB' etc.
      if (s[0] === s[1] && s[0] !== 'Z') {
          return String.fromCharCode(s.charCodeAt(0) + 1).repeat(2);
      }
  }
  return null; // for other cases or when it reaches 'ZZ'
}


const sortSpeakerSoundDict = (speakerSoundDict) => {
  const entries = Object.entries(speakerSoundDict)
  entries.sort((a, b) => b[0].length - a[0].length);
  return Object.fromEntries(entries);


}


const VideoPlayer = ({
  initial_segments,
  initialSpeakerSoundDict,
  videoUrl,
  transcriptionVerificationLoader,
  trancriptionVerificationError,
  verifyTranscript,
  getTranscriptRecommendation,
  recommendationLoading,
  transcriptRecommendation,
  breakpoints,
  selectedVideoId,
  audioBreakpoints
}) => {
  const [playing, setPlaying] = useState(false);
  const [duration, setDuration] = useState(0);
  const [currentTime, setCurrentTime] = useState(0);
  const timelineRef = useRef(null);
  const playerRef = useRef(null);
  const rangeSliderRef = useRef(null);
  const [segments, setSegments] = useState(initial_segments);
  const [segmentIndex, setSegmentIndex] = useState(-1);
  const [addMode, setAddMode] = useState(false);
  // const [speakerColorDict, setSpeakerColorDict] = useState(() =>
  //   initializeSpeakerColorDict(segments)
  // );
  const [speakerSoundDict,setSpeakerSoundDict] = useState(sortSpeakerSoundDict(initialSpeakerSoundDict))
  const speakerColorDict = useMemo(() => {
    return initializeSpeakerColorDict(speakerSoundDict)
  },[speakerSoundDict])
  // const [speakerColorDict,setSpeakerColorDict] = useState(() => initializeSpeakerColorDict(speakerSoundDict))
  
  const [startTime, setStartTime] = useState(0);
  const [endTime, setEndTime] = useState(leastCount);
  const [isThumbDragging, setIsThumbDragging] = useState(false);
  const [isRangeDragging, setIsRangeDragging] = useState(false);
  const [isBuffering, setIsBuffering] = useState(false);
  const sliderRef = useRef(null);
  const totalWidth =
    duration > leastCount ? duration * (800 / leastCount) : 800;
  const [isMute, setIsMute] = useState(false);
  const [splitMode, setSplitMode] = useState(false);
  const [isContextModalOpen, setIsContextModalOpen] = useState(false);
  const [context, setContext] = useState("");
  const [verificationInitiated, setVerificationInitiated] = useState(false);
  const [sliderValue,setSliderValue] = useState(0.4)
  const [selectedBreakpoint,setSelectedBreakpoint] = useState(null)
  const [magicLoading,setMagicLoading] = useState(false)
  const [translationLoading,setTranslationLoading] = useState(false)
  const navigate = useNavigate()
  const [audioChecked,setAudioChecked] = useState(false)
  

  const closeContextModal = () => {
    setIsContextModalOpen(false);
  };

  useEffect(() => {
    if (verificationInitiated) {
      closeContextModal();
      verifyTranscript(segments, context,false,speakerSoundDict);
    }
  }, [verificationInitiated]);

  const onVideoProgress = ({ playedSeconds }) => {
    console.log("firing...");
    setCurrentTime(playedSeconds);
    if (!isThumbDragging && !isRangeDragging) {
      let index = findSegemntIndexFromCurrentTime(segments, playedSeconds);
      setSegmentIndex(index);
    }
  };

  const onThumbDragStart = (index) => {
    setIsThumbDragging(true);
    setSegmentIndex(index);
  };

  const onThumbDragEnd = () => {
    setIsThumbDragging(false);
  };

  const onRangeDragEnd = () => {
    setIsRangeDragging(false);
  };

  const onBuffer = () => {
    console.log("buffering...");
    setIsBuffering(true);
  };

  const onBufferEnd = () => {
    console.log("buffering end...");
    setIsBuffering(false);
  };

  const toggleMute = () => {
    setIsMute(!isMute);
  };

  //insert into segments maintainng startTime order
  const insertSegment = (startTime) => {
    let new_segments = [...segments];
    let index = 0;
    for (let i = 0; i < new_segments.length; i++) {
      if (new_segments[i].start < startTime) {
        index = i + 1;
      }
    }
    new_segments.splice(index, 0, {
      start: startTime,
      end: startTime + 1,
      text: "",
      speaker: "A",
    });

    setSegments(new_segments);
  };

  const deleteSegment = () => {
    let new_segments = [...segments];
    new_segments.splice(segmentIndex, 1);
    setSegments(new_segments);
    setSegmentIndex(-1);
  };

  const alterSegments = (alter_index, alter_obj) => {
    let new_segments = [...segments];
    new_segments[alter_index] = { ...segments[alter_index], ...alter_obj };
    console.log("setting ", new_segments);
    setSegments(new_segments);
  };

  const addSpeaker = () => {
    const keys = Object.keys(speakerSoundDict)
    setSpeakerSoundDict({...speakerSoundDict,[nextLetter(keys[keys.length-1])]:"abc"})
  }

  const onSlide = (new_range, slide_index) => {
    if (isIncreamentPossible(segments, new_range, slide_index)) {
      const old_range = [
        segments[slide_index].start,
        segments[slide_index].end,
      ];
      playerRef.current.seekTo(
        findChangingTime(old_range, new_range),
        "seconds"
      );
      console.log(segmentIndex);
      alterSegments(slide_index, { start: new_range[0], end: new_range[1] });
    } else {
      console.log(segments[slide_index], new_range, "Not possible!");
    }
  };

  const togglePlaying = () => {
    setPlaying(!playing);
  };

  useEffect(() => {
    if (transcriptRecommendation) {
      alterSegments(segmentIndex, {
        text: segments[segmentIndex]["text"] + "\n" + transcriptRecommendation,
      });
    }
  }, [transcriptRecommendation]);

  const handleInsertClick = (e) => {
    const secondsPerPixel = duration / rangeSliderRef.current.offsetWidth;
    const clickTime =
      (e.clientX - rangeSliderRef.current.getBoundingClientRect().left) *
      secondsPerPixel;
    insertSegment(clickTime);
    playerRef.current.seekTo(clickTime + 0.5, "seconds");
    setAddMode(false);
  };

  const handleSplitClick = (e) => {
    const secondsPerPixel = duration / rangeSliderRef.current.offsetWidth;
    const clickTime =
      (e.clientX - rangeSliderRef.current.getBoundingClientRect().left) *
      secondsPerPixel;

    const index = findSegemntIndexFromCurrentTime(segments, clickTime);
    console.log("index ", index);
    const first_new_segment = { ...segments[index], end: clickTime };
    const second_new_segment = { ...segments[index], start: clickTime + 0.1 };
    let new_segments = [...segments];
    new_segments.splice(index, 1, first_new_segment, second_new_segment);
    setSegments(new_segments);
    setSplitMode(false);
    // insertSegment(clickTime);
    // playerRef.current.seekTo(clickTime + 0.5, "seconds");
    // setAddMode(false);
  };

  const handleTimelineClick = (e) => {
    const secondsPerPixel =
      (endTime - startTime) / timelineRef.current.offsetWidth;
    const clickTime =
      (e.clientX - rangeSliderRef.current.getBoundingClientRect().left) *
      secondsPerPixel;
    playerRef.current.seekTo(clickTime, "seconds");
  };

  const handleSegmentClick = (index) => {
    setIsRangeDragging(true);
    playerRef.current.seekTo(segments[index].start, "seconds");
    setSegmentIndex(index);
  };

  const handleTranscriptChange = (event, index) => {
    console.log("altering ", segments[index], event.target.value);
    alterSegments(index, { text: event.target.value });
  };

  useEffect(() => {
    if (
      Math.floor(currentTime) === Math.floor(endTime) &&
      Math.floor(currentTime) !== Math.floor(duration)
    ) {
      console.log(
        "ct ",
        Math.floor(currentTime),
        Math.floor(endTime),
        duration
      );
      const secondsPerPixel = duration / totalWidth;
      const newScrollLeft = currentTime / secondsPerPixel;
      if (sliderRef.current) {
        sliderRef.current.scrollLeft = newScrollLeft;
        const newStartTime = newScrollLeft * secondsPerPixel;
        const newEndTime =
          newStartTime + 800 * secondsPerPixel < duration
            ? newStartTime + 800 * secondsPerPixel
            : duration;
        setStartTime(newStartTime);
        setEndTime(newEndTime);
      }
    }
  }, [currentTime]);

  const handleScroll = (e) => {
    const secondsPerPixel = duration / totalWidth;
    const newStartTime = e.currentTarget.scrollLeft * secondsPerPixel;
    const newEndTime = newStartTime + 800 * secondsPerPixel;
    setStartTime(newStartTime);
    setEndTime(newEndTime);
  };

  const handleSpeakerChange = (event, index) => {
    alterSegments(index, { speaker: event.target.value });
  };

  const getMagicSplit = async () => {
    setMagicLoading(true)
    try {
      const response = await axios.get(
        "https://apx.translatetracks.com/split_segment",
        {
          headers: {
            token: localStorage.getItem("token"),
          },
          params: {
            session_id: selectedVideoId,
            ...selectedBreakpoint
          },
        }
      );
      console.log(response.data);
      if (response.data.status === 0) {
        setMagicLoading(false)
        // console.log(response.data)
        const new_segments = [...segments]
        //replace segment_index from new_segments with two returned segments
        new_segments.splice(response.data.segment_index,1,...response.data.data)
        setSegments(new_segments)

      } else if (response.data.status === -1) {
        localStorage.removeItem("token");
        localStorage.removeItem("email");
        navigate("/auth");
      } else {
        setMagicLoading(false)
      }
    } catch (err) {
      console.log(err);
      setMagicLoading(false)
    }
  };

  const mergeSegments = (mergeIndex) => {
    const new_segments = [...segments]
    new_segments[mergeIndex] = {...new_segments[mergeIndex],end:new_segments[mergeIndex+1].end,
      text:new_segments[mergeIndex].text + " " + new_segments[mergeIndex+1].text,
      translation:new_segments[mergeIndex].translation + " " + new_segments[mergeIndex+1].translation}
    new_segments.splice(mergeIndex+1,1)
    setSegments(new_segments)
  }

  const getAccurateTranslation = async (index) => {
    setTranslationLoading(true)
    try {
      const response = await axios.get(
        "https://apx.translatetracks.com/get_accurate_translation",
        {
          headers: {
            token: localStorage.getItem("token"),
          },
          params: {
            text: segments[index].text,
          },
        }
      );
      console.log(response.data);
      if (response.data.status === 0) {
        setTranslationLoading(false)
        //set translation of index
        alterSegments(index,{translation:response.data.data})
      } else if (response.data.status === -1) {
        setTranslationLoading(false)
        localStorage.removeItem("token");
        localStorage.removeItem("email");
        navigate("/auth");
      } else {
        setTranslationLoading(false)
      }
    } catch (err) {
      setTranslationLoading(false)
      console.log(err);
    }
  }


  return (
    <div style={{ backgroundColor: "#272727", height: "100vh" }}>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
        }}
      >
        <div
          className="video-player"
          style={{ display: "flex", flexDirection: "row" }}
        >
          <div style={{ flex: 1 }}></div>
          <ReactPlayer
            className="react-player"
            url={videoUrl}
            playing={playing}
            onDuration={(duration) => {
              setDuration(duration);
              if (duration < leastCount) {
                setEndTime(duration);
              }
            }}
            onProgress={onVideoProgress}
            ref={playerRef}
            progressInterval={10}
            width={480}
            height={270}
            onBuffer={onBuffer}
            onBufferEnd={onBufferEnd}
            muted={isMute}
          />
          <div style={{ alignSelf: "center", flex: 1 }}>
            {Object.keys(speakerSoundDict).map((speaker, index) => {
              return (
                <div
                  style={{
                    padding: 10,
                    backgroundColor: speakerColorDict[speaker],
                    width: "50%",
                    margin: "auto",
                    display: "flex",
                  }}
                >
                  <Typography style={{ marginRight: 5, color: "white" }}>
                    {speaker}
                  </Typography>
                  <audio controls style={{ flex: 1, height: 20 }}>
                    <source src={speakerSoundDict[speaker]} />
                  </audio>
                </div>
              );
            })}
            <IconButton
                  style={{ margin: "auto",display:"block" }}
                  onClick={addSpeaker}
                >
                  <AddBoxIcon style={{ color: "white" }} fontSize="large" />
                </IconButton>
          </div>
        </div>
        <div
          className="controls"
          style={{ display: "flex", alignSelf: "center", flexDirection: "row" }}
        >
          <IconButton onClick={togglePlaying}>
            {isBuffering ? (
              <CircularProgress style={{ color: "white" }} size={20} />
            ) : playing ? (
              <PauseCircleIcon style={{ color: "white" }} fontSize="medium" />
            ) : (
              <PlayCircleIcon style={{ color: "white" }} fontSize="medium" />
            )}
          </IconButton>
          <IconButton onClick={toggleMute}>
            {isMute ? (
              <VolumeOffIcon style={{ color: "white" }} fontSize="medium" />
            ) : (
              <VolumeUpIcon style={{ color: "white" }} fontSize="medium" />
            )}
          </IconButton>
        </div>
        <div
          className="timeline"
          ref={timelineRef}
          style={{
            display: "flex",
            alignSelf: "center",
            flexDirection: "row",
            position: "relative",
            marginTop: 5,
          }}
          onClick={handleTimelineClick}
        >
          {/* <Typography style={{position:"absolute",left:"50%",color:"white"}}>.</Typography> */}
          {(audioChecked ? audioBreakpoints[sliderValue*10] : breakpoints[sliderValue*10]).map((breakpoint) => {
            return (
                <img src={!selectedBreakpoint ? redDotImage : (selectedBreakpoint.breakpoint === breakpoint ? blueDotImage :  redDotImage)} style={{
                  width:10,
                  position:"absolute",
                  left:`${((breakpoint - startTime)/(endTime - startTime))*100}%`,
                top:12,
                display:breakpoint < startTime || breakpoint > endTime ? "none" : "block",
                cursor:"pointer",
              }}
              onClick={(event) => {
                setSelectedBreakpoint({
                'breakpoint':breakpoint,
                'text':segments[findSegemntIndexFromCurrentTime(segments,breakpoint)].text,
                'speaker':segments[findSegemntIndexFromCurrentTime(segments,breakpoint)].speaker,
                'segment_index':findSegemntIndexFromCurrentTime(segments,breakpoint),
                'index':segments[findSegemntIndexFromCurrentTime(segments,breakpoint)].index
              })
              playerRef.current.seekTo(breakpoint-1,"seconds")
              event.stopPropagation()

            }}
              />
            );
          })}
          <time
            style={{
              color: "white",
              position: "absolute",
              top: "-4px",
              fontSize: 12,
              transform: "translateX(-50%)",
            }}
          >
            {formatDuration(startTime)}
          </time>
          <TimelineSvg lineSpacing={36} />
          <time
            style={{
              color: "white",
              position: "absolute",
              top: "-4px",
              right: 0,
              fontSize: 12,
              transform: "translateX(50%)",
            }}
          >
            {formatDuration(endTime)}
          </time>
          {/*Add a vertical line travelling with time block*/}
          <time
            style={{
              color: "black",
              position: "absolute",
              top: "-8px",
              left: `${
                ((currentTime - startTime) / (endTime - startTime)) * 100
              }%`,
              fontSize: 12,
              padding: 4,
              backgroundColor: "white",
              borderRadius: 20,
              transform: "translateX(-50%)",
              cursor: "pointer",
              display:
                currentTime < startTime || currentTime > endTime
                  ? "none"
                  : "block",
            }}
          >
            {formatDuration(currentTime)}
          </time>
          <img src={verticalLineImage} style={{
            width:10,
            position:"absolute",
            left:`${((currentTime - startTime)/(endTime - startTime))*100}%`,
            top:12,
            display:currentTime < startTime || currentTime > endTime ? "none" : "block",
            transform: "translateX(-50%)",
            height:78,
            cursor:"text",
            zIndex:10
          }}/>

        </div>
        <div
          style={{ width: 800, margin: "auto", overflow: "auto" }}
          ref={sliderRef}
          onScroll={handleScroll}
          className="slider-container"
        >
          <div
            id="range-slider"
            ref={rangeSliderRef}
            style={{ width: totalWidth }}
          >
            {segments.map((segment, index) => {
              return (
                <StyledRangeSlider
                  color={
                    segmentIndex === index
                      ? speakerColorDict[segment.speaker]
                      : "#807f80"
                  }
                  shadow={index === 0}
                  key={index}
                  borderThickness={segmentIndex === index ? "3px" : "0px"}
                  displayThumbs={segmentIndex === index}
                  totalWidth={totalWidth}
                >
                  <RangeSlider
                    value={[segment.start, segment.end]}
                    onInput={(new_range) => onSlide(new_range, index)}
                    min={0}
                    max={duration}
                    step={0.001}
                    onRangeDragStart={() => handleSegmentClick(index)}
                    onThumbDragStart={() => onThumbDragStart(index)}
                    onThumbDragEnd={onThumbDragEnd}
                    onRangeDragEnd={onRangeDragEnd}
                  />
                </StyledRangeSlider>
              );
            })}
            <div
              style={{
                margin: "auto",
                height: 60,
                width: totalWidth,
                backgroundColor: "transparent",
                zIndex: splitMode || addMode ? 9999 : -1,
                position: "absolute",
              }}
              onClick={addMode ? handleInsertClick : handleSplitClick}
            ></div>
          </div>
        </div>
        <div
          className="console"
          style={{ alignSelf: "center", width: 800, marginTop: 10 }}
        >
          {segmentIndex !== -1 ? (
            <div style={{ display: "flex" }}>
              {!splitMode ? (
                <div style={{display:"flex",flexDirection:"column",flex:4,justifyContent:"center"}}>
                  <EmptyTextarea
                    value={segments[segmentIndex].text}
                    onChange={(event) => {
                      handleTranscriptChange(event, segmentIndex);
                    }}
                    style={{alignSelf:"center"}}
                  />
                  <Typography style={{color:"#afb8c1",textAlign:"center"}}>{segments[segmentIndex].translation}</Typography>
                </div>
              ) : (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    margin: "auto",
                  }}
                >
                  <Typography
                    variant="h7"
                    style={{ color: "white", margin: "auto" }}
                  >
                    Click on the segment to split it!
                  </Typography>

                  <IconButton
                    style={{ margin: "auto" }}
                    onClick={() => setSplitMode(false)}
                  >
                    <CancelPresentationIcon
                      style={{ color: "white" }}
                      fontSize="large"
                    />
                  </IconButton>
                </div>
              )}
              {!splitMode && (
                <div style={{ display: "flex", flexDirection: "column",flex:1 }}>
                  <FormControl
                    style={{
                      color: "white",
                      width: "100%",
                      marginLeft: 10,
                      alignSelf: "center",
                    }}
                  >
                    <InputLabel id="speaker" style={{ color: "white" }}>
                      Speaker
                    </InputLabel>
                    <Select
                      labelId="speaker"
                      id="demo-simple-select"
                      value={segments[segmentIndex].speaker}
                      label="Speaker"
                      onChange={(e) => handleSpeakerChange(e, segmentIndex)}
                    >
                      {/* <MenuItem value={"A"}>A</MenuItem>
                  <MenuItem value={"B"}>B</MenuItem> */}
                      {speakerSoundDict &&
                        Object.keys(speakerSoundDict).map((speaker) => {
                          return <MenuItem value={speaker}>{speaker}</MenuItem>;
                        })}
                    </Select>
                  </FormControl>

                  <div style={{ display: "flex", flexDirection: "row", flexWrap:"wrap" }}>
                    {recommendationLoading ? (
                      <CircularProgress
                        size={25}
                        style={{ alignSelf: "center", marginLeft: 5 }}
                      />
                    ) : (
                      <IconButton
                        style={{ margin: "auto" }}
                        onClick={() =>
                          getTranscriptRecommendation(
                            segments[segmentIndex]["start"],
                            segments[segmentIndex]["end"]
                          )
                        }
                      >
                        <RefreshIcon fontSize="medium" />
                      </IconButton>
                    )}
                    <IconButton
                      style={{ margin: "auto" }}
                      onClick={deleteSegment}
                    >
                      <DeleteIcon style={{ color: "white" }} fontSize="medium" />
                    </IconButton>
                    <IconButton
                      style={{ margin: "auto" }}
                      onClick={() => setSplitMode(true)}
                    >
                      <CallSplitIcon
                        style={{ color: "white" }}
                        fontSize="medium"
                      />
                    </IconButton>
                    { !magicLoading ? (
                    <IconButton
                      style={{ margin: "auto" }}
                      onClick={() => getMagicSplit()}
                      disabled={!selectedBreakpoint}
                    >
                      <AutoFixOffIcon
                        style={{ color: "white" }}
                        fontSize="medium"
                      />
                    </IconButton>):(
                      <CircularProgress
                        size={25}
                        style={{ alignSelf: "center", marginLeft: 5 }}
                      />
                    )
                  }
                  <IconButton
                      style={{ margin: "auto" }}
                      disabled={segmentIndex === segments.length-1}
                      onClick={() => mergeSegments(segmentIndex)}
                    >
                      <MergeIcon
                        style={{ color: "white" }}
                        fontSize="medium"
                      />
                    </IconButton>
                    {translationLoading ? (
                      <CircularProgress
                        size={25}
                        style={{ alignSelf: "center", margin:"auto" }}
                      />
                    ) : (
                    <IconButton
                      style={{ margin: "auto" }}
                      onClick={() => getAccurateTranslation(segmentIndex)}
                    >
                      <TranslateIcon
                        style={{ color: "white" }}
                        fontSize="medium"
                      />
                    </IconButton>
                    )}
                  </div>
                  <Slider
                    aria-label="Temperature"
                    defaultValue={sliderValue}
                    valueLabelDisplay="auto"
                    step={0.1}
                    marks
                    min={0.1}
                    max={0.4}
                    style={{width:"80%",alignSelf:"center"}}
                    onChange={(event,value) => setSliderValue(value)}
                  />
                  {audioBreakpoints && <FormControlLabel control={<Switch checked={audioChecked}
                    onChange={(event) => setAudioChecked(event.target.checked)}
                    inputProps={{ 'aria-label': 'controlled' }} />} label="Audio Bp" style={{ color: "white",margin:"auto" }} />}
                </div>
              )}
            </div>
          ) : (
            <div style={{ display: "flex", flexDirection: "column" }}>
              {!addMode && (
                <IconButton
                  style={{ margin: "auto" }}
                  onClick={() => setAddMode(true)}
                >
                  <AddBoxIcon style={{ color: "white" }} fontSize="large" />
                </IconButton>
              )}
              <Typography
                variant="h7"
                style={{ color: "white", margin: "auto" }}
              >
                {!addMode
                  ? "Add a new segment"
                  : "Click on the slider bar to add a new segment!"}
              </Typography>
              {addMode && (
                <IconButton
                  style={{ margin: "auto" }}
                  onClick={() => setAddMode(false)}
                >
                  <CancelPresentationIcon
                    style={{ color: "white" }}
                    fontSize="large"
                  />
                </IconButton>
              )}
            </div>
          )}
        </div>
        {transcriptionVerificationLoader ? (
          <div style={{ width: "20%", margin: "auto" }}>
            <LinearProgress style={{ marginTop: 10 }} />
          </div>
        ) : (
          <React.Fragment>
            <div style={{ display: "flex", justifyContent: "center" }}>
              <Button
                variant="contained"
                color="primary"
                style={{ alignSelf: "center", marginTop: 10 }}
                // onClick={() => verifyTranscript(segments)}
                onClick={() => setIsContextModalOpen(true)}
              >
                Verify
              </Button>
              <Button
                variant="contained"
                color="primary"
                style={{ alignSelf: "center", marginTop: 10,marginLeft:10 }}
                onClick={() => verifyTranscript(segments,null,true,speakerSoundDict)}
              >
                Save
              </Button>
            </div>
            {trancriptionVerificationError && (
              <Typography
                style={{
                  color: "red",
                  fontSize: 14,
                  textAlign: "center",
                  marginTop: 5,
                }}
              >
                {trancriptionVerificationError}
              </Typography>
            )}
          </React.Fragment>
        )}
      </div>
      <ReactModal
        isOpen={isContextModalOpen}
        onRequestClose={closeContextModal}
        contentLabel="Example Modal"
        className="CloneModal"
        overlayClassName="Overlay"
      >
        <ContextModal
          closeContextModal={closeContextModal}
          context={context}
          setContext={setContext}
          setVerificationInitiated={setVerificationInitiated}
        />
      </ReactModal>
    </div>
  );
};

export default VideoPlayer;
