import SliderOverlay from "./SliderOverlay";
import Slider from "./StyledSlider";

const SliderContainer = ({segments,timelineWidth,totalWidth,
    currentSegmentId,splitMode,addMode,sliderRef,handleScroll,
    rangeSliderRef,duration,speakerLegend,handleSegmentClick,onSlide,
    onThumbDragStart,onThumbDragEnd,onRangeDragEnd,handleInsertSegmentClick,handleSplitClick,hide}) => {
    return (
        <div
            style={{ width: timelineWidth, margin: "auto", overflow: "auto",display: hide ? "none" : "block" }}
            ref={sliderRef}
            onScroll={handleScroll}
            className="slider-container"
        >
            <div
                id="range-slider"
                ref={rangeSliderRef}
                style={{ width: totalWidth }}
            >
                {segments.map((segment, index) => {
                    return (
                        <Slider
                            segment={segment}
                            segmentId={segment.id}
                            currentSegmentId={currentSegmentId}
                            totalWidth={totalWidth}
                            duration={duration}
                            index={index}
                            speakerLegend={speakerLegend}
                            handleSegmentClick={handleSegmentClick}
                            onSlide={onSlide}
                            onRangeDragEnd={onRangeDragEnd}
                            onThumbDragEnd={onThumbDragEnd}
                            onThumbDragStart={onThumbDragStart}
                            key={segment.id}
                            />
                    );
                })}
                <SliderOverlay splitMode={splitMode} addMode={addMode} 
                totalWidth={totalWidth} handleInsertClick={handleInsertSegmentClick}
                handleSplitClick={handleSplitClick}/>
            </div>
        </div>
    )
}

export default SliderContainer;