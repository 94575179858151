import Inference from "./Inference";
import InferenceControls from "./InferenceControls";
import AddWidget from "./AddWidget";
import SplitWidget from "./SplitWidget";

const Console = ({ handleTrancriptTranslationClick, transcriptTranslationLoader, handleSegmentChange,segment, 
    setAddMode,addMode,status,setSplitMode,splitMode,handleDeleteClick,
    handleMergeClick,handleRetranscribeClick,retranscribeLoader,
    handleMagicSplit,magicSplitLoader,handleRegenerateClick,regenrateLoader,
    handleRefitClick,refitLoader,handlePredictTimeClick,predictTimeLoader,
handleTrimClick,audioChecked,setAudioChecked,sliderValue,setSliderValue,
handleToReferenceTranslationClick,toReferenceTranslationLoader,
  handleFromReferenceTranslationClick,fromReferenceTranslationLoader,
  speakerLegend,handleSpeakerChange,transcriptVisible,setTranscriptVisible,
  translationVisible,setTranslationVisible,segments,video_duration,setIsSpeakerModalOpen,selectedBreakpoint }) => {
    if (!segment) {
        return <div>Loading...</div>
    }
    
    if(segment === -1){
        return(
            <AddWidget splitMode={splitMode} addMode={addMode} setAddMode={setAddMode} setSplitMode={setSplitMode}/>
        )
    }

    return (
        <div style={{ display: "flex", width: 1200, margin: "auto" }}>
            <div style={{ flex: 4 }}>
                {splitMode ? <SplitWidget setSplitMode={setSplitMode}/> :
                <Inference handleTrancriptTranslationClick={handleTrancriptTranslationClick} 
                transcriptTranslationLoader={transcriptTranslationLoader}
                           handleSegmentChange={handleSegmentChange} 
                           segment={segment}
                           status={status}
                           handleToReferenceTranslationClick={handleToReferenceTranslationClick}
                           toReferenceTranslationLoader={toReferenceTranslationLoader}
                           handleFromReferenceTranslationClick={handleFromReferenceTranslationClick}
                           fromReferenceTranslationLoader={fromReferenceTranslationLoader}
                           transcriptVisible={transcriptVisible}
                           setTranscriptVisible={setTranscriptVisible}
                           translationVisible={translationVisible}
                            setTranslationVisible={setTranslationVisible}
                           />}
            </div>
            <div style={{ flex: 1, display: "flex", justifyContent: "center", alignItems: "center" }}>
                <InferenceControls status={status} 
                    setSplitMode={setSplitMode} handleDeleteClick={handleDeleteClick} 
                    handleMergeClick={handleMergeClick} 
                    handleRetranscribeClick={handleRetranscribeClick}
                    retranscribeLoader={retranscribeLoader}
                    segment={segment}
                    handleMagicSplit={handleMagicSplit}
                    magicSplitLoader={magicSplitLoader}
                    handleRegenerateClick={handleRegenerateClick}
                    regenrateLoader={regenrateLoader}
                    handleRefitClick={handleRefitClick}
                    refitLoader={refitLoader}
                    handlePredictTimeClick={handlePredictTimeClick}
                    predictTimeLoader={predictTimeLoader}
                    handleTrimClick = {handleTrimClick}
                    audioChecked={audioChecked}
                    sliderValue={sliderValue}
                    setAudioChecked={setAudioChecked}
                    setSliderValue={setSliderValue}
                    speakerLegend={speakerLegend}
                    handleSpeakerChange={handleSpeakerChange}
                    segments={segments}
                    video_duration={video_duration}
                    setIsSpeakerModalOpen={setIsSpeakerModalOpen}
                    selectedBreakpoint={selectedBreakpoint}/>
            </div>
        </div>
    );
}

export default Console;